// LoginFailed.tsx
import { Notification } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import React from 'react';
import {  useNavigate } from 'react-router-dom';

const LoginFailed: React.FC = () => {
  const navigate = useNavigate();

    // Extract the error message from the URL
    const params = new URLSearchParams(window.location.search);
    const errorMessage = params.get('error') || 'An unknown error occurred.';

  const handleRetry = () => {
    navigate('/login'); // Redirect to login page
  };

  const handleGoHome = () => {
    navigate('/'); // Redirect to home page
  };

  return (
    <div>
      <h2>Authentication Failed</h2>
      <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {errorMessage}
                </Notification>
      <p> Please try again or return to the home page.</p>
      <div>
        <button onClick={handleRetry}>Try Again</button>
        <button onClick={handleGoHome}>Go to Home Page</button>
      </div>
    </div>
  );
};

export default LoginFailed;
