import { Box, Container } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface TermsOfServiceProps {
  platformName: string;
  jurisdiction: string;
  contactEmail: string;
  contactPhone: string;
  effectiveDate: string;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({
  platformName,
  jurisdiction,
  contactEmail,
  contactPhone,
  effectiveDate,
}) => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>Effective Date: {effectiveDate}</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using {platformName}, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our platform.
      </p>

      <h2>2. Use of the Platform</h2>
      <p>
        You must be at least 18 years old to use our platform. By using the platform, you represent and warrant that you are of legal age. You must create an account to use certain features, and you are responsible for all activities under your account.
      </p>

      <h2>3. Campaigns and Contributions</h2>
      <p>
        Campaign creators must provide accurate information about their campaigns. Contributors understand that contributions are voluntary. {platformName} does not guarantee the success of any campaign.
      </p>

      <h2>4. Refund Policy</h2>
      <p>
        Contributions made on {platformName} are generally non-refundable. However, if a campaign is found to violate our Acceptable Use Policy, {platformName} reserves the right to issue refunds at its discretion. Contributors can contact us at {contactEmail} for refund inquiries.
      </p>

      <h2>5. Fees and Payments</h2>
      <p>
        {platformName} charges a 3.5% commission of the donation amount. Additional fees from third party payment proccessors may apply. By making a contribution, you agree to our payment processor's terms and conditions.
      </p>

      <h2>6. Acceptable Use Policy (AUP)</h2>
      <p>
        FundReach’s Acceptable Use Policy (AUP) prohibits the use of the platform for any illegal activities, including but not limited to:
      </p>
      <ul>
        <li>The sale or promotion of illicit or harmful products (e.g., drugs, weapons, counterfeit goods).</li>
        <li>Fundraising for hate speech, discriminatory or violent organizations.</li>
        <li>Fraudulent campaigns or misrepresentation of the campaign’s purpose.</li>
        <li>Fundraising for activities that violate local or international laws.</li>
        <li>Prohibited industries include gambling, adult content, and high-risk financial schemes (e.g., Ponzi schemes).</li>
      </ul>
      <p>
        {platformName} reserves the right to terminate any account or campaign that violates the AUP and may report violations to law enforcement as appropriate.
      </p>

      <h2>7. Risk Management Controls</h2>
      <p>
        {platformName} employs various measures to ensure the integrity of the platform and protect users, including but not limited to:
      </p>
      <ul>
        <li><strong>Phone Call Verification:</strong> We verify the phone numbers of campaign creators to prevent fraudulent activity.</li>
        <li><strong>Email Validation:</strong> We use gibberish email detection to prevent spam and fraudulent signups.</li>
        <li><strong>Mass Account Creation Prevention:</strong> CAPTCHA and other anti-bot technologies are in place to prevent bulk account creation.</li>
        <li><strong>Account Linking Detection:</strong> We monitor for any suspicious linking between accounts.</li>
        <li><strong>Manual Review:</strong> High-risk campaigns are subject to manual review before being approved.</li>
      </ul>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        The platform is provided "as is" without warranties of any kind. We do not guarantee uninterrupted or error-free service.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        {platformName} and its affiliates are not liable for any indirect, incidental, or consequential damages arising out of your use of the platform.
      </p>

      <h2>10. Indemnification</h2>
      <p>
        You agree to indemnify {platformName} against any claims or damages arising from your use of the platform.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These Terms of Service are governed by the laws of the {jurisdiction} without regard to its conflict of laws principles.
      </p>

      <h2>12. Changes to These Terms</h2>
      <p>
        We may update these Terms of Service from time to time. Changes will be posted on our platform, and the effective date will be updated accordingly.
      </p>

      <h2>13. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms of Service, please contact us at{' '}
       <Link to="/contact-us">Contact Us</Link> or call us at {contactPhone}.
      </p>
    </div>
  );
};

// Default export with FundReach specific details
export default function FundReachTermsOfService() {
  return (
<Container style={{backgroundColor:"#eeeeee"}}>
<Box>
     <TermsOfService
      platformName="FundReach"
      jurisdiction="Republic of Kenya"
      contactEmail="customercare@roshtech.co.ke"
      contactPhone="+254717245218"
      effectiveDate="August 29, 2024"
    />
 </Box>
</Container>
  );
}
