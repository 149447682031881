import  { useState, useEffect } from 'react';
import { makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Loader } from '@mantine/core';



const Withdrawal = () => {
   
    const [error, setError] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);


    
    const navigate = useNavigate();
    const{id} = useParams();
    const params = new URLSearchParams(window.location.search);
    const withdrawalId = decodeURIComponent(params.get('wi') as string);


    const processWithdrawal = async()=>{
        setProcessing(true)           

        const res = await makePostRequest(`process-withdrawal`,{campaignId:id,withdrawalId:withdrawalId});

        if (isErrorObject(res)) {
            setProcessing(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
          
            navigate('/withdrawal-success',{state:res.data})
        }
    
   
   

}
    
     
    
    
    useEffect(() => {
        processWithdrawal();
    }, []);

    return (
        <div>
            <Container>
            {processing && <p>PROCESSING...Plese wait! <Loader/></p>}
            {error && <p className="error">{error}</p>}
    
            </Container>
         
        </div>
    );

   
};

export default Withdrawal;






