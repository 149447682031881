import {useEffect, useState} from "react";
import {DataTable} from "mantine-datatable";
import {IDonation} from "../types";
import {Avatar, Group, Text} from "@mantine/core";
import { toTitleCase } from "../utils/utils";

const PAGE_SIZE = 10;

interface DonationsTableProps {
    donations: IDonation[];
}

const CampaignDonorsTable:React.FC<DonationsTableProps> = ({donations}) => {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(donations.slice(0, PAGE_SIZE));


    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
       donations && setRecords(donations.slice(from, to));
    }, [page,donations]);

    return (
        <DataTable
            columns={[
                {
                    accessor: 'Donor',
                    render: ({donor}: IDonation) =>
                        <Group>
                            <Avatar src={donor.avatar} alt={`${donor.first_name} profile avatar`} size="sm" radius="xl"/>
                            <Text>{toTitleCase(donor.first_name)} {toTitleCase(donor.last_name)}</Text>
                        </Group>
                },
                {accessor: 'donation_currency'},
                {accessor: 'donation_amount'}
            ]}
            records={records}
            totalRecords={donations && donations.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            highlightOnHover
            verticalSpacing="sm"
            striped
        />
    );
};

export default CampaignDonorsTable;
