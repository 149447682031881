import React, { useState, useEffect } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import './CampaignCategory.css'; // Make sure to include your CSS file
import { isErrorObject, toTitleCase } from '../utils/utils';

interface Category {
  id: string;
  category: string;
}

const CampaignCategory: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getCampaignCategory = async () => {
    setIsLoading(true);
    const res = await makeGetRequest("campaign-categories");

    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
      setCategories(res.data);
      setIsLoading(false);
      setError(null);
    }
  };

  useEffect(() => {
    getCampaignCategory();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <ul className="ws-product-categories">
        {categories.map(category => (
          <li className="ws-product-category" key={category.id}>
            <a className="ws-product-category__link" href={`campaigns/category/${category.id}`}>
              {toTitleCase(category.category)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CampaignCategory;
