import { Table, Text, Group, Paper, Title, Notification, Loader } from '@mantine/core';
import { ICampaignBal, ICampaignTransHistory } from '../types';
import React, { useEffect, useState } from 'react';
import { makeGetRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { IconAlertCircle } from '@tabler/icons-react';

type transProps = {
    transactions:ICampaignTransHistory[]
    campaignId:string;
    
  };

const TransactionHistory:React.FC<transProps> = ({ transactions ,campaignId}) => {

  const [donationBal, setDonationBal] = useState<ICampaignBal>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);


  // Summarize transactions
  /*const totalDeposits = transactions
    .filter((t) => t.type === 'deposit')
    .reduce((acc, curr) => acc + curr.amount, 0);

  const totalWithdrawals = transactions
    .filter((t) => t.type === 'withdrawal')
    .reduce((acc, curr) => acc + curr.amount, 0);*/


  // Table rows
  const rows = transactions.map((transaction:ICampaignTransHistory, index:number) => (
    <tr key={index}>
      <td>{new Date(transaction.date).toLocaleDateString()}</td>
      <td>{transaction.description}</td>
      <td>
        <Text
          color={transaction.type === 'in' ? 'green' : 'red'}
          weight={500}
        >
          {transaction.type === 'in' ? '+' : '-'}
          {transaction.amount.toLocaleString(undefined, {
            style: 'currency',
            currency: transaction.currency||'KES',
          })}
        </Text>
      </td>
    </tr>
  ));

  const getDonationBal = async () => {
    setIsLoading(true);
    const res = await makeGetRequest(`campaign-donation-balance/${campaignId}`);

    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
    } else {
        const results = res.data;
        setDonationBal(results);       
        setIsLoading(false);
        setError(null);
    }
};

  useEffect(()=>{
    getDonationBal()

  },[])

  return (
    <Paper withBorder radius="md" p="md">
      <Group position="apart" mb="md">
        <Title order={3}>Transaction Summary</Title>

        {isLoading && <Loader/>}
    {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
        <Group spacing="xl">
          <div>
            <Text>Total Deposits:</Text>
            <Text weight={700} color="green">
              {donationBal?.totalDonations.toLocaleString(undefined, {
                style: 'currency',
                currency: donationBal.currency,
              })}
            </Text>
          </div>
          <div>
            <Text>Total Withdrawals:</Text>
            <Text weight={700} color="red">
              {donationBal?.totalWithdrawals.toLocaleString(undefined, {
                style: 'currency',
                currency: donationBal.currency,
              })}
            </Text>
          </div>
          <div>
            <Text>Current Balance:</Text>
            <Text weight={700}>
              {donationBal?.availableBalance.toLocaleString(undefined, {
                style: 'currency',
                currency: donationBal.currency,
              })}
            </Text>
          </div>
        </Group>
      </Group>

      <Table striped highlightOnHover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Paper>
  );
};

export default TransactionHistory;
