import  { useEffect, useState } from 'react';
import {
    Box,
    Title,
    Notification,
    Loader,
    Container,
    Button,
} from '@mantine/core';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import {  useNavigate, useParams } from 'react-router-dom';
import { makeGetRequest, makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { /*ICampaign,*/ ICampaignAdmin } from '../types';




const WithdrawalAdminApproval= () => {
    const [admins, setAdmins] = useState<ICampaignAdmin[]>([]);
    const [selectedAdmin, setSelectedAdmin] = useState<string | null>();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
   // const [campaign, setCampaign] = useState<ICampaign>();



    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const withdrawalId = localStorage.getItem('wi');



    
    const handleAdminSelected =(admin:string)=>{
        setSelectedAdmin(admin)
        selectedAdmin && sendApprovalCode()

        
      

    }

    const {id} = useParams();
    const navigate = useNavigate();
    //const params = new URLSearchParams(window.location.search);
    //const withdrawalId = decodeURIComponent(params.get('wi') as string);


 

    const sendApprovalCode = async () => {
        if(!selectedAdmin){
            setError('No admin is selected')
        }

        const data ={
            adminId:selectedAdmin,
            campaignId:id,
            withdrawalId:withdrawalId,
            initiator:user
        }
        setIsLoading(true);

        const res = await makePostRequest(`send-withdrawal-approval-code/${id}`,data);

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            const result = res.data;
         
          

               //set approval status
               const checkApprovalStatus = await makePostRequest(`update-withdrawal-status`,{campaignId:id,withdrawalId:withdrawalId});

               if (isErrorObject(checkApprovalStatus)) {
                   setIsLoading(false);
                   const errorMessage = ErrorHandler(checkApprovalStatus);
                   setError(errorMessage);
               } else {

                const status = checkApprovalStatus.data.approved;
                console.log('status',status); 
                if(status){
                    setSuccess(true)
                    navigate(`/process-withdrawal/${id}?wi=${withdrawalId}`)

                }else{
                    const adminData = {
                        ai:selectedAdmin,
                        adn:result.adminName,
                        wi:result.withdrawalId
                    }
                    navigate(`/verify-admin-approval/${id}?dt=${ encodeURIComponent(JSON.stringify(adminData))}`)
                }

      


                 
               }
           }

      

           
        }
    
   

    const getAdmins = async () => {
        setIsLoading(true);

        const res = await makeGetRequest(`campaign-admins/${id}`);

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            const results = res.data;
            setAdmins(results);       
            setIsLoading(false);
            setError(null);
        }
    };


   /* const fetchCampaign = async () => {
        setIsLoading(true);
        const res = await makeGetRequest( `campaign/${id}`);
        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
          } else {
            setCampaign(res.data);
        }
        setIsLoading(false);
    };*/

   /* const approvalStatus = async()=>{
        const totalApproved = admins.filter((admin)=>admin.approved)
        const withdrawalMandate = campaign?.withdrawal_mandate;
        alert(withdrawalMandate)
      

        if(withdrawalMandate==='any_two' && totalApproved.length>1 ){
            //set approval status
            const res = await makePutRequest(`update-withdrawal-status/${id}`,{campaignId:id,withdrawalId:withdrawalId});

            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
              
                navigate(`/process-withdrawal/${id}/wi=${withdrawalId}`)
            }
        }
         if(withdrawalMandate==='all' && totalApproved.length>2){
                   //set approval status
         const updateStatus = await makePutRequest(`update-withdrawal-status/${id}`,{campaignId:id,withdrawalId:withdrawalId});

         if (isErrorObject(updateStatus)) {
             setIsLoading(false);
             const errorMessage = ErrorHandler(updateStatus);
             setError(errorMessage);
         } else {
            setSuccess(true)
            navigate(`/process-withdrawal/${id}/wi=${withdrawalId}`)

           

         }
         }
       

    }*/

 



    useEffect(()=>{
       // fetchCampaign()        
        getAdmins()
       

    },[])

 

    return (
        <Box  mx="auto">
           <Container>
           {isLoading && <Loader/>}
            {error && <p className='error'>{error}</p>}
            <Title order={3} align="center" mb="lg">Withdrawal Request- Admin Approval</Title>
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Withdrawal request submitted successfully.
                </Notification>
            )}
             <p>Click on the admin and and approval code will be send to the admin. You will have to enter it on the next screen to confirm that the admin has approved the transaction.</p>
             <br/>
        {admins.filter(admin => admin.user.id !== user.id).map(admin => (
            <div key={admin.id}><br/>
           
                <Button onClick={() => handleAdminSelected(admin.id)}>{admin.user.first_name} {admin.user.last_name}</Button>
                <br/>
               
            </div>
        ))}
           </Container>
        </Box>
    );
};

export default WithdrawalAdminApproval;
