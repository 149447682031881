import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import SanitizeHtmlComponent from './SanitizeHtmlComponent';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { Helmet } from "react-helmet";
import { IMAGE_BASE_URL } from '../services/paths';
import {
  Container,
  Grid,
  Col,
  Image,
  Card,
  Text,
  Divider,
  Loader,
} from '@mantine/core';
import { isErrorObject } from '../utils/utils';

interface Author {
  avatar: string;
  first_name: string;
  last_name: string;
}

interface Post {
  id: number;
  slug: string;
  featuredImage: string;
  title: string;
  content: string;
  createdAt: string;
  author: Author | null;
  comments: Array<any>;
}

const Blog: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getPosts = async () => {
      setIsLoading(true);
      const res = await makeGetRequest('blog-posts');
      if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
        setBlogPosts(res.data);
        setIsLoading(false);
        setError(null);
      }
    };
    getPosts();
  }, []);




  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <Text color="red">{error}</Text>;
  }

  return (
    <>
      <Helmet>
        <title>CS Blog</title>
        <meta
          name="description"
          content="Latest from CS church management and accounting software blog"
        />
        <link rel="canonical" href="/blog" />
      </Helmet>
      <Container>
        <Text align="center" size="xl" weight={700}>
          Blog
        </Text>
        {error !== null && <Text color="red">{error}</Text>}
        {blogPosts.length !== 0 ? (
          blogPosts.map((post) => (
            <Grid key={post.id} gutter="md" style={{ marginBottom: '25px' }}>
              <Col span={5}>
                <Link to={`/blog/${post.slug}/${post.id}`}>
                  <Image
                    src={`${IMAGE_BASE_URL}${post.featuredImage}`}
                    alt={post.title}
                    style={{ width: '100%', height: '400px' }}
                  />
                </Link>
              </Col>
              <Col span={7} style={{ border: '1px solid grey', borderLeft: 'none' }}>
                <Card shadow="sm" padding="lg">
                  <div style={{ display: 'flex', marginBottom: '25px' }}>
                    <Image
                      src={post.author?.avatar || ''}
                      alt="avatar"
                      width={50}
                      height={50}
                      radius="xl"
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <Text>{post.author?.first_name} {post.author?.last_name}</Text>
                      <Text size="xs" color="dimmed">
                       {/* <Moment fromNow>{post.createdAt}</Moment>*/}
                      </Text>
                    </div>
                  </div>
                  <Link to={`/blog/${post.slug}/${post.id}`}>
                    <Text size="lg" weight={500}>{post.title}</Text>
                  </Link>
                  <Link to={`/blog/${post.slug}/${post.id}`}>
                    <Text>
                      <SanitizeHtmlComponent htmlContent={post.content} maxContentLength={230} />
                    </Text>
                  </Link>
                  <Divider my="sm" />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text>{post.comments.length} comments</Text>
                    <Text>{2} <FaHeart /></Text>
                  </div>
                </Card>
              </Col>
            </Grid>
          ))
        ) : (
          <Text color="blue">No Posts</Text>
        )}
      </Container>
    </>
  );
};

export default Blog;
