
 const ErrorHandler =(res:any)=>{ 
  
        const error = res.errorType;
        if (error === 'authentication') {
            
            return res.errorMessage;

        }

        else if (error === 'validation') {
            const validationErrors = res.validationErrors.errors;
            let backend_errors:any = [];  
            validationErrors.map((error:any)=>{
              backend_errors.push(error.msg)
            return backend_errors;
            })

        }
        else if (error === 'authorization') {
            return res.errorMessage;

        }
        else if (error === 'badrequest') {
            return res.errorMessage;
        } else {
            return res.errorMessage;
        }
       
      
    

}
export default ErrorHandler;
