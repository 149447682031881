import {Box, BoxProps, Loader, TextProps, Title, TitleProps} from '@mantine/core'
import {CampaignCard, TitleBadge} from "../../components";
import {Carousel} from "@mantine/carousel";

import { useEffect, useState } from 'react';
import { ICampaign } from '../../types';
import { makeGetRequest } from '../../services/httpHelper';
import { isErrorObject } from '../../utils/utils';
import ErrorHandler from '../../services/ErrorHandler';

interface IProps {
    boxProps: BoxProps
    titleProps?: TitleProps,
    subtitleProps?: TextProps
}

const CampaignsSection = ({boxProps, titleProps}: IProps) => {

    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const user =  JSON.parse(localStorage.getItem('user') || '{}');


    useEffect(() => {
        const fetchCampaigns = async () => {
            setIsLoading(true);
            const endpoint = `campaigns-in-your-community/${user.id}`;
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setCampaigns(res.data);
            }
            setIsLoading(false);
        };
        fetchCampaigns();
    }, []);
    const slides = campaigns && campaigns.map(c => (<Carousel.Slide key={c.id}><CampaignCard data={c}/></Carousel.Slide>))

    if(isLoading){
        return <Loader/>
    }
    if(error){
        return <p className='error'>{error}</p>
    }

    return (
        <Box {...boxProps}>
            <TitleBadge title="Happening near you"/>
            <Title {...titleProps}>Fundraisers in your community</Title>
            <Carousel
                slideSize="45%"
                align="start"
                slideGap="md"
                breakpoints={[
                    {maxWidth: 'md', slideSize: '45%'},
                    {maxWidth: 'sm', slideSize: '100%', slideGap: 0},
                ]}
            >
                {slides}
            </Carousel>
        </Box>
    );
};

export default CampaignsSection;
