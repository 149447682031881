import React, { useState, useEffect } from 'react';

import {
    Button,
    Container,
    Divider,
    Loader,
    Paper,
    TextInput,
    Title,
} from '@mantine/core';
import {Helmet} from "react-helmet";
import { makePostRequest } from '../services/httpHelper';
import { Navigate } from 'react-router-dom';




const ForgotPasswordPage = () => {
  const [countDown, setCountDown] = useState<number>(60);
  const [showResendButton, setShowResendButton] = useState<boolean>(false);
    const[error,setError]=useState<string | null>(null);
    const[isSubmitting,setIsSubmitting]=useState(false);
    const[isSuccess,setIsSuccess]=useState(false);
    const[email,setEmail]=useState<string| null>(null);


    const handleChange = (e:  React.ChangeEvent<HTMLInputElement>) =>{
        setEmail(e.target.value)
    
    }

    const handleSubmit =async(e: React.MouseEvent<HTMLButtonElement>)=>{
        setError(null)
        e.preventDefault();
        if(email===null){
            setError('Enter Email');
            return;
        }

        setIsSubmitting(true)
     

        const data ={
          email:email,

        
      }

        const res = await makePostRequest('auth/forgot-password',data);
        if(res && res.errorType){
            setIsSubmitting(false);
            setError(res.errorMessage);
                   
    
      
          }else{
                       
            setIsSubmitting(false);
            setIsSuccess(true)

            
          }

        
    }

        

  useEffect(() => {
    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown(prevCount => prevCount - 1);
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval on unmount
    } else {
      setShowResendButton(true);
    }
  }, [countDown]);

  const resendVerificationCode = async(e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // Your resend verification code logic here

    // Reset the countdown and hide the button
    setCountDown(60);
    setShowResendButton(false);
    setIsSubmitting(true)
     

        const data ={
          email:email,

        
      }

        const res = await makePostRequest('auth/resend-email-verification-code',data);
        if(res && res.errorType){
            setIsSubmitting(false);
            setError(res.errorMessage) 
          }else{                       
            setIsSubmitting(false);
            setIsSuccess(true)

            
          }


  };

  return (
    <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={() => ({fontWeight: 900})}
                >
                   Forgot Password
                </Title>
             

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                   
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader/>}
                    {isSuccess && <Navigate to={`/verify-reset-password-code/${email}`}/>}

                    {error && <p className='error'>{error}</p>}

                    <TextInput label="Account Email Address" placeholder="Enter Your Email" name="email" onChange={handleChange} />               
               

                  
                  
                    <Button fullWidth mt="xl" onClick={(e)=>handleSubmit(e)}>
                        Request Set Code
                    </Button>
                </Paper>

                <div>
      <p>Did not receive  code? Resend in {countDown} seconds</p>
      {showResendButton && (
        <button onClick={resendVerificationCode}>
          Resend Code
        </button>
      )}
    </div>
            </Container>
        </>
   
  );
};

export default ForgotPasswordPage;
