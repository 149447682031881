import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {  makePutRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { Box, Loader, Title, Notification, TextInput, Button } from '@mantine/core';
import { IconCheck, IconAlertCircle, IconSelector } from '@tabler/icons-react';
import { IRole } from '../types';

type RoleFormData = {
  name: string;
  description: string;
  
};

type AdminProps = {
  role:IRole
};

const EditRoleForm:React.FC<AdminProps>  = ({role}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formData, setFormData] = useState<RoleFormData>({
    name: role.name,
    description: role.description,
   
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchAdminData = async () => {
        const res = await makePutRequest(`role${role.id}`,formData);
        if (!isErrorObject(res)) {
          setFormData(res.data);
        } else {
          setError('Failed to fetch admin details');
        }
      };

      fetchAdminData();
    }
  }, [id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

 
    const res = await makePutRequest(`role/${role.id}`, formData)     

    setIsSubmitting(false);

    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      setSuccess(true);
      // Resetting the form fields to empty strings
      setFormData({
        name: '',
        description: '',
        
      });
    }
  };

  return (
    <Box mx="auto">
      {error && (
        <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
          {error}
        </Notification>
      )}
      {success && (
        <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
          Admin {id ? 'updated' : 'added'} successfully.
        </Notification>
      )}

      <form onSubmit={handleSubmit}>
        {isSubmitting && <Loader />}
        <Title order={3} align="center" mb="lg">
          {id ? 'Edit Role' : 'Add Role'}
        </Title>

        <div>
          <label htmlFor="first_name">Role Name:</label>
       
           <TextInput 
            label="Name"
            name="name" 
            value={formData.name}
            onChange={handleChange}
                                         />
        </div>
        <div>
          <label htmlFor="last_name">Description:</label>
        
            <TextInput 
            label="Description"
            name="description" 
            value={formData.description}
            onChange={handleChange}
            />
        </div>
       <br/>
       <Button
        leftIcon={<IconSelector size={18}/>}
        mx="auto"
        variant="light"
        type='submit'

            >
         Save  
    </Button>
        
      
      </form>
    </Box>
  );
};

export default EditRoleForm;
