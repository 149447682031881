import React, { useState } from 'react';
import { makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { Box, Loader, Title,Notification, SimpleGrid, TextInput, Button } from '@mantine/core';
import { IconCheck, IconAlertCircle, IconSelector } from '@tabler/icons-react';




type AdminFormData = {
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  role: string;
  campaign_id:string

};

type campaignIdProps = {
  campaignId:any
};

const AddCampaignPromoterForm:React.FC<campaignIdProps>  = ({campaignId}) => {

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  //const user = JSON.parse(localStorage.getItem('user') || '{}');


  const [formData, setFormData] = useState<AdminFormData>({
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    role: '',
    campaign_id:campaignId

  });



  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true)

    const res = await makePostRequest(`team`,formData);
    if (isErrorObject(res)) {
      setIsSubmitting(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
      setSuccess(false)
  } else {
          // Resetting the form fields to empty strings
          setFormData({
            first_name: '',
            last_name: '',
            email: '',
            mobile_number: '',
            role: '',
            campaign_id:campaignId
        });    
      setIsSubmitting(false);
      setError(null);
      setSuccess(true)
  
  }


 
  };

  return (
    <Box  mx="auto">
         {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Admin Added 
                </Notification>
            )}

<form onSubmit={handleSubmit}>

{isSubmitting && <Loader/>}
            <Title order={3} align="center" mb="lg">Add Campaign Admin </Title>
            {error && <p className='error'>{error}</p>}
          

         
               <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                        <TextInput 
                                        label="First name"
                                         name="first_name" 
                                         value={formData.first_name}
                                         onChange={handleChange}
                                         />
                                        <TextInput 
                                        label="Last name"
                                         name="last_name" 
                                         value={formData.last_name}
                                         onChange={handleChange}
                                         />
                                        <TextInput
                                         label="Email" 
                                         mb="xs" name="email"
                                         value={formData.email}
                                         onChange={handleChange}
                                          />
                                           <TextInput
                                         label="Mobile Number" 
                                         mb="xs" name="mobile_number"
                                         value={formData.mobile_number}
                                         onChange={handleChange}
                                          />
                                        <TextInput
                                            label="Role"
                                            placeholder="e.g. Social media manager, funds manager"
                                            mb="xs"
                                            name="role"
                                          
                                            value={formData.role}
                                             onChange={handleChange}
                                        />
                                    </SimpleGrid>
                                    <Button
                                        leftIcon={<IconSelector size={18}/>}
                                        mx="auto"
                                        variant="light"
                                        type='submit'

                                    >
                                       Add  Promoter
                                    </Button>





      
    </form>
    </Box>
    
  );
};

export default AddCampaignPromoterForm;
