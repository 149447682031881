import { Box } from "@mantine/core";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { IDonationByCategory } from "../types";
import { useEffect, useState } from "react";

interface DonationsChartProps {
  donations: IDonationByCategory[];
}

const YearlyDonationChart: React.FC<DonationsChartProps> = ({ donations }) => {
  const [series, setSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    const newSeries = donations.map((category) => {
      const categoryName = getCategoryName(category.categoryName); // Implement getCategoryName function

      const data = [];
      // Loop through months and count donations
      for (let month = 1; month <= 6; month++) {
        const key = `${month}-2024`;
        const monthlyDonations = category.donations[key] || [];
        data.push(monthlyDonations.length);
      }

      return {
        name: categoryName,
        data: data,
      };
    });

    setSeries(newSeries);

    // Extract and format months for the x-axis categories
    const extractedMonths = new Set<string>();
    donations.forEach((category) => {
      Object.keys(category.donations).forEach((key) => {
        const [month, year] = key.split("-");
        const date = new Date(parseInt(year), parseInt(month) - 1);
        const formattedDate = date.toLocaleString("default", { month: "short" }) + ` '${year.slice(-2)}`;
        extractedMonths.add(formattedDate);
      });
    });

    setCategories(Array.from(extractedMonths).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()));
  }, [donations]);

  const getCategoryName = (categoryId: string): string => {
    // Replace this with your logic to fetch category names based on categoryId
    // For now, returning a placeholder value
    return `Category ${categoryId}`;
  };

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <Box>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </Box>
  );
};

export default YearlyDonationChart;
