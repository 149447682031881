import {useEffect, useState} from "react";
import {DataTable} from "mantine-datatable";
import {ICampaignTeam} from "../types";
import {Avatar, Group, Text} from "@mantine/core";
import { toTitleCase } from "../utils/utils";

const PAGE_SIZE = 10;

interface TeamsTableProps {
    teams: ICampaignTeam[];
}

const CampaignTeamTable:React.FC<TeamsTableProps> = ({teams}) => {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(teams.slice(0, PAGE_SIZE));


    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
       teams && setRecords(teams.slice(from, to));
    }, [page,teams]);

    return (
        <DataTable
            columns={[
                {
                    accessor: 'Name',
                    render: ({user}: ICampaignTeam) =>
                        <Group>
                            <Avatar src={user.avatar} alt={`${user.first_name} profile avatar`} size="sm" radius="xl"/>
                            <Text>{toTitleCase(user.first_name)} {toTitleCase(user.last_name)}</Text>
                        </Group>
                },
                {accessor: 'role'},
            ]}
            records={records}
            totalRecords={teams && teams.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            highlightOnHover
            verticalSpacing="sm"
            striped
        />
    );
};

export default CampaignTeamTable;
