import React, { useEffect, useState } from 'react';
import { Button, Modal, TextInput, MultiSelect, Group,  Menu, Loader, ActionIcon } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconEdit, IconTrash, IconLock,  IconMessage, IconBarrierBlock,  IconDotsVertical } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { IUser } from '../types';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../services/httpHelper';




interface Role {
  id: string;
  name: string;
}

const ManageUsersPage: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState<IUser[]>([]);


  const PAGE_SIZE = 10;
  const [newUser, setNewUser] = useState({ first_name: '', last_name: '', email: '',mobile_number:'', roles: [] as string[] });

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  // Fetch users from backend

  const fetchUsers = async () => {
    const res = await makeGetRequest(`users`);
    setIsLoading(false);
    if (isErrorObject(res)) {
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
    } else {
      console.log(res.data)
        setUsers(res.data);
        setRecords(res.data.slice(0, PAGE_SIZE));
    }
};

  

  // Fetch roles from backend
  const fetchRoles = async () => {
    const res = await makeGetRequest(`roles`);
    setIsLoading(false);
    if (isErrorObject(res)) {
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
    } else {
        setRoles(res.data);
      //  setRecords(res.data.slice(0, PAGE_SIZE));
    }
};

  // Add new user
  const handleAddUser = async () => {
    try {
        setIsSubmitting(true)
        const res = await makePostRequest(`user`,newUser);
        if (isErrorObject(res)) {
            setIsSubmitting(false)
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
            showNotification({ message: 'Failed to add user', color: 'red' });

        } else {
            setIsSubmitting(false)
            setOpened(false);
            fetchUsers();
            showNotification({ message: 'User added successfully', color: 'green' });
        }
          
    
    } catch (error) {
      console.error('Failed to add user', error);
      showNotification({ message: 'Failed to add user', color: 'red' });
    }
  };

  // Block/Unblock user
  const handleBlockUser = async (userId: string, isBlocked: boolean) => {
    try {
      const confirm = window.confirm('Are sure about this?');

       if(confirm){
        const res = await makePostRequest(`user/${userId}/block`,{ blocked: !isBlocked });
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
            console.error('Failed to block/unblock user', error);
      showNotification({ message: `Failed to ${isBlocked ? 'unblock' : 'block'} user`, color: 'red' });

        } else {
            fetchUsers();
            showNotification({ message: `User ${isBlocked ? 'unblocked' : 'blocked'} successfully`, color: 'green' });
        }
       }

     
    } catch (error) {
      console.error('Failed to block/unblock user', error);
      showNotification({ message: `Failed to ${isBlocked ? 'unblock' : 'block'} user`, color: 'red' });
    }
  };

  // Assign/Revoke roles
  const handleAssignRoles = async (userId: string, selectedRoleIds: string[]) => {
    try {

        const res = await makePostRequest(`user/${userId}/role`,{ roleId: selectedRoleIds });
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
            fetchUsers();
            showNotification({ message: 'Roles updated successfully', color: 'green' });

        } else {
            fetchUsers();
            showNotification({ message: 'Roles updated successfully', color: 'green' });
        }
   
    } catch (error) {
      console.error('Failed to update roles', error);
      showNotification({ message: 'Failed to update roles', color: 'red' });
    }
  };

  // Remove user
  const handleRemoveUser = async (userId: string) => {
    try {
      const confimed = window.confirm('Are sure you want to permanently delete this user account?')

      if(confimed){
        const res = await makeDeleteRequest(`user/${userId}`);
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
            console.error('Failed to remove user', error);
            showNotification({ message: 'Failed to remove user', color: 'red' });

        } else {
            fetchUsers();
            showNotification({ message: 'User removed successfully', color: 'green' });
        }
      }
     
    } catch (error) {
      console.error('Failed to remove user', error);
      showNotification({ message: 'Failed to remove user', color: 'red' });
    }
  };

  // Send message to user
  const handleMessageUser = (user: IUser) => {
    showNotification({ message: `Message sent to ${user.first_name} ${user.last_name}`, color: 'green' });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(users.slice(from, to));
};

  return (
    <div>
        {isLoading && <Loader/>}
      <Group position="apart">
        <h1>User Management</h1>
        <Button onClick={() => setOpened(true)}>Add New User</Button>
      </Group>

      <DataTable
        columns={[
          { accessor: 'first_name', title: 'First Name' },
          { accessor: 'last_name', title: 'Last Name' },
          { accessor: 'email', title: 'Email' },
          { accessor: 'mobile_number', title: 'Phone' },
          { accessor: 'roles', title: 'Roles', render: (row: IUser) => row.roles?.map(role => role.name).join(', ') || 'No Role Assigned' },

          {
            accessor: 'actions',
            title: 'Actions',
            render: (row) => (
              <Menu>
                <Menu.Target>
                  <ActionIcon>
                    <IconDotsVertical size={20} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={row.blocked ? <IconBarrierBlock size={16} /> : <IconLock size={16} />}
                    onClick={() => handleBlockUser(row.id, row.blocked)}
                  >
                    {row.blocked ? 'Unblock' : 'Block'} User
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconEdit size={16} />}
                    onClick={() => setSelectedUser(row)}
                  >
                    Edit Roles
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTrash size={16} />}
                    onClick={() => handleRemoveUser(row.id)}
                  >
                    Remove User
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconMessage size={16} />}
                    onClick={() => handleMessageUser(row)}
                  >
                    Message User
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}

        records={records}
        totalRecords={users.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={handlePageChange}
        highlightOnHover
        verticalSpacing="sm"
      />

      {/* Add New User Modal */}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Add New User"
      >
        <TextInput
          label="First Name"
          placeholder="Enter first name"
          value={newUser.first_name}
          onChange={(e) => setNewUser({ ...newUser, first_name: e.currentTarget.value })}
        />
        <TextInput
          label="Last Name"
          placeholder="Enter last name"
          value={newUser.last_name}
          onChange={(e) => setNewUser({ ...newUser, last_name: e.currentTarget.value })}
        />

       <TextInput
          label="Mobile Number"
          placeholder="Enter mobile number"
          value={newUser.mobile_number}
          onChange={(e) => setNewUser({ ...newUser, mobile_number: e.currentTarget.value })}
        />
        <TextInput
          label="Email"
          placeholder="Enter email"
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.currentTarget.value })}
        />
        <MultiSelect
          label="Roles"
          placeholder="Select roles"
          data={roles.map((role) => ({ value: role.id, label: role.name }))}
          value={newUser.roles}
          onChange={(values) => setNewUser({ ...newUser, roles: values })}
        />
        <Group position="right" mt="md">
          <Button onClick={handleAddUser}>{isSubmitting ? <Loader/>:"Add User"}</Button>
        </Group>
      </Modal>

      {/* Edit Roles Modal */}
      {selectedUser && (
        <Modal
          opened={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          title={`Edit Roles for ${selectedUser.first_name} ${selectedUser.last_name}`}
        >
        <MultiSelect
        label="Roles"
        placeholder="Select roles"
        data={roles.map((role) => ({ value: role.id, label: role.name }))}
        value={selectedUser.roles.map((role) => role.id)}  // Map roles to IDs
        onChange={(values) => handleAssignRoles(selectedUser.id, values)}
        />
          <Group position="right" mt="md">
            <Button onClick={() => setSelectedUser(null)}>Save</Button>
          </Group>
        </Modal>
      )}
    </div>
  );
};

export default ManageUsersPage;
