
//Validate mobile phone number both in national and international formats
export  function isMobile(phone:string){  
   
    const regix = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if(regix.test(phone)===false){
        return false;
    }
    else {
        return true;
    }
  



}
export  function isEmail(email:string){  
    
    const regix = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
    if(!regix.test(email)){
        return false;
    }
    else {
        return true;
    }

}

export  function isEmpty(value:any):boolean{
    if(value === null || value ===''){
        return true;
    }else{
        return false;
    }
}



    

export  function isNumber(value:number):boolean{
    if(isNaN(value)){
        return true;
    }else{
        return false;
    }
}

export  function isText(value:any):boolean{
    const reg = /^[A-Za-z]+$/;
    if(!reg.test(value)){
        return false;  
    }else{
        return true;
    }
    
}

export  function isStrongPassword(value:string):boolean{
    // Password validation (at least 8 characters, 1 digit, 1 uppercase, 1 lowercase)
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!value.match(passwordRegex)) {
       
        return false
      }else{
        return true;
      }
 
    
}

export  function isImageFile(fileInput:any):boolean{
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if(!allowedExtensions.exec(fileInput.value)) {
       
        fileInput.value = '';
        return false;
    }else{
        return true;
    }
    

}

export  function isAllowedFile(fileInput:any):boolean{
    const allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;
    if(!allowedExtensions.exec(fileInput.value)) {
       
        fileInput.value = '';
        return false;
    }else{
        return true;
    }
    

}

export  function isValidVerificationCode(code:number):boolean{
    if(!isNumber(code) || code.toString.length !== 6){
        return false
    }else{
        return true;
    }
}

export function formatCurrency(num:number|string) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isValidMoneyInput = (value:any) => {
    const pattern = /^\d+(\.\d{1,2})?$/; // Regular expression pattern for validating money input
    return pattern.test(value);
  };