import {useEffect, useState} from "react";
import {DataTable} from "mantine-datatable";
import {ICampaignStatement} from "../types";
import { Group, Text} from "@mantine/core";
import { toTitleCase } from "../utils/utils";

const PAGE_SIZE = 10;

interface StatementProps {
    statements: ICampaignStatement[];
}

const CampaignStatementTable:React.FC<StatementProps> = ({statements}) => {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(statements.slice(0, PAGE_SIZE));


    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
       statements && setRecords(statements.slice(from, to));
    }, [page,statements]);

    return (
        <DataTable
            columns={[
                {
                    accessor:'date'
                },
                {
                    accessor:'amount'
                },

                {
                    accessor:'orginal_currency'
                },
                {
                    accessor:'service_fee'
                },
                {
                    accessor:'received_amount'
                },

                {
                    accessor:'ex_rate'
                },


                {
                    accessor:'settled_amount'
                },
                {
                    accessor:'settld_currency'
                },
                {
                    accessor:'status'
                },


                {
                    accessor: 'Donor',
                    render: ({donor}: ICampaignStatement) =>
                        <Group>
                           
                            <Text>{toTitleCase(donor.first_name)} {toTitleCase(donor.last_name)}</Text>
                        </Group>
                },
            ]}
            records={records}
            totalRecords={statements && statements.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            highlightOnHover
            verticalSpacing="sm"
            striped
        />
    );
};

export default CampaignStatementTable;
