// AuthSuccess.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthSuccess: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the token and user info from URL parameters
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const user = params.get('user') ? JSON.parse(decodeURIComponent(params.get('user') as string)) : null;

    if (token && user) {
      // Store the token and user info in localStorage
      localStorage.setItem('token', token);
      sessionStorage.setItem('token',token)
      localStorage.setItem('user', JSON.stringify(user));

      // Redirect the user to the dashboard
      navigate('/dashboard');
    } else {
      // If token is missing, redirect to login failure page
      navigate('/login-failed');
    }
  }, [navigate]);

  return (
    <div>
      <h2>Processing Authentication...</h2>
    </div>
  );
};

export default AuthSuccess;
