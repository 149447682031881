import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from './paths';

const baseUrl: string = BASE_URL;

interface ErrorObject {
  errorMessage: string | null;
  statusCode: number | null;
  validationErrors: any | null;
  errorType: string | null;
}

let errorObj: ErrorObject = {
  errorMessage: null,
  statusCode: null,
  validationErrors: null,
  errorType: null
};

interface ResponseError {
  response: {
    status: number;
    data: any;
    headers: any;
  };
}

interface RequestError {
  request: any;
  message: string;
}

//type ApiResponse<T = any> = AxiosResponse<T> | ErrorObject;


export const makePostRequest = async (url: string, data: object): Promise<any> => {
  const end_point = baseUrl + url;
  try {
    let res: AxiosResponse = await axios.post(end_point, data);
    return res;
  } catch (error:any) {
    if ((error as ResponseError).response) {
      return responseError(error as ResponseError);
    } else {
      return requestError(error as RequestError);
    }
  }
};

export const makePutRequest = async (url: string, data: object): Promise<AxiosResponse | ErrorObject> => {
  const end_point = baseUrl + url;
  try {
    let res: AxiosResponse = await axios.put(end_point, data);
    return res;
  } catch (error) {
    if ((error as ResponseError).response) {
      return responseError(error as ResponseError);
    } else {
      return requestError(error as RequestError);
    }
  }
};

export const makeDeleteRequest = async (url: string): Promise<AxiosResponse | ErrorObject> => {
  const end_point = baseUrl + url;
  try {
    let res: AxiosResponse = await axios.delete(end_point);
    return res;
  } catch (error) {
    if ((error as ResponseError).response) {
      return responseError(error as ResponseError);
    } else {
      return requestError(error as RequestError);
    }
  }
};

export const makeGetRequest = async (url: string): Promise<AxiosResponse | ErrorObject> => {
  const end_point = baseUrl + url;
  try {
    let res: AxiosResponse = await axios.get(end_point);
    return res;
  } catch (error) {
    if ((error as ResponseError).response) {
      return responseError(error as ResponseError);
    } else {
      return requestError(error as RequestError);
    }
  }
};



export const makeMultipartFormPostRequest = async (url: string, data: object): Promise<AxiosResponse | ErrorObject> => {
  const end_point = baseUrl + url;
  try {
    let res: AxiosResponse = await axios.post(end_point, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res);
    return res;
  } catch (error) {
    if ((error as ResponseError).response) {
      return responseError(error as ResponseError);
    } else {
      return requestError(error as RequestError);
    }
  }
};

const responseError = (error: ResponseError): ErrorObject => {
  console.log('ERROR RESPONSE', error.response);
  if (error.response.status === 422) {
    errorObj.statusCode = error.response.status;
    errorObj.errorMessage = error.response.data;
    errorObj.errorType = "validation";
    errorObj.validationErrors = error.response.data.errors;
    return errorObj;
  } else if (error.response.status === 401) {
    errorObj.statusCode = error.response.status;
    errorObj.errorMessage = error.response.data || 'please login';
    errorObj.errorType = "authentication";
    localStorage.removeItem('user');
    localStorage.removeItem('church');
    localStorage.removeItem('roles');
    sessionStorage.removeItem('token');
    return errorObj;
  } else if (error.response.status === 403) {
    errorObj.statusCode = error.response.status;
    errorObj.errorMessage = error.response.data || 'Unauthorized Request!';
    errorObj.errorType = "authorization";
    return errorObj;
  } else if (error.response.status === 400) {
    errorObj.statusCode = error.response.status;
    errorObj.errorMessage = error.response.data || 'Bad Request';
    errorObj.errorType = "badrequest";
    return errorObj;
  } else if (error.response.status === 429) {
    errorObj.statusCode = error.response.status;
    errorObj.errorMessage = 'Request blocked, Try again after 30 minutes';
    errorObj.errorType = "Too Many Requests";
    return errorObj;
  } else {
    console.log('response error', error);
    errorObj.statusCode = error.response.status;
    errorObj.errorMessage =  error.response.data;
    errorObj.errorType = "server";
    return errorObj;
  }
};

const requestError = (error: RequestError): ErrorObject => {
  console.log('request error', error);
  errorObj.errorType = 'request';
  errorObj.errorMessage = error.message;
  errorObj.statusCode = 500;
  return errorObj;
};
