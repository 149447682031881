import {forwardRef, useEffect, useState} from 'react';
import {Group, Loader, Select, Text} from "@mantine/core";
import {ICurrency} from "../types";
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { makeGetRequest } from '../services/httpHelper';


interface CurrencySelectProps {
    value: string;
    onChange: (value: string) => void;
  }


const CurrencySelectItem = forwardRef<HTMLDivElement, ICurrency>(
    ({name, cc, ...others}: ICurrency, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Text size="sm">{name}</Text>
                <Text size="sm" opacity={0.65}>
                    {cc}
                </Text>
            </Group>
        </div>
    )
);

const CurrencySelect:React.FC<CurrencySelectProps> = ({value,onChange}) => {

    const [currencyData, setCurrencyData] = useState<ICurrency[]>([]);
    const [isLoading,setIsloading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getCurrencies = async () => {
        setIsloading(true);
        const res = await makeGetRequest('currencies');
      
        if (isErrorObject(res)) {
          setIsloading(false);
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
        } else {
          const results = res.data;
          setCurrencyData(results);
          setIsloading(false);
          setError(null);
        }
      };

    useEffect(()=>{
        getCurrencies();
    },[])

    if(isLoading){
        return <Loader/>
    }
    if(error){
        return <p className='error'>{error}</p>
    }
    return (
        <Select
            label="What currency do you want to raise money in?"
            itemComponent={CurrencySelectItem}
            data={currencyData.map(c => ({value: c.cc, label: c.name, ...c}))}
            searchable
            clearable
            maxDropdownHeight={300}
            nothingFound="Nobody here"
            filter={(value, item) =>
                item?.name?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                item?.code?.toLowerCase().includes(value?.toLowerCase().trim())
            }
            value={value}
            onChange={onChange}
        />
    );
};

export default CurrencySelect;
