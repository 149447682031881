import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button, Divider,
    Loader,
} from '@mantine/core';
import { Helmet } from "react-helmet";
import { IconBrandFacebook, IconBrandGoogle } from "@tabler/icons-react";
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { isEmpty } from '../utils/FormValidator';
import { makePostRequest } from '../services/httpHelper';
import validateLoginForm from '../validators/LoginFormValidator';
import { BASE_URL } from '../services/paths';
import { Link } from 'react-router-dom';
//import { BASE_URL } from '../services/paths';


const LoginPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false)


    const navigate = useNavigate();

    const [values, setValues] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsSubmitting(true)
        setErrors(validateLoginForm(values));
        const data = {
            password: values.password,
            user_name: values.email,
        }
        const res = await makePostRequest('auth/login', data);

        if (res && res.errorType) {
            setIsSubmitting(false);
            setError(res.errorMessage)
        } else {
            res.data && localStorage.setItem('user', JSON.stringify(res.data));
            res.data && sessionStorage.setItem('token', res.data.token);
            setIsSubmitting(false);
            setIsSuccess(true)
        }
    }

    const handleFacebookLogin = async () => {
        const endPoint = BASE_URL+"auth/facebook";
         window.open(endPoint,"_self")
       
    }

    const handleGoogleLogin = async () => {
        const endPoint = BASE_URL+"auth/google";
        window.open(endPoint,"_self")

    }



  

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title align="center" sx={() => ({ fontWeight: 900 })}>
                    Welcome back!
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    Do not have an account yet?{' '}
                    <Anchor size="sm" component="button">
                        Create account
                    </Anchor>
                </Text>

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <Group grow mb="md" mt="md">
                        <Button radius="xl" leftIcon={<IconBrandFacebook size={18}/>} onClick={handleFacebookLogin}>Facebook</Button>
                        <Button radius="xl" leftIcon={<IconBrandGoogle size={18}/>} onClick={handleGoogleLogin}>Google</Button>
                    </Group>
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader />}
                    {isSuccess &&  <Navigate to={`/dashboard`} />}                  
                    {error && <p className='error'>{error}</p>}

                    <TextInput label="Email" placeholder="Email" name="email" value={values.email} onChange={handleChange} />
                    {!isEmpty(errors.email) && <p className='error'>{errors.email}</p>}
                    <PasswordInput label="Password" name="password" placeholder="Your password"  mt="md" onChange={handleChange}/>
                    {!isEmpty(errors.password) && <p className='error'>{errors.password}</p>}
                    <Group position="apart" mt="lg">
                        <Checkbox label="Remember me" />
                        <Anchor component="button" size="sm" onClick={() => navigate(`/forgot-password`)}>
                            Forgot password?
                        </Anchor>
                    </Group>
                    <Button fullWidth mt="xl" onClick={(e) => handleLogin(e)}>
                        Sign in
                    </Button>

                    <br/>                 

                  <Group position="apart" mt="lg">
                        
                        <Link to="/signup">
              
                        <Anchor component="button" size="sm" >
                            Sign Up
                        </Anchor>
                    
                        </Link>
                    </Group>
                </Paper>
            </Container>
        </>
    );
}

export default LoginPage;
