import { useEffect, useState } from 'react';
import { Box, Button,  Notification, Group, Text, Avatar, Loader } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { IconAlertCircle,  IconEdit, IconTrash, IconMessage } from '@tabler/icons-react';
import { makeGetRequest } from '../services/httpHelper';
import { ICampaignAdmin } from '../types';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';

const PAGE_SIZE = 10;
type adminProps={
    campaignId:string;
}

const CampaignAdmins:React.FC<adminProps> = ({campaignId}) => {
    const [admins, setAdmins] = useState<ICampaignAdmin[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<ICampaignAdmin[]>([]);


    useEffect(() => {
        const fetchAdmins = async () => {
            const res = await makeGetRequest(`campaign-admins/${campaignId}`);
            setIsLoading(false);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setAdmins(res.data);
                setRecords(res.data.slice(0, PAGE_SIZE));
            }
        };

        fetchAdmins();
    }, []);

    const handlePageChange = (page: number) => {
        setPage(page);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(admins.slice(from, to));
    };

   

  

    

   

    return (
        <Box mx="auto" p="md">
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
      

            {isLoading ? (
                <Loader />
            ) : (
                <>
                 

                    <DataTable
                       columns={[
                        {
                            accessor: 'name',
                            render: ({ user }: ICampaignAdmin) => (
                                <Group>
                                    <Avatar size="sm" radius="xl" />
                                    <Text>{`${user.first_name} ${user.last_name}`}</Text>
                                </Group>
                            ),
                        },
                        {
                            accessor: 'email',
                            render: ({ user }: ICampaignAdmin) => <Text>{user.email}</Text>,
                        },
                        {
                            accessor: 'mobile_number',
                            render: ({ user }: ICampaignAdmin) => <Text>{user.mobile_number}</Text>,
                        },
                        {
                            accessor: 'role',
                            render: ({ role }: ICampaignAdmin) => <Text>{role}</Text>, // Adjust according to your role property
                        },
                        {
                            accessor: 'actions',
                            render: () => (
                                <Group spacing="sm">
                                    <Button
                                        leftIcon={<IconEdit size={16} />}
                                        size="xs"
                                        disabled
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        leftIcon={<IconTrash size={16} />}
                                        size="xs"
                                        color="red"
                                        disabled
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        leftIcon={<IconMessage size={16} />}
                                        size="xs"
                                        disabled
                                    >
                                        Message Admin
                                    </Button>
                                </Group>
                            ),
                        },
                    ]}
                        records={records}
                        totalRecords={admins.length}
                        recordsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={handlePageChange}
                        highlightOnHover
                        verticalSpacing="sm"
                    />

                
                  

                   
                </>
            )}
        </Box>
    );
};

export default CampaignAdmins;
