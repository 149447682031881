import {
    Anchor,
    Button,
    Container,
    Divider,
    Loader,
    Paper,
    PasswordInput,
    Text,
    Title,
} from '@mantine/core';
import {Helmet} from "react-helmet";
import { useState } from 'react';
import validateSignupForm from '../validators/SignupFormValidator';
import {  isEmpty } from '../utils/FormValidator';
import { makePostRequest } from '../services/httpHelper';
import { Navigate, useParams } from 'react-router-dom';

const SetNewPasswordPage = () => {

    const { email } = useParams();
    
    const [values, setValues] = useState({
       
        password:'',
        password_confirm:'',    

    });
    
    const [errors, setErrors] = useState({
      
        password:'',
        password2:'',
     
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error,setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
        //setErrors(validateSignupForm(values));
    }

    const handleChangePassword =async(e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        setError(null)
        setIsSubmitting(true)
        setErrors(validateSignupForm(values));
        const data ={
            email:email,
            password:values.password,
          
        }

        if(Object.keys(errors).length){
            setIsSubmitting(false);
            return;
        }

        const res = await makePostRequest('auth/change-password',data);

        if(res && res.errorMessage){
            console.log('ERROR',res.errorMessage)

            setIsSubmitting(false);
            setError(res.errorMessage);
        
          }else{
            setIsSuccess(true)
            setIsSubmitting(false);
            setError(null)
            
          }


    }
    return (
        <>
            <Helmet>
                <title>Set New Password</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={() => ({fontWeight: 900})}
                >
                   Set New Password
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    Login Instead?{' '}
                    <Anchor size="sm" component="button">
                        Login
                    </Anchor>
                </Text>

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                   
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader/>}
                    {isSuccess && <Navigate to={`/login`}/>}
                    {error && <p className='error'>{error}</p>}

                              
                    

                    <PasswordInput label="Password" placeholder="Password" name="password" onChange={handleChange} value={values.password} mt="md"/>
                    {!isEmpty(errors.password) && <p className='error'>{errors.password}</p>}

                    <PasswordInput label="Confirm Password" placeholder="Confirm Password" name="password2" onChange={handleChange}  mt="md"/>
                    {!isEmpty(errors.password2) && <p className='error'>{errors.password2}</p>}
                  
                    <Button fullWidth mt="xl" onClick={(e)=>handleChangePassword(e)}>
                        Sign Up
                    </Button>
                </Paper>
            </Container>
        </>
    );
}

export default SetNewPasswordPage;
