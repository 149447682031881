import {forwardRef, useEffect, useState} from 'react';
import { Loader, Select, Text} from "@mantine/core";
import {ICampaign} from "../types";
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { makeGetRequest } from '../services/httpHelper';

interface CampaignSelectProps {
    value: string;
    onChange: (value: string) => void;
  }


  interface User {
    id:string;
    first_name: string;
    role: string;  
    is_admin:boolean
}

const CampaignSelectItem = forwardRef<HTMLDivElement, ICampaign>(
    ({id, title,  ...others}: ICampaign, ref) => (
        <div ref={ref} {...others}>
          
            <Text size="sm">{title}</Text>

        </div>
    )
);



const CampaignSelect: React.FC<CampaignSelectProps> = ({ value, onChange }) => {
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const user: User = JSON.parse(localStorage.getItem('user') || '{}');

  
    const getUserCampaigns = async () => {
      setIsLoading(true);
      const res = await makeGetRequest(`user-campaigns/${user.id}`);
  
      if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
        const results = res.data;
        setCampaigns(results);
        setIsLoading(false);
        setError(null);
      }
    };
  
    useEffect(() => {
      getUserCampaigns();
    }, []);
  
    if (isLoading) {
      return <Loader />;
    }
    if (error) {
      return <p className='error'>{error}</p>;
    }
  
    return (
      <Select
        label="Campaign"
        itemComponent={CampaignSelectItem}
        data={campaigns.map(c => ({ value: c.id, label: c.title, ...c }))}
        searchable
        clearable
        maxDropdownHeight={300}
        nothingFound="Nothing found"
        filter={(value, item) =>
          item?.title?.toLowerCase().includes(value?.toLowerCase().trim()) ||
          item?.id?.toLowerCase().includes(value?.toLowerCase().trim())
        }
        value={value}
        onChange={onChange}
      />
    );
  };
  
  export default CampaignSelect;
