import {
  
    Button,
    Container,
    Divider,
    Loader,
    Paper,
    PasswordInput,
    Title,
} from '@mantine/core';
import {Helmet} from "react-helmet";
import { useState } from 'react';
import validateSignupForm from '../validators/SignupFormValidator';
import {  isEmpty } from '../utils/FormValidator';
import { makePostRequest } from '../services/httpHelper';
import { Navigate } from 'react-router-dom';

const ChangePasswordPage = () => {

    
    const [values, setValues] = useState({
       
        password:'',
        password_confirm:'',
        current_password:''    

    });
    
    const [errors, setErrors] = useState({
      
        password:'',
        password2:'',
        current_password:'',
     
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error,setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }

    // define user interface

    interface User {
        id: string;
        name: string;
        email: string;
        // add other properties as needed
      }
      const userJson = localStorage.getItem('user');
      const user: User | null = userJson ? JSON.parse(userJson) as User : null;



    const handleChangePassword =async(e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        setError(null)
        setIsSubmitting(true)
        setErrors(validateSignupForm(values));
        const data ={
            user_id:user && user.id,
            new_password:values.password,
            current_password:values.current_password
          
        }

        if(Object.keys(errors).length){
            setIsSubmitting(false);
            return;
        }

        const res = await makePostRequest('auth/chenge-password',data);

        if(res && res.errorMessage){
            console.log('ERROR',res.errorMessage)

            setIsSubmitting(false);
            setError(res.errorMessage);
        
          }else{
            setIsSuccess(true)
            setIsSubmitting(false);
            setError(null)
            
          }


    }
    return (
        <>
            <Helmet>
                <title>Change Password</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={() => ({fontWeight: 900})}
                >
                   Change Password
                </Title>
             

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                   
                    <Divider label="Set something that is easier for you to remember but hard to guess" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader/>}
                    {isSuccess && <Navigate to={`/login`}/>}
                    {error && <p className='error'>{error}</p>}

                              
                    <PasswordInput label="Current Password" placeholder="Current Password" name="current_password" onChange={handleChange} value={values.current_password} mt="md"/>
                    {!isEmpty(errors.current_password) && <p className='error'>{errors.current_password}</p>}

                    <PasswordInput label="New Password" placeholder="New Password" name="password" onChange={handleChange} value={values.password} mt="md"/>
                    {!isEmpty(errors.password) && <p className='error'>{errors.password}</p>}

                    <PasswordInput label="Confirm New Password" placeholder="Confirm New Password" name="password2" onChange={handleChange}  mt="md"/>
                    {!isEmpty(errors.password2) && <p className='error'>{errors.password2}</p>}
                  
                    <Button fullWidth mt="xl" onClick={(e)=>handleChangePassword(e)}>
                        Change Password
                    </Button>
                </Paper>
            </Container>
        </>
    );
}

export default ChangePasswordPage;
