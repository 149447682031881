import {forwardRef, useEffect, useState} from 'react';
import {Group, Loader, Select, Text} from "@mantine/core";
import {IPaymentMethod} from "../types";
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { makeGetRequest } from '../services/httpHelper';


interface PaymentMethodSelectProps {
    value: string;
    onChange: (value: string) => void;
  }


const PaymentMethodSelectItem = forwardRef<HTMLDivElement, IPaymentMethod>(
    ({payment_service, ...others}: IPaymentMethod, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Text size="sm">{payment_service}</Text>
                
            </Group>
        </div>
    )
);

const PaymentMethodSelect:React.FC<PaymentMethodSelectProps> = ({value,onChange}) => {

    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [isLoading,setIsloading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getPaymentMethods = async () => {
        setIsloading(true);
        const res = await makeGetRequest('payment-services');
      
        if (isErrorObject(res)) {
          setIsloading(false);
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
        } else {
          const results = res.data;
          const supportMethods = results.filter((method:IPaymentMethod)=>method.payment_service==='M-PESA' ||method.payment_service==='Bank')
          setPaymentMethods(supportMethods);
          setIsloading(false);
          setError(null);
        }
      };

    useEffect(()=>{
        getPaymentMethods();
    },[])

    if(isLoading){
        return <Loader/>
    }
    if(error){
        return <p className='error'>{error}</p>
    }
    return (
        <Select
            label="Payment Method *"
            itemComponent={PaymentMethodSelectItem}
            data={paymentMethods.map(p => ({value: p.payment_service, label: p.payment_service, ...p}))}
            searchable
            clearable
            maxDropdownHeight={300}
            nothingFound="Nobody here"
            filter={(value, item) =>
                item?.payment_service?.toLowerCase().includes(value?.toLowerCase().trim()) 
            }
            value={value}
            onChange={onChange}
        />
    );
};

export default PaymentMethodSelect;
