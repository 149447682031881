import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { Container, Loader, Notification } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

// Define the component as a functional component
const CapturePaypalOrder: React.FC = () => {

    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate =useNavigate();
    const orderId = localStorage.getItem('orderId');

    useEffect(()=>{

        const captureOrder = async () => {
            const res = await makePostRequest(`capture-paypal-order/${orderId}`,{});
            setIsLoading(false);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setIsLoading(false)
                navigate('/donation-success',{
                  state:{
                    amount:res.data.amount,
                    campaignId:res.data.campaignId,
                    currency:res.data.currency
                  }
                })
                
            }
        };

        captureOrder()

    },[])
   
  return (
   <Container>
     <div className='container'>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="message-box _success _failed">
            <i className="fa fa-times-circle" aria-hidden="true"></i>
            {
                isLoading && <>
                  <h2> <span><Loader/></span> Processing...Please Wait! </h2>

                </>
            }
            {
                error !== null &&
                <>
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>

<p><Link to="/campaigns" className="link-anchor">TRY AGAIN</Link></p>

                </>
            }
          
            
          </div>
        </div>
      </div>
    </div>
   </Container>
  );
}

export default CapturePaypalOrder;
