import { Box, BoxProps, Button, Container, Flex,  SimpleGrid, Stack,  Title, TitleProps } from "@mantine/core";
import {  useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mantine/hooks";
import { makePostRequest } from "../services/httpHelper"; // Adjust import based on your file structure
import ErrorHandler from "../services/ErrorHandler"; // Adjust import based on your file structure
import { isErrorObject } from "../utils/utils";
import CampaignSelect from "../components/CampaignSelect";
import { DateInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import CampaignStatementTable from "../components/CampaignStatementTable";
import { ICampaignStatement } from "../types";




const CampaignStatementPage = (): JSX.Element => {
    const matchesMobile = useMediaQuery('(max-width: 768px)');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setTodate] = useState<Date | null>(null);
    const [statement, setStatement] = useState<ICampaignStatement[]>([]);






    const handleSelectedCampaign = (selectedCampaign:string) => {
        setSelectedCampaign(selectedCampaign);
      
        if (selectedCampaign !== '') {
         
        }
      };


      const getStatement = async () => {
        setIsLoading(true);

        const user =  JSON.parse(localStorage.getItem('user') || '{}');
        if(error){
            setIsLoading(false)
        }

        if(!selectedCampaign){
            setError('Select campaign before you continue')
            return;
        }
        if(!fromDate){
            setError('Choose statement start date')
            return;
        }
        if(!toDate){
            setError('Choose statement end date')
            return;
        }

        const data = {
            campaign_id:selectedCampaign,
            from_date: fromDate,
            to_date:toDate,
            user_id:user.id
            
          };
    
        const res = await makePostRequest('campaign-statement',data);
      
        if (isErrorObject(res)) {
            setIsLoading(false);
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
          setIsLoading(false)
        } else {
            setStatement(res.data)
            setIsLoading(false)
            setIsSuccess(true);
      

        }
      };
   




    const boxProps: BoxProps = {
        mt: matchesMobile ? 4 : 24,
        mb: matchesMobile ? 4 : 48,
        py: matchesMobile ? 16 : 24
    };

    const titleProps: TitleProps = {
        size: 32,
        weight: 700,
        mb: "lg",
        transform: 'capitalize',
        sx: { lineHeight: '40px' }
    };

   

    return (
        <>
            <Helmet>
                <title>Campaign Statement</title>
            </Helmet>
            <Box>
                <Container size="lg">
                    <Stack>
                        <Box {...boxProps}>
                            <Title {...titleProps} align="center"> Campaign Donation Statement</Title>
                        </Box>
                        <Flex
                            justify="space-between"
                            gap={{ base: 'sm', sm: 'lg' }}
                            direction={{ base: 'column-reverse', sm: 'row' }}
                        >

                                    <CampaignSelect
                                     value={selectedCampaign} 
                                     onChange={handleSelectedCampaign} 
                                    />
                         
                            <Flex align="center" gap="sm" justify={{ base: 'space-between', sm: 'flex-start' }}>
                                                    <DateInput
                                                    value={fromDate}
                                                    onChange={setFromDate}
                                                    label="Statement Start Date"
                                                    placeholder="From Date"
                                                    icon={<IconCalendar size={18}/>}
                                                />
                              <DateInput
                                value={toDate}
                                onChange={setTodate}
                                label="Statement End Date"
                                 placeholder="To Date"
                                icon={<IconCalendar size={18}/>}
                                                />

                        <Button onClick={getStatement}>Get Statment</Button>
                            </Flex>
                        </Flex>
                        <SimpleGrid
                            cols={3}
                            spacing="lg"
                            breakpoints={[
                                { maxWidth: 'md', cols: 2, spacing: 'md' },
                                { maxWidth: 'sm', cols: 1, spacing: 0 },
                            ]}
                        >
                            {isLoading && <p>Loading...</p> }
                        </SimpleGrid>
                        {error && <p className="error">{error}</p>}

                        {isSuccess && <CampaignStatementTable statements={statement}/>}
                    </Stack>

                </Container>
            </Box>
        </>
    );
};

export default CampaignStatementPage;
