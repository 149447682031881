import React from 'react';
import { Card, Text, Center } from '@mantine/core';
import {
  FaCheckCircle,
  FaDollarSign,
  FaDonate,
  FaChartBar,
  FaRecordVinyl,
  FaBuilding,
  FaHandsHelping,
  FaPeopleCarry
} from 'react-icons/fa';

interface ServiceBoxProps {
  icon: string;
  title: string;
  subtitle: string;
}

const ServiceBox: React.FC<ServiceBoxProps> = ({ icon, title, subtitle }) => {
  let getIcon;

  switch (icon) {
    case 'icon':
      getIcon = <FaCheckCircle style={{ fontSize: '50px', color: '#8DC750' }} />;
      break;
    case 'accounting':
      getIcon = <FaDollarSign style={{ fontSize: '50px', color: 'red' }} />;
      break;
    case 'contribution':
      getIcon = <FaDonate style={{ fontSize: '50px', color: 'blue' }} />;
      break;
    case 'report':
      getIcon = <FaChartBar style={{ fontSize: '50px', color: 'purple' }} />;
      break;
    case 'tracking':
      getIcon = <FaRecordVinyl style={{ fontSize: '50px', color: 'green' }} />;
      break;
    case 'budget':
      getIcon = <FaBuilding style={{ fontSize: '50px', color: 'amber' }} />;
      break;
    case 'volunteer':
      getIcon = <FaHandsHelping style={{ fontSize: '50px', color: 'olive' }} />;
      break;
    case 'group':
      getIcon = <FaPeopleCarry style={{ fontSize: '50px', color: '#008080' }} />;
      break;
    default:
      getIcon = <FaCheckCircle style={{ fontSize: '50px', color: '#0e1' }} />;
      break;
  }

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Center>
        <div>{getIcon}</div>
      </Center>
      <Text align="center" weight={700} size="lg" mt="md">
        {title}
      </Text>
      <Text align="center" size="sm" mt="sm">
        {subtitle}
      </Text>
    </Card>
  );
};

export default ServiceBox;
