import { ActionIcon, Button, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {useDisclosure} from "@mantine/hooks";
import { IconShare } from '@tabler/icons-react';
import ShareModal from './ShareModal';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { isErrorObject } from '../utils/utils';
import { ICampaign } from '../types';
import { useLocation, useNavigate } from 'react-router-dom';


// Define props for the component
/*interface ThankYouPageProps {
  amount: number;
  campaignId: string;
  currency:string;
}*/

const DonationThankYouPage: React.FC = () => {
    const [opened, {open, close}] = useDisclosure(false);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [campaign, setCampaign] = useState<ICampaign>();

    const {state} = useLocation();
    const amount = state.amount;
    const campaignId=state.campaignId;
    const currency = state.currency

    const navigate = useNavigate();

    const goToHomePage=()=>{
      navigate('/')
    }



    useEffect(()=>{

        const fetchCampaign = async () => {
            setIsLoading(true);
            const res = await makeGetRequest( `campaign/${campaignId}`);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
              } else {
                setCampaign(res.data);
            }
            setIsLoading(false);
        };
        fetchCampaign();

    },[])

  return (
    <div>
         {isLoading && <Loader/>}
         {error && <p className="error">{error}</p>}
      <head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Thank You</title>
        <link href='https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700' rel='stylesheet' type='text/css' />
        <style>
          @import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
          @import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
        </style>
        <link rel="stylesheet" href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css" />
      </head>
      <body>
        <header className="site-header" id="header">
          <h1 className="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
        </header>

        <div className="main-content">
          <i className="fa fa-check main-content__checkmark" id="checkmark"></i>
          <p className="main-content__body" data-lead-id="main-content-body">
            Thank you for your generous donation of <strong>{currency}{amount} </strong>to the campaign <strong>{campaign?.title}</strong>. Your support means a lot to us!
          </p>
        </div>

        <footer className="site-footer" id="footer">
          <div>

            <div>
            <ActionIcon
         variant="subtle"
        onClick={open}
         color="blue"
        title="Share with your friends on social media platforms"
         size="lg"
            >
<Button style={{backgroundColor:"blue"}}><IconShare size={28} style={{color:"white"}}/> Share Campaign</Button>
</ActionIcon>

            </div>

            <div>
              <Button onClick={goToHomePage}>Go to HOME</Button>
              
            </div>
          </div>
     

<ShareModal opened={opened} onClose={close} campaign={campaign} iconSize={18}/> 

        </footer>
      </body>
    </div>
  );
};

export default DonationThankYouPage;
