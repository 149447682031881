import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const WithdrawalSuccess = () => {


    const{state} = useLocation();

    const amount = state.withdrawnAmount || 100
    const campaignTitle = state.campaignTitle || 'Sample campaign title';
    const bal = state.availabeBalance || 100;
    return (
      <div style={styles.container}>
        <h2>Withdrawal Successful</h2>
        <p>
          You have successfully withdrawn{' '}
          <strong>
            {state.currency} {amount.toFixed(2)}
          </strong>{' '}
          from the campaign: <strong>{campaignTitle}</strong>.
        </p>
        <p>
          Your new available balance is{' '}
          <strong>
            {state.currency} {bal.toFixed(2)}
          </strong>.
        </p>
        <br/>
        <Link to="/dashboard">Back Dashboard</Link>
      </div>
    );
  };
  
  // Define styles for the component (optional, for better presentation)
  const styles = {
    container: {
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      maxWidth: '400px',
      margin: '20px auto',
      textAlign: 'center' as 'center', // Type assertion for TypeScript
    },
  };
  
  export default WithdrawalSuccess;
  