import { Container } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface PrivacyPolicyProps {
  platformName: string;
  jurisdiction: string;
  contactEmail: string;
  contactPhone: string;
  effectiveDate: string;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({
  platformName, 
  contactPhone,
  effectiveDate,
}) => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Effective Date: {effectiveDate}</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to {platformName}. Your privacy is important to us. This Privacy Policy explains how we
        collect, use, disclose, and protect your information when you use our platform and services.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        We collect personal information that you provide directly, such as your name, email address,
        phone number, billing information, and any other information you choose to provide. We also
        collect campaign information, payment information, and usage data to enhance your experience on
        our platform.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>
        We use your information to operate, maintain, and improve our platform, communicate with you,
        and comply with legal obligations. We may also use your data to send you updates, newsletters,
        and marketing materials.
      </p>

      <h2>4. Sharing Your Information</h2>
      <p>
        We may share your information with third-party service providers, legal authorities, and in the
        event of a business transfer. Your information is shared only to fulfill the purposes outlined
        in this policy.
      </p>

      <h2>5. Security of Your Information</h2>
      <p>
        We take reasonable measures to protect your information. However, no method of transmission over
        the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2>6. Your Choices</h2>
      <p>
        You can update your account information at any time and opt-out of receiving marketing
        communications by following the unsubscribe instructions in our emails.
      </p>

      <h2>7. Third-Party Links</h2>
      <p>
        Our platform may contain links to third-party websites. We are not responsible for the privacy
        practices or content of those sites.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted on our platform, and
        the effective date will be updated accordingly.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at{' '}
        <Link to="/contact-us">Contact Us</Link> or call us at {contactPhone}.
      </p>
    </div>
  );
};

// Default export with FundReach specific details
export default function FundReachPrivacyPolicy() {
  return (
 <Container style={{backgroundColor:"#eee"}}>
     <PrivacyPolicy
      platformName="FundReach"
      jurisdiction="Republic of Kenya"
      contactEmail="customercare@roshtech.co.ke"
      contactPhone="+254717245218"
      effectiveDate="August 29, 2024"
    />
 </Container>
  );
}
