import { isEmpty, isEmail, } from "../utils/FormValidator";


export default function validateLoginForm(values:any){
    let errors = {
        email:'',
        password:'',
       
    };
   

   
    if(isEmpty(values.email.trim())){
        errors.email = 'Enter your email';
    }

 
    if(!isEmail(values.email.trim())){
        errors.email = 'Enter a valid email';
    }
    
    if(isEmpty(values.password.trim())){
        errors.password = 'Enter Password';
    }


   
    
   
   return errors;
   
}