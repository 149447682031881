import { isEmpty, isEmail, isMobile } from "../utils/FormValidator";


export default function validateSignupForm(values:any){
    let errors = {
        first_name:'',
        last_name:'',
        email:'',
        mobile_number:'',
        password:'',
        password2:'',
        current_password:''
    };
    if(isEmpty(values.first_name)){
        errors.first_name = 'Enter first name';
    }
  
    if(isEmpty(values.last_name)){
        errors.last_name = 'Enter Last Name';
    }

    if(values.last_name.length < 3){
        errors.last_name = ' last name too short';

    }

   
    if(isEmpty(values.email)){
        errors.email = 'Enter your email';
    }

 
    if(!isEmail(values.email)){
        errors.email = 'Enter a valid email';
    }
    if(isEmpty(values.mobile_number)){
        errors.mobile_number = 'Enter Mobile Number';
    }
    if(!isMobile(values.mobile_number)){
        errors.mobile_number = 'Enter a valid mobile number';
    }
    if(isEmpty(values.password2)){
        errors.password2 = 'Confirm Password';
    }
    if(isEmpty(values.current_password)){
        errors.current_password = 'Enter New Password';
    }
    if(isEmpty(values.password)){
        errors.password = 'Choose Password';
    }else if(values.password !== values.password2){
        errors.password = 'Your password do not match';
    }


   
    
   
   return errors;
   
}