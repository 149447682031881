import { forwardRef } from 'react';
import { Group, Select, Text } from "@mantine/core";
import {
  IconClipboardHeart,
  IconSos,
  IconLeaf,
  IconHeartHandshake,
  IconReportMoney,
  IconCat,
  IconFireHydrant,
  IconAugmentedReality,
  IconDeviceTv,
  IconBuilding,
  IconSchool,
  IconRings,IconBuildingChurch,
  IconMoodAnnoyed
} from "@tabler/icons-react";
import { toTitleCase } from '../utils/utils';

interface Category {
  id: string;
  category: string;
  description: string;
  icon: React.ComponentType<{ size: number }>;
}

interface CategorySelectProps {
  data: Category[];
  value: string;
  onChange: (value: string) => void;
}



const iconMap: Record<string, React.ComponentType<{ size: number }>> = {
    medical: IconClipboardHeart,
    emergency: IconSos,
    development:IconBuilding,
    education:IconSchool,
    wedding:IconRings,
    environment: IconLeaf,
    nonprofit: IconHeartHandshake,
    financial: IconReportMoney,
    animals: IconCat,
    crisis: IconFireHydrant,
    technology: IconAugmentedReality,
    film: IconDeviceTv,
    church:IconBuildingChurch,
    funeral:IconMoodAnnoyed
  };

  interface CategorySelectItemProps {
    category: string;
  }

  const CategorySelectItem = forwardRef<HTMLDivElement, CategorySelectItemProps>(
    ({ category, ...others }, ref) => {
      const IconComponent = iconMap[category.toLowerCase()] || null;
      return (
        <div ref={ref} {...others}>
          <Group noWrap>
            {IconComponent && <IconComponent size={18} />}
            <div>
              <Text size="sm">{toTitleCase(category)}</Text>
            </div>
          </Group>
        </div>
      );
    }
  );

  const CategorySelect:React.FC<CategorySelectProps>  = ({ data,value,onChange }: CategorySelectProps) => {
    return (
      
      <Select
        label="Category"
        itemComponent={CategorySelectItem}
        data={data.map(c => ({
          value: c.id,
          label: c.category,
          category: c.category,
        }))}
        searchable
        clearable
        maxDropdownHeight={300}
        nothingFound="Nothing found"
        filter={(value, item) =>
          item?.category?.toLowerCase().includes(value?.toLowerCase().trim())
        }
        value={value}
        onChange={onChange}

      />
    );
  };

export default CategorySelect;
