import {Helmet} from "react-helmet";
import {
   // ActionIcon,
    Alert,
    //Anchor,
    Box,
    Button,
    Checkbox,
    Container,
   // Flex,
    Group,
    Loader,
    NumberInput,
    Paper,
    PaperProps,
    Radio,
    SegmentedControl,
   // Select,
    SimpleGrid,
    Stack,
    Stepper,
    Text,
    TextInput,
    Title,
    TitleProps,
    useMantineTheme
} from "@mantine/core";
import {Link, RichTextEditor} from '@mantine/tiptap';
import {useEditor} from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import React, {/*forwardRef*/ useEffect, useState} from "react";
import {DateInput} from "@mantine/dates";
import {
    IconBrandAlipay,
   // IconBrandFacebook,
   // IconBrandLinkedin,
    IconBrandPaypal,
    /*IconBrandTwitter,
    IconBrandWhatsapp,
    IconBrandYoutube,*/
    IconCalendar,
    //IconCheck,
    IconChevronLeft,
    IconChevronRight,
    IconCurrency,
    IconCurrencyDollar,
    IconInfoCircleFilled,
    IconLink,
    IconMail,
   // IconPlus,
    //IconTrash
} from "@tabler/icons-react";
import {CategorySelect, CountrySelect, CurrencySelect, FileDropzone} from "../components";
//import {randomId} from "@mantine/hooks";
//import {useForm} from "@mantine/form";
import { makeGetRequest, makeMultipartFormPostRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { isErrorObject } from "../utils/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { formatCurrency } from "../utils/FormValidator";
import CampaignPreview from "./CampaignPreview";





const CreateCampaignPage = () => {
    const theme = useMantineTheme()
    const [active, setActive] = useState(0);
    const [target, setTarget] = useState('deadline');
    const [deadlineDate, setDeadlineDate] = useState<Date | null>(null);
    const [donationType, setDonationType] = useState('any');
    const [minimumCheck, setMinimumCheck] = useState(false);
    const [categories, setCategories] = useState<[]>([]);
    const [isLoading,setIsloading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});


      interface Promoter {
        first_name: string;
        last_name: string;
        email: string;
        role: string;
      }
      

    const [values, setValues] = useState({
        title: '',
        country: selectedCountry ? selectedCountry : '',
        currency: selectedCurrency? selectedCurrency : '',
        category: selectedCategory ? selectedCategory : '',
        compaign_type:target,
        city:'',
        end_date:deadlineDate,
        target:'',
        donation_type:donationType,
        video:'',
        first_name:'',
        last_name:'',
        email:'',
        role:'',
    });




    const [promoters, setPromoters] = useState<Promoter[]>([]);
    const [promoterDetails, setPromoterDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: ''
  });

  const handleAddPromoter = (e: React.ChangeEvent<HTMLInputElement> ) => {
    const { name, value } = e.target;
    setPromoterDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleAddPromoterClick = () => {
    if (
      promoterDetails.first_name &&
      promoterDetails.last_name &&
      promoterDetails.email &&
      promoterDetails.role
    ) {
      setPromoters((prevPromoters) => [...prevPromoters, promoterDetails]);
      setPromoterDetails({
        first_name: '',
        last_name: '',
        email: '',
        role: ''
      });
    } else {
      // Handle case where some fields might be empty
      alert('All fields must be filled');
    }
  };

  const [paymentDetails, setPaymentDetails] = useState<any>({
    mpesaNumber: '',
    paypalEmail: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    branch: '',
    country: '',
  });

  const handlePaymentMethodClick = (method: string) => {
    setSelectedPaymentMethod(method);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPaymentDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

    // Generalized handleChange function
    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | number, name?: string) => {
        if (typeof e === 'number') {
            // Handling the NumberInput change
            setValues((prevValues) => ({
                ...prevValues,
                [name!]: e
            }));
    
            // Clear the error for the NumberInput field if there is one
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name!]: ''
            }));
        } else {
            // Handling the TextInput change
            const { name, value } = e.target;
            setValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
    
            // Clear the error for the TextInput field if there is one
            if (value !== '') {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: ''
                }));
            }
        }
    };

 
  const handleCategoryChange = (selectedCategory:string) => {
    setSelectedCategory(selectedCategory);
  
    if (selectedCategory !== '') {
        
      setErrors((prevErrors) => ({
        ...prevErrors,
        ['category']:''
      
      }));
    }
  };
  
  const handleCountryChange = (selectedCountry:string) => {
    setSelectedCountry(selectedCountry);
  
    if (selectedCountry !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ['country']:''
      
      }));
    }
  };

  const handleCurrencyChange = (selectedCurrency:string) => {
    setSelectedCurrency(selectedCurrency);
  
    if (selectedCurrency !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
       ['currency']:''
      }));
    }
}

const handleAttachFile =(file:File)=>{
  setSelectedFile(file)
  setErrors((prevErrors) => ({
    ...prevErrors,
   ['image']:''
  }));

}



    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({types: ['heading', 'paragraph']}),
        ],
        content: '',
    });

    const validateEditorContent = (editor: any): boolean => {
      // Ensure editor is defined and get the text length
      const isEmpty = editor?.getText().length <= 1;
      return !isEmpty;
    };

        

      const validateStep = () => {
        const stepErrors: { [key: string]: string } = {};
        if (active === 0) {
          if (!values.title) stepErrors.title = 'Title is required';
          if (!values.target) stepErrors.target = 'Target amount must be greater than zero';
          if (!selectedCategory) stepErrors.category = 'Campaign category is required';
          if (!selectedCountry) stepErrors.country = 'Country is required';
          if (!values.city) stepErrors.city = 'Enter your city or nearest town';
          if (!selectedCurrency) stepErrors.currency = 'Currency is required';



        } else if (active === 1) {
            if (!validateEditorContent(editor)) stepErrors.description = 'Description is required';
            if(selectedFile===null) stepErrors.image = 'Attach campaign photo';

          if (selectedPaymentMethod === 'mpesa' && !paymentDetails.mpesaNumber) stepErrors.mpesaNumber = 'M-PESA number is required';
          if (selectedPaymentMethod === 'paypal' && !paymentDetails.paypalEmail) stepErrors.paypalEmail = 'PayPal email is required';
          if (selectedPaymentMethod === 'bank' && (!paymentDetails.bankDetails?.bankName || !paymentDetails.bankDetails?.accountName || !paymentDetails.bankDetails?.accountNumber || !paymentDetails.bankDetails?.branch || !paymentDetails.bankDetails?.country)) {
            stepErrors.bankDetails = 'All bank details are required';
          }
        }
        setErrors(stepErrors);

        console.log('validation errors',errors.length)
        return Object.keys(errors).length === 0;
      };
    
      

      const nextStep = () => {
        setActive((current: number) => {
          // Perform validation
          if (validateStep()) {
            // If valid, move to the next step
            return current < 4 ? current + 1 : current;
          } else {
            // If invalid, remain on the current step
            return current;
          }
        });
      };
    const prevStep = () => setActive((current: number) => (current > 0 ? current - 1 : current));

  

    const titleProps: TitleProps = {
        size: 24,
        mb: "md"
    }

    const subTitleProps: TitleProps = {
        size: 18,
        mb: "sm"
    }

    const paperProps: PaperProps = {
        p: "md",
        withBorder: false,
        shadow: 'sm',
        mb: "md",
        sx: {backgroundColor: theme.white}
    }

  const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const user =  JSON.parse(localStorage.getItem('user') || '{}');
        if(error){
            setIsSubmitting(false)
        }

        const userData = {
            title:values.title,
            country: selectedCountry,
            currency:selectedCurrency,
            category:selectedCategory,
            compaign_type:values.compaign_type,
            city:values.city,
            end_date:values.end_date,
            target:values.target,
            donation_type:values.donation_type,
            video:values.video,
            user_id:user.id,           
            role:values.role,
            description:editor?.getText(),
            promoters:promoters,
            paymentDetails:paymentDetails,
            preferredPaymentMethod:selectedPaymentMethod
          };

            // Create a FormData object to include the file
    const formData = new FormData();
    formData.append('data', JSON.stringify(userData));

    if (selectedFile) {
        formData.append('image', selectedFile);
    }


      
        const res = await makeMultipartFormPostRequest('campaign',formData);
      
        if (isErrorObject(res)) {
          setIsloading(false);
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
          setIsSubmitting(false)
        } else {
          setIsSuccess(true);
          setActive(5);
          navigate(`/campaigns/${res.data}`)

        }
      };

    


    const getCampaignCategory = async () => {
        setIsloading(true);
        const res = await makeGetRequest('campaign-categories');
      
        if (isErrorObject(res)) {
          setIsloading(false);
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
        } else {
          const results = res.data;
          setCategories(results);
          setIsloading(false);
          setError(null);
        }
      };

    useEffect(()=>{
        getCampaignCategory();
    },[])

    return (
        <>
            <Helmet>
                <title>Create campaign</title>
            </Helmet>
            <Box>
                <Container my={36}>
                    <Title mb="xl" align="center">Create your campaign</Title>
                    {isLoading && <Loader/>}
                    {error && <p className="error">{error}</p>}
                    {isSuccess && <Navigate to="/"/>}
                    <Stepper active={active} onStepClick={setActive} breakpoint="sm">
                        <Stepper.Step
                            label="Get started"
                            description="Set essential fundraiser details such as fundraiser title, target and currency"
                        >
                            <Title {...titleProps}>Campaign information</Title>
                            <Paper {...paperProps}>
                                <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                    <TextInput label="Title" name="title" value={values.title} onChange={handleChange}/>
                                    {errors.title && <p className="error">{errors.title}</p>}

                                   <CategorySelect 
                                    data={categories && categories} 
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    />
                                 
                                    {errors.category && <p className="error">{errors.category}</p>}
                                </SimpleGrid>
                            </Paper>
                            <Paper {...paperProps}>
                                <Title {...subTitleProps}>Campaign location</Title>
                                <Text size="sm" mb="sm">Please select the country that we&apos;ll be sending funds to
                                    (typically where you&apos;re resident). This helps match you to the correct payment
                                    processors.</Text>
                                <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                    <CountrySelect
                                     value={selectedCountry} 
                                     onChange={handleCountryChange} 
                                    />
                                     {errors.country && <p className="error">{errors.country}</p>}
                                    <TextInput label="City" placeholder="city" name="city" value={values.city} onChange={handleChange}/>
                                    {errors.city && <p className="error">{errors.city}</p>}
                                </SimpleGrid>
                            </Paper>
                            <Paper {...paperProps}>
                                <Stack spacing="sm">
                                    <Title {...subTitleProps}>Donation information</Title>
                                    <CurrencySelect 
                                    value={selectedCurrency} 
                                    onChange={handleCurrencyChange} 
                                  />
                                   {errors.currency && <p className="error">{errors.currency}</p>}
                                    <Radio.Group
                                        label="What kind of fundraiser would you like to create?"
                                        value={target}
                                        onChange={setTarget}
                                    >
                                        <Group mt="xs">
                                            <Radio value="deadline" label="Fundraiser with a specific end date?"/>
                                            <Radio value="no-deadline" label="Ongoing (no deadline) fundraiser?"/>
                                        </Group>
                                    </Radio.Group>
                                    <Paper {...paperProps}>
                                        {target === 'deadline' ?
                                            <Stack spacing="xs">
                                                <Text size="sm">Fundraiser with a specific end date?</Text>
                                                <Text size="sm">This creates urgency and should always be used when
                                                    money is needed before a certain time.</Text>
                                                <DateInput
                                                    value={deadlineDate}
                                                    onChange={setDeadlineDate}
                                                    label="Deadline"
                                                    placeholder="Date input"
                                                    icon={<IconCalendar size={18}/>}
                                                />
                                                <NumberInput
                                                    label="Target amount"
                                                    icon={<IconCurrencyDollar size={18}/>}
                                                    name="target"
                                                    onChange={(value) => handleChange(value || 0, 'target')}

                                                    />
                                                     {errors.target && <p className="error">{errors.target}</p>}
                                               {/* <Checkbox
                                                    label="Allow your fundraiser to be funded over the needed amount?"/>*/}
                                            </Stack> :
                                            <Stack spacing="xs">
                                                <Text size="sm">Ongoing (no deadline) fundraiser?</Text>
                                                <Text size="sm">This should be used if you are collecting money on a
                                                    regular
                                                    basis.</Text>
                                                <Checkbox
                                                    checked={minimumCheck}
                                                    onChange={(event) => setMinimumCheck(event.currentTarget.checked)}
                                                    label="Select this if you would like to set a specific a minimum financial target"/>
                                                {minimumCheck &&
                                                    <NumberInput
                                                        label="Target amount"
                                                        icon={<IconCurrencyDollar size={18}/>}
                                                    />}
                                            </Stack>}
                                    </Paper>
                                </Stack>
                            </Paper>
                            <Paper {...paperProps}>
                                <Title {...subTitleProps}>Donation type</Title>
                                <SegmentedControl
                                    size="md"
                                    value={donationType}
                                    onChange={setDonationType}
                                    data={[
                                        {label: 'Any Amount (popular option)', value: 'any'},
                                        {label: 'Minimum', value: 'minimum'},
                                        {label: 'Fixed', value: 'fixed'},
                                    ]}
                                    mb="sm"
                                />
                                {donationType === 'minimum' &&
                                    <NumberInput label="Minimum amount(s)"/> 
                                   
                                    }
                                    {
                                      donationType==='fixed' &&  <NumberInput label="Fixed amount(s)"/>
                                    }

{
                                      donationType==='any' &&  <NumberInput label="Any amount(s)"/>
                                    }

                               {/* <Checkbox
                                    label="Would you like your fundraising page shown in more than one language?"
                                    mt="sm"
                                />*/}
                            </Paper>
                           {/* <Paper {...paperProps}>
                                <Stack spacing="sm">
                                    <Title {...subTitleProps}>Fund & Registration details</Title>
                                    <Text size="sm">*Name of the person receiving funds. For organizations, the legal
                                        representative
                                        name (this can be amended later).</Text>
                                    <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                        <TextInput label="First name"/>
                                        <TextInput label="Last name"/>
                                    </SimpleGrid>
                                    <FileDropzone
                                        label="Upload your profile picture"
                                        description="This picture will be shown next to your name"
                                    />
                                    <Checkbox label={
                                        <>
                                            I agree to the FundReach{' '}
                                            <Anchor href="#" target="_blank">
                                                terms and conditions & privacy policy
                                            </Anchor>
                                        </>
                                    }/>
                                </Stack>
                            </Paper>*/}

                        </Stepper.Step>
                        <Stepper.Step
                            label="Campaign story"
                            description="Tell your story! Add your description, images, videos and more">
                            <Title {...titleProps}>
                                Your campaign story
                            </Title>
                            <Paper {...paperProps}>
                                <Stack spacing="sm">
                                    <Text size="sm">Explain why you&apos;re raising money, what the funds will be used
                                        for, and
                                        how much you value the support</Text>
                                    <RichTextEditor editor={editor}  
                                    
                                    >
                                        <RichTextEditor.Toolbar sticky stickyOffset={60}>
                                            <RichTextEditor.ControlsGroup>
                                                <RichTextEditor.Bold/>
                                                <RichTextEditor.Italic/>
                                                <RichTextEditor.Underline/>
                                                <RichTextEditor.Strikethrough/>
                                                <RichTextEditor.ClearFormatting/>
                                                <RichTextEditor.Highlight/>
                                                <RichTextEditor.Code/>
                                            </RichTextEditor.ControlsGroup>

                                            <RichTextEditor.ControlsGroup>
                                                <RichTextEditor.H1/>
                                                <RichTextEditor.H2/>
                                                <RichTextEditor.H3/>
                                                <RichTextEditor.H4/>
                                            </RichTextEditor.ControlsGroup>

                                            <RichTextEditor.ControlsGroup>
                                                <RichTextEditor.Blockquote/>
                                                <RichTextEditor.Hr/>
                                                <RichTextEditor.BulletList/>
                                                <RichTextEditor.OrderedList/>
                                                <RichTextEditor.Subscript/>
                                                <RichTextEditor.Superscript/>
                                            </RichTextEditor.ControlsGroup>

                                            <RichTextEditor.ControlsGroup>
                                                <RichTextEditor.Link/>
                                                <RichTextEditor.Unlink/>
                                            </RichTextEditor.ControlsGroup>

                                            <RichTextEditor.ControlsGroup>
                                                <RichTextEditor.AlignLeft/>
                                                <RichTextEditor.AlignCenter/>
                                                <RichTextEditor.AlignJustify/>
                                                <RichTextEditor.AlignRight/>
                                            </RichTextEditor.ControlsGroup>
                                        </RichTextEditor.Toolbar>

                                        <RichTextEditor.Content/>
                                    </RichTextEditor>

                                    {errors.description && <p className="error">{errors.description}</p>}
                                    <FileDropzone
                                        label="Upload campaign photo (No more than 5MB)"
                                        description="This is the picture to appear in campaign listing and on donation page."
                                        onDrop={(acceptedFiles:any) => {
                                            console.log('Accepted zero ', acceptedFiles[0])



                                            return handleAttachFile(acceptedFiles[0])
                                        }

                                        }                                  

                                        />
                                        {errors.image && <p className="error">{errors.image}</p>}


                                    <TextInput
                                        label="Video URL(optional)"
                                        description="The inclusion of a personalized video can help your fundraiser raise more money. We support links from YouTube and Vimeo. Simply copy paste your video link into the field below."
                                        icon={<IconLink size={18}
                                       

                                        />
                                        
                                    }
                                    name="video"
                                    value={values.video}
                                    onChange={handleChange}
                                    />
                                </Stack>
                            </Paper>
                        </Stepper.Step>
                        <Stepper.Step
                            label="Final details"
                            description="Add team members, customize visibility, and more"
                        >
                            <Title {...titleProps}>Final details</Title>
                            <Paper {...paperProps}>
                                <Stack spacing="sm">
                                    <Title {...subTitleProps}>Manage Team</Title>
                                    <Text size="sm">If there&apos;s more than one person that's responsible for this
                                        fundraiser and you'd like them to get public credit and help manage this page,
                                        invite them via email.</Text>
                                    <Text size="sm">Team members will be shown on your page along with their role.
                                       </Text>
                                   {
                                    !promoters.length &&  <Alert color="orange" variant="light" icon={<IconInfoCircleFilled size={18}/>}>You
                                        haven't invited anyone to help promote this fundraiser yet.</Alert>
                                    
                                    }
                                   
                                    <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                        <TextInput 
                                        label="First name"
                                         name="first_name" 
                                         value={promoterDetails.first_name}
                                         onChange={handleAddPromoter}
                                         />
                                        <TextInput 
                                        label="Last name"
                                         name="last_name" 
                                         value={promoterDetails.last_name}
                                         onChange={handleAddPromoter}
                                         />
                                        <TextInput
                                         label="Email" 
                                         mb="xs" name="email"
                                         value={promoterDetails.email}
                                         onChange={handleAddPromoter}
                                          />
                                        <TextInput
                                            label="Role"
                                            placeholder="e.g. Social media manager, funds manager"
                                            mb="xs"
                                            name="role"
                                          
                                            value={promoterDetails.role}
                                             onChange={handleAddPromoter}
                                        />
                                    </SimpleGrid>
                                    <Button
                                        leftIcon={<IconMail size={18}/>}
                                        mx="auto"
                                        variant="light"
                                        onClick={handleAddPromoterClick}

                                    >
                                       Add as Promoter
                                    </Button>

                                    {
                            promoters.length > 0 && (
                                <ul>
                                {promoters.map((promoter, index) => (
                                    <li key={index}>
                                    {promoter.first_name} {promoter.last_name} - {promoter.email} ({promoter.role})
                                    </li>
                                ))}
                                </ul>
                            )
                            }
                                </Stack>
                             
                            </Paper>
                         
                           {/* <Paper {...paperProps}>
                                <Select
                                    label="How did you hear about us?"
                                    data={['Search engine', 'Friends & family', 'Social media', 'Other']}
                                    name="how_you_heard_about_us"
                                />
                            </Paper>*/}
                        </Stepper.Step>
                        <Stepper.Step label="Payment methods" description="Get full access">
                            <Title {...titleProps}>Fundraiser Payment Methods</Title>
                            <Paper {...paperProps}>
                                <Stack spacing="sm">
                                    <Title {...subTitleProps}>How to you want to collect your donations?
                                        </Title>
                                   {/* <Alert icon={<IconCurrency size={18}/>} color="blue">You can enable GGF Card
                                        Payments (powered by MangoPay) if you switch your currency from GBP to
                                        USD </Alert>*/}
                                    <Text size="sm">Available payment methods</Text>
                                    <div>
      <Group>
        <Button
          variant="light"
          leftIcon={<IconCurrency size={18} />}
          onClick={() => handlePaymentMethodClick('mpesa')}
        >
          Via M-PESA
        </Button>
        <Button
          variant="light"
          leftIcon={<IconBrandAlipay size={18} />}
          onClick={() => handlePaymentMethodClick('bank')}
        >
          Bank
        </Button>
        <Button
          variant="light"
          leftIcon={<IconBrandPaypal size={18} />}
          onClick={() => handlePaymentMethodClick('paypal')}
        >
          PayPal
        </Button>
      </Group>

      {selectedPaymentMethod === 'mpesa' && (
        <TextInput
          label="M-PESA Number"
          name="mpesaNumber"
          value={paymentDetails.mpesaNumber}
          onChange={handleInputChange}
        />
      )}

      {selectedPaymentMethod === 'paypal' && (
        <TextInput
          label="PayPal Email"
          type="email"
          name="paypalEmail"
          value={paymentDetails.paypalEmail}
          onChange={handleInputChange}
        />
      )}

      {selectedPaymentMethod === 'bank' && (
        <>
          <TextInput
            label="Bank Name"
            name="bankName"
            value={paymentDetails.bankName}
            onChange={handleInputChange}
          />
          <TextInput
            label="Account Name"
            name="accountName"
            value={paymentDetails.accountName}
            onChange={handleInputChange}
          />
          <TextInput
            label="Account Number"
            name="accountNumber"
            value={paymentDetails.accountNumber}
            onChange={handleInputChange}
          />
          <TextInput
            label="Branch"
            name="branch"
            value={paymentDetails.branch}
            onChange={handleInputChange}
          />
          <TextInput
            label="Country"
            name="country"
            value={paymentDetails.country}
            onChange={handleInputChange}
          />
        </>
      )}
    </div>
                                </Stack>
                            </Paper>
                        </Stepper.Step>
                        <Stepper.Completed>
                            <Title {...titleProps} align="center" my="xl">Preview Your Campaign</Title>
                                <h3>Campaign detail</h3>
                                <p><strong>Title:</strong> {values.title}</p>
            <p><strong>Description:</strong> {editor?.getText()}</p>
            <p><strong>Country:</strong> {selectedCountry}</p>
            <p><strong>City:</strong> {values.city}</p>
            <p><strong>Campaign Type:</strong> {values.compaign_type}</p>
            <p><strong>Target Amount:</strong> {selectedCurrency}{formatCurrency(parseFloat(values.target))}</p>
            <p><strong>Preferred Payment Method:</strong> {selectedPaymentMethod}</p>
            {selectedPaymentMethod=== 'mpesa' && <p><strong>M-PESA Number:</strong> {paymentDetails.mpesaNumber}</p>}
            {selectedPaymentMethod=== 'paypal' && <p><strong>PayPal Email:</strong> {paymentDetails.paypalEmail}</p>}
            {selectedPaymentMethod=== 'bank' && (
              <>
                <p><strong>Bank Name:</strong> {paymentDetails.bankDetails?.bankName}</p>
                <p><strong>Account Name:</strong> {paymentDetails.bankDetails?.accountName}</p>
                <p><strong>Account Number:</strong> {paymentDetails.bankDetails?.accountNumber}</p>
                <p><strong>Branch:</strong> {paymentDetails.bankDetails?.branch}</p>
                <p><strong>Country:</strong> {paymentDetails.bankDetails?.country}</p>
              </>
            )}

<h3>Promoters</h3>
{
                            promoters.length > 0 && (
                                <ul>
                                {promoters.map((promoter, index) => (
                                    <li key={index}>
                                    {promoter.first_name} {promoter.last_name} - {promoter.email} ({promoter.role})
                                    </li>
                                ))}
                                </ul>
                            )
                            }

        <CampaignPreview
          values={values}
          selectedCategory={selectedCategory}
          selectedCountry={selectedCountry}
          selectedCurrency={selectedCurrency}
          editor={editor}
          selectedFile={selectedFile}
        />

                        </Stepper.Completed>
                    </Stepper>

                    <Group position="center" mt="xl">
                        <Button
                            variant="default"
                            onClick={prevStep}
                            leftIcon={<IconChevronLeft size={18}/>}
                           disabled={active===0}
                        >
                            Back
                        </Button>
                        {active < 4 ?
                            <Button onClick={nextStep} leftIcon={<IconChevronRight size={18}/>}>Next step</Button> :
                            <Button
                                onClick={handleSubmit}
                                loading={isSubmitting}
                            >
                                Submit
                            </Button>
                        }
                    </Group>
                  
                </Container>
            </Box>
        </>
    );
};

export default CreateCampaignPage;
