import {Button, Center, Drawer, DrawerProps, SimpleGrid, Stack, TextInput, Title} from "@mantine/core";
import {IconSearch} from "@tabler/icons-react";
import {CategorySelect, CountrySelect} from "./index";
import {useMediaQuery} from "@mantine/hooks";
import { useEffect, useState } from "react";
import { makeGetRequest } from "../services/httpHelper";
import { isErrorObject } from "../utils/utils";
import ErrorHandler from "../services/ErrorHandler";

type IProps = Pick<DrawerProps, 'opened' | 'onClose' | 'size'>

const SearchDrawer = ({...others}: IProps) => {
    const matchesMobile = useMediaQuery('(max-width: 768px)');
    const [categories, setCategories] = useState<[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [error, setError] = useState<string | null>(null);


    const handleCategoryChange = (selectedCategory:string) => {
        setSelectedCategory(selectedCategory);
      
       
      };
      
      const handleCountryChange = (selectedCountry:string) => {
        setSelectedCountry(selectedCountry);
      
       
      };


    const getCampaignCategory = async () => {
        const res = await makeGetRequest('campaign-categories');
      
        if (isErrorObject(res)) {
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
        } else {
          const results = res.data;
          setCategories(results);
          setError(null);
        }
      };

      useEffect(() => {
      ;
        getCampaignCategory();
    }, []);

    if(error){
        return <p>{error}</p>
    }



    return (
        <Drawer
            position="bottom"
            title=""
            size="100%"
            {...others}
        >
            <Center sx={{minHeight: '80vh'}}>
                <Stack spacing="lg" sx={{width: matchesMobile ? '75%' : '60%'}}>
                    <Title>Search</Title>
                    <TextInput
                        size={matchesMobile ? "sm" : "lg"}
                        icon={<IconSearch size={24}/>}
                        placeholder="Try searching people, titles and hashtags"
                    />
                    <SimpleGrid cols={2} spacing="lg" breakpoints={[{maxWidth: 'md', cols: 1, spacing: 'md'}]}>
                                     <CategorySelect 
                                    data={categories && categories} 
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    />
                                  <CountrySelect
                                     value={selectedCountry} 
                                     onChange={handleCountryChange} 
                                    />
                    </SimpleGrid>
                    <Button size="md" mt="lg">Search</Button>
                </Stack>
            </Center>
        </Drawer>
    );
};

export default SearchDrawer;
