import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Grid,
 // Group,
  Loader,
  Notification,
  Progress,
  Stack,
  Tabs,
  Text,
  Title,
  UnstyledButton,
  Textarea,
  Group,
} from "@mantine/core";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { /*useDisclosure,*/ useMediaQuery } from "@mantine/hooks";
import { BackButton, NotFound } from "../components";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { notifications } from "@mantine/notifications";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { makeGetRequest, makePutRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { formatCurrency } from "../utils/FormValidator";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../services/paths";
import ErrorBoundary from "../utils/ErrorBoundary";
import { ICampaign, IDonation } from "../types";
import CampaignAdmins from "../components/CampaignAdmins";

const ApprovePendingCampaigns = (): JSX.Element => {
  dayjs.extend(LocalizedFormat);
  const { id } = useParams();

  const [campaign, setCampaign] = useState<ICampaign>();
  //const [opened, { open, close }] = useDisclosure(false);
  const matchesMobile = useMediaQuery("(max-width: 768px)");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitting, setIsSubmitting] = useState(false);
  const [donations, setDonations] = useState<IDonation[]>([]);
  const [reason, setReason] = useState("");
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const campaignImage = IMAGE_BASE_URL + campaign?.image;
  const navigate = useNavigate();

  const getProgress = (raised: number, target: number): number => {
    return (raised / target) * 100;
  };

  const totalRaised = (): number => {
    let total = 0;
    const campaignDonations = donations && donations.filter((donation) => donation.campaign_id === id);
    campaignDonations?.forEach((d) => {
      total += parseFloat(d.donation_amount);
    });
    return total;
  };

  const fetchCampaignData = async () => {
    setIsLoading(true);
    try {
      const campaignRes = await makeGetRequest(`campaign/${id}`);
      if (isErrorObject(campaignRes)) {
        throw new Error(ErrorHandler(campaignRes));
      }
      setCampaign(campaignRes.data);

      const donationsRes = await makeGetRequest(`campaign-donors/${id}`);
      if (isErrorObject(donationsRes)) {
        throw new Error(ErrorHandler(donationsRes));
      }
      setDonations(donationsRes.data);
    } catch (err) {
      console.log(err);
      setError("something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async () => {
    try {
        setIsSuccess(false)
        setError(null)
    const confirm = window.confirm('Are sure you want to approve and publish this campaign');
    if(confirm){
        setIsSubmitting(true)

        const res = await makePutRequest(`campaign/${id}/approve`, {
            userId:campaign?.user_id
        });
        if (isErrorObject(res)) {
            setIsSubmitting(false)

          setError(ErrorHandler(res));
        } else {
          setIsSuccess(true);
          setIsSubmitting(false)

          notifications.show({
            title: "Campaign Approved",
            message: "The campaign has been approved successfully.",
            color: "green",
          });
          setTimeout(()=>{
            navigate('/pending-campaigns')

          },3000)
        }
    }
    } catch (err) {
      setError("Error approving campaign");
    }
  };

  const handleReject = () => {
    setShowReasonInput(true);
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
    setIsSubmitDisabled(e.target.value.trim().length === 0);
  };

  const handleRejectSubmit = async () => {
    try {
        if(!reason){
            setError('Enter reason for rejecting the campaign');
            return;
        }
        const confirm = window.confirm('Are sure about this?');
        if(confirm){
            setIsSubmitting(true)
            const res = await makePutRequest(`campaign/${id}/reject`, { reason:reason,userId:campaign?.user_id });
            if (isErrorObject(res)) {
                setIsSubmitting(false)

              setError(ErrorHandler(res));
            } else {
              setIsSuccess(true);
              setIsSubmitting(false)

              notifications.show({
                title: "Campaign Rejected",
                message: "The campaign has been rejected.",
                color: "red",
              });

              setTimeout(()=>{
                navigate('/pending-campaigns')
    
              },3000)   

            }
        }
     
    } catch (err) {
      setError("Error rejecting campaign");
    }
  };

  useEffect(() => {
    fetchCampaignData();
  }, [id]);

  return (
    <>
      <ErrorBoundary>
        <Helmet>
          <title>{campaign?.title}</title>
        </Helmet>
        <Box>
          <Container>
            {isLoading && <Loader />}
            {isSuccess && (
              <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                Authorisation Applied
              </Notification>
            )}
            {error && (
              <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                {error}
              </Notification>
            )}
          </Container>
          {campaign ? (
            <Container size="lg">
              <BackButton mb="md" />
              <Grid>
                <Grid.Col lg={12}>
                  <Stack>
                    <Tabs defaultValue="campaign">
                      <Tabs.List>
                        <Tabs.Tab value="campaign">Campaign</Tabs.Tab>
                        <Tabs.Tab value="donations" disabled>
                          Donations
                        </Tabs.Tab>
                        <Tabs.Tab value="promoters" disabled>
                          Promoters
                        </Tabs.Tab>
                        <Tabs.Tab value="followers" disabled>
                          Followers
                        </Tabs.Tab>
                        <Tabs.Tab value="admins" disabled>
                          Admins
                        </Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="campaign">
                        <Card padding="md" shadow="sm">
                          <Card.Section>
                            <img crossOrigin="anonymous" src={`${campaignImage}`} height={480} />
                          </Card.Section>
                          <Stack mt="md">
                            {!matchesMobile ? (
                              <Flex gap="xs" align="center">
                                <Text size="sm">Campaign created by</Text>
                                <UnstyledButton component={Link} to={`/user/${campaign.fundRaiser.id}`}>
                                  <Flex gap="xs" align="center">
                                    <Avatar src={campaign?.fundRaiser.avatar} radius="xl" size="sm" />
                                    <Text size="sm">
                                      {campaign?.fundRaiser.first_name} {campaign?.fundRaiser.last_name}
                                    </Text>
                                  </Flex>
                                </UnstyledButton>
                                <Text component={Link} to={`/country/${campaign?.country}`} size="sm">
                                  {campaign?.country}
                                </Text>
                                <Text component={Link} to={`/category/${campaign.category.id}`} size="sm">
                                  {toTitleCase(campaign.category.category)}
                                </Text>
                              </Flex>
                            ) : (
                              <Stack>
                                <Flex gap="md">
                                  <Text size="sm">Fundraise campaign created by</Text>
                                  <UnstyledButton component={Link} to={`/user/${campaign.fundRaiser.id}`}>
                                    <Flex gap="xs" align="center">
                                      <Avatar src={campaign.fundRaiser?.avatar} radius="xl" size="sm" />
                                      <Text size="sm">
                                        {campaign?.fundRaiser.first_name} {campaign?.fundRaiser.last_name}
                                      </Text>
                                    </Flex>
                                  </UnstyledButton>
                                </Flex>
                              </Stack>
                            )}
                            <Text size="lg" weight={600}>
                              Our story
                            </Text>
                            <Text size="sm">{campaign?.description}</Text>
                            <Divider />
                            <Flex justify="space-between">
                              <Title size={32}>{totalRaised()}</Title>
                              <Text fw={500} align="center" color="dimmed">
                                raised of {formatCurrency(parseFloat(campaign?.target.toString()))}
                              </Text>
                            </Flex>
                            <Progress value={getProgress(parseFloat(totalRaised().toString()), parseFloat(campaign.target.toString()))} size="md" />

                            <Group>
                                <CampaignAdmins campaignId={campaign.id}/>
                             
                            </Group>

                            <Flex justify="space-between">
                              <Button color="green" onClick={handleApprove}>
                               {submitting ? <Loader/> :" Approve"}
                              </Button>
                              <Button color="red" onClick={handleReject}>
                                Reject
                              </Button>
                            </Flex>

                            {showReasonInput && (
                              <Stack mt="md">
                                <Textarea
                                  label="Reason for rejection"
                                  placeholder="Enter the reason..."
                                  value={reason}
                                  onChange={handleReasonChange}
                                />
                                <Button color="red" disabled={isSubmitDisabled} onClick={handleRejectSubmit}>
                                  Submit Rejection
                                </Button>
                              </Stack>
                            )}
                          </Stack>
                        </Card>
                      </Tabs.Panel>
                    </Tabs>
                  </Stack>
                </Grid.Col>
              </Grid>
            </Container>
          ) : (
            <NotFound />
          )}
        </Box>
      </ErrorBoundary>
    </>
  );
};

export default ApprovePendingCampaigns;
