import React from 'react';
import { Container, Grid, Col, Image } from '@mantine/core';
//import './AboutUs.css';
import Web7 from '../assets/img/lotus-flower.png';
import { Helmet } from "react-helmet";

const AboutUs: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="FundReach is a pioneering crowdfunding platform that empowers individuals and organizations to raise funds for causes that matter most to them. With a local-first approach, FundReach provides tailored solutions to meet the unique fundraising needs of different countries." />
      </Helmet>
      <div className="about-us">
       

        <div className="our-story-section text-center">
          <Container>

          <Grid>
              <Col span={4}>
               
              </Col>
              <Col span={8}>
              <h2>Who We Are</h2>
                <p>
                FundReach is a pioneering crowdfunding platform that empowers individuals and organizations to raise funds for causes that matter most to them. With a local-first approach, FundReach provides tailored solutions to meet the unique fundraising needs of different countries. From supporting funeral arrangements in Kenya via M-PESA to enabling donations through credit cards and PayPal in the US, FundRea
                </p>
              </Col>
            </Grid>
            <Grid>
              <Col span={8}>
                <h2>OUR STORY</h2>
                <p>
                  FundReach was born out of the need to provide a more accessible and efficient way for people to raise funds for important causes. Recognizing the limitations of traditional fundraising methods and the diverse payment preferences in different countries, we set out to create a platform that combines the best of both worlds. Our local-first approach ensures that FundReach is tailored to meet the unique needs of each region, making it easier for fundraisers to connect with donors and achieve their goals. Today, FundReach stands as a testament to the power of community and innovation in making a difference.
                </p>
              </Col>
              <Col span={4}>
                {/* You can add content here */}
              </Col>
            </Grid>

            <Grid>
              <Col span={12}>
                <div className="mission-vision-section">
                  <Grid>
                    <Col span={5}>
                      <div className="mission-section">
                        <div className="mission-content">
                          <h3>Our Mission</h3>
                          <p>To empower individuals and organizations worldwide by providing a versatile and accessible crowdfunding platform that supports diverse fundraising needs.</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={2}>
                      <Image src={Web7} fit="contain" />
                    </Col>
                    <Col span={5}>
                      <div className="vision-section">
                        <div className="vision-content">
                          <h3>Vision</h3>
                          <p>To be the leading global crowdfunding platform known for its local-first approach and innovative solutions that make fundraising easy and effective for everyone.</p>
                          <br/>
                        </div>
                      </div>
                    </Col>
                  </Grid>
                </div>
              </Col>
            </Grid>

            <Grid>
              <Col span={6}>
                <h2>The Need We Are Addressing</h2>
                <p style={{ fontWeight: 'bold', color: '#0e1a57' }}>
                  Many people around the world struggle to raise funds for important causes due to limited access to convenient fundraising tools and payment methods. In countries like Kenya, traditional fundraising methods such as community gatherings and WhatsApp groups are prevalent. FundReach addresses this need by offering a platform that integrates these traditional methods with modern crowdfunding capabilities, making it easier to reach a wider audience and receive donations.
                </p>
              </Col>
            </Grid>
          </Container>
        </div>

      
      </div>
    </>
  );
};

export default AboutUs;
