import { jwtDecode } from "jwt-decode";
// Axios request interceptor
import axios from 'axios';
import * as Sentry from "@sentry/react";


// Type definition for JWT payload
interface JwtPayload {
    exp: number;
}

// TypeScript function to decode JWT
const decodeToken = (token: string): JwtPayload => {
    return (jwtDecode as unknown as (token: string) => JwtPayload)(token);
};

// Rest of your code
const logout = async (): Promise<boolean> => {
    console.log('logging out...');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('roles');
    return true;
};

// TypeScript function to log out user
const logoutUser = async (): Promise<void> => {
    await logout();
};



Sentry.init({
    dsn: "https://6eaad995a77d0054024b3b9f4a97b8b4@o4503947002249216.ingest.us.sentry.io/4507854000881664",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/fundreach\.roshtech.co.ke\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
axios.interceptors.request.use(
    (request) => {
        console.log('Request interceptor', request.url);
        if (request.url && !request.url.includes('login')) {
            const token = sessionStorage.getItem('token');
            if (token) {
                const decodedToken = decodeToken(token);
                if (decodedToken.exp * 1000 < Date.now()) {
                    logoutUser();
                } else {
                    request.headers['Authorization'] = `Bearer ${token}`;
                }
            } else {
                logoutUser();
            }
        }
        return request;
    }
);

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      
            <App />
    
    </React.StrictMode>
);
