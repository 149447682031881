import { useEffect, useState } from "react";
import { DataTable } from "mantine-datatable";
import { ICampaignBal, IDonation } from "../types";
import { Avatar, Group, Text, Paper, Grid, Button, Title, Loader, Notification } from "@mantine/core";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { Link } from "react-router-dom";
import { makeGetRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { IconAlertCircle } from "@tabler/icons-react";

const PAGE_SIZE = 10;

interface DonationsTableProps {
  donations: IDonation[];
  campaignId: string;
  //totalWithdrawals: number;
}

const AdminCampaignDonationTable: React.FC<DonationsTableProps> = ({
  donations,
  campaignId,
  //totalWithdrawals,
}) => {
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(donations.slice(0, PAGE_SIZE));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [donationBal, setDonationBal] = useState<ICampaignBal>();


  //const [totalWithdrawals,setTotlWithdrawals]=useState(0)

  const totalWithdrawals =0;

  // Calculate total donations
  const totalDonations = donations.reduce<number>(
    (acc: number, donation: IDonation) => acc + Number(donation.donation_amount),
    0
  );

  const currentBalance = totalDonations - totalWithdrawals;

  const getDonationBal = async () => {
    setIsLoading(true);
    const res = await makeGetRequest(`campaign-donation-balance/${campaignId}`);

    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
    } else {
        const results = res.data;
        setDonationBal(results);       
        setIsLoading(false);
        setError(null);
    }
};

useEffect(()=>{
  getDonationBal()
},[])

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    donations && setRecords(donations.slice(from, to));
  }, [page, donations]);

  return (
    <>
    {isLoading && <Loader/>}
    {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
      {/* Top section with cards for total donations, total withdrawals, and current balance */}
      <Grid gutter="lg">
        <Grid.Col span={4}>
          <Paper withBorder p="md" radius="md">
            <Title order={4}>Total Donations</Title>
            <Text weight={700} size="xl">
              {donationBal?.totalDonations.toLocaleString(undefined, {
                style: "currency",
                currency: donationBal.currency,
              })}
            </Text>
          </Paper>
        </Grid.Col>

        <Grid.Col span={4}>
          <Paper withBorder p="md" radius="md">
            <Title order={4}>Total Withdrawals</Title>
            <Text weight={700} size="xl" color="red">
              {donationBal?.totalWithdrawals.toLocaleString(undefined, {
                style: "currency",
                currency: donationBal.currency,
              })}
            </Text>
          </Paper>
        </Grid.Col>

        <Grid.Col span={4}>
          <Paper withBorder p="md" radius="md">
            <Group position="apart">
              <div>
                <Title order={4}>Current Balance</Title>
                <Text weight={700} size="xl" color={currentBalance > 0 ? "green" : "gray"}>
                  {donationBal?.availableBalance.toLocaleString(undefined, {
                    style: "currency",
                    currency: donationBal.currency,
                  })}
                </Text>
              </div>
              <Button
                component={Link}
                to={`/initiate-withdrawal/${campaignId}`}
                disabled={currentBalance <= 0}
                variant="outline"
                color="blue"
              >
                Withdraw
              </Button>
            </Group>
          </Paper>
        </Grid.Col>
      </Grid>

      {/* Donors Table */}
      <DataTable
        columns={[
          {
            accessor: "Donor",
            render: ({ donor }: IDonation) => (
              <Group>
                <Avatar
                  src={donor.avatar}
                  alt={`${donor.first_name} profile avatar`}
                  size="sm"
                  radius="xl"
                />
                <Text>
                  {toTitleCase(donor.first_name)} {toTitleCase(donor.last_name)}
                </Text>
              </Group>
            ),
          },
          { accessor: "donation_currency" },
          { accessor: "donation_amount" },
        ]}
        records={records}
        totalRecords={donations && donations.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        highlightOnHover
        verticalSpacing="sm"
        striped
      />
    </>
  );
};

export default AdminCampaignDonationTable;
