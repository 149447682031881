import { useEffect, useState } from 'react';
import { Box, Button, Modal, Notification, Group, Text,  Loader } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { IconCheck, IconAlertCircle, IconPlus, IconEdit, IconTrash } from '@tabler/icons-react';
import { makeGetRequest, makeDeleteRequest } from '../services/httpHelper';
import { IRole } from '../types';
import EditRoleForm from '../components/EditRoleForm';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';

import AddRoleForm from '../components/AddRoleForm';

const PAGE_SIZE = 10;

const ManageRolesPage = () => {
    const [roles, setRoles] = useState<IRole[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<IRole | null>(null);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<IRole[]>([]);


    useEffect(() => {
        const fetchRoles = async () => {
            const res = await makeGetRequest(`roles`);
            setIsLoading(false);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setRoles(res.data);
                setRecords(res.data.slice(0, PAGE_SIZE));
            }
        };

        fetchRoles();
    }, []);

    const handlePageChange = (page: number) => {
        setPage(page);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(roles.slice(from, to));
    };

    const handleEditRole = (role: IRole) => {
        setSelectedRole(role);
       selectedRole && setShowEditModal(true);
    };

    const handleRemoveAdmin = async (roleId: string) => {
        const res = await makeDeleteRequest(`role/${roleId}`);
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setSuccess('Role removed successfully.');
            setRoles(roles.filter(role => role.id !== roleId));
            setRecords(records.filter(role => role.id !== roleId));
        }
    };

  

   

    return (
        <Box mx="auto" p="md">
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    {success}
                </Notification>
            )}

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Group position="apart" mb="md">
                        <Text weight={500} size="lg">
                            Manage Roles
                        </Text>
                        <Button leftIcon={<IconPlus />} onClick={() => setShowAddModal(true)}>
                            Add Role
                        </Button>
                    </Group>

                    <DataTable
                       columns={[
                        {accessor: 'name'},
                        {accessor: 'description'},
                       
                       
                        {
                            accessor: 'actions',
                            render: (role: IRole) => (
                                <Group spacing="sm">
                                    <Button
                                        leftIcon={<IconEdit size={16} />}
                                        size="xs"
                                        onClick={() => handleEditRole(role)}
                                    >
                                        Edit 
                                    </Button>
                                    <Button
                                        leftIcon={<IconTrash size={16} />}
                                        size="xs"
                                        color="red"
                                        onClick={() => handleRemoveAdmin(role.id)}
                                    >
                                        Remove
                                    </Button>
                                 
                                </Group>
                            ),
                        },
                    ]}
                        records={records}
                        totalRecords={roles.length}
                        recordsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={handlePageChange}
                        highlightOnHover
                        verticalSpacing="sm"
                    />

                    {/* Modals for adding and editing admins */}
                    <Modal opened={showAddModal} onClose={() => setShowAddModal(false)} title="Add Role">
                        <AddRoleForm/>
                    </Modal>

                   

                    {selectedRole && (
                        <Modal opened={showEditModal} onClose={() => setShowEditModal(false)} title="Edit Role">
                            <EditRoleForm role={selectedRole} />
                        </Modal>
                    )}
                </>
            )}
        </Box>
    );
};

export default ManageRolesPage;
