//import React from 'react';
import {
    Box,
    Container,
    createStyles,
    Group,
    Paper,
    //rem,
    Text,
    Title,
    TitleProps
} from "@mantine/core";
import { Helmet } from "react-helmet";
import { toTitleCase } from "../utils/utils";

const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));

const UserSettingsPage = () => {
    const { classes } = useStyles();

    const paperProps = {
        p: "md",
        shadow: "sm"
    };

    const subTitleProps: TitleProps = {
        size: 18,
        mb: "sm"
    };

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    return (
        <>
            <Helmet>
                <title>User Settings</title>
            </Helmet>
            <Box>
                <Container fluid my="xl">
                    <Paper {...paperProps}>
                        <Title {...subTitleProps}>User Settings</Title>
                        <Text size="sm">Update your account settings below:</Text>
                        <Group spacing="md" mt="md">
                            <Text className={classes.title}>Name: {toTitleCase(user.first_name)}</Text>
                            <Text className={classes.title}>Email: {user.email}</Text>
                            {/* Add more settings options as needed */}
                        </Group>
                    </Paper>
                </Container>
            </Box>
        </>
    );
};

export default UserSettingsPage;
