import  {  useState } from 'react';
import {
    Box,
    Button,
    TextInput,
    NumberInput,
    Group,
    Title,
    Notification,
    Loader,
    Container,
} from '@mantine/core';
import {  IconAlertCircle } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import {  makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import PaymentMethodSelect from './PaymentMethodSelect';





const WithdrawalForm = () => {
    const [amount, setAmount] = useState<number | undefined>(undefined);
    //const [paymentMethod, setPaymentMethod] = useState<'mpesa' | 'bank' | ''>('');
    const [paymentMethod, setPaymentMethod] = useState('');

    const [mpesaNumber, setMpesaNumber] = useState<string>('');
    const [bankAccountNumber, setBankAccountNumber] = useState<string>('');
    const [bankAccountName, setBankAccountName] = useState<string>('');
    const [bankName, setBankName] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    


    const {id} = useParams();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const navigate = useNavigate();

  


    const handleSubmit = async()=>{
            
       if(!amount){
        setError('Please enter amount');
                return;
       }

       if(!paymentMethod){
        setError('Choose account type');
                return;
       }
        if (paymentMethod === 'mpesa') {
            if (!mpesaNumber) {
                setError('Please enter  M-PESA number.');
                return;
            }
        } else if (paymentMethod === 'bank') {
            if (!bankName) {
                setError('Please select your bank');
                return;
            }
            if (!bankAccountName) {
                setError('Please enter bank account name');
                return;
            }
            if (!bankAccountNumber) {
                setError('Please enter bank account number');
                return;
            }
          
        }
        const res = await makePostRequest(`initiate-withdrawal`,{
            campaignId:id,
            amount:amount,
            paymentMethod:paymentMethod,
            mpesaNumber:mpesaNumber,
            bankName:bankName,
            bankAccountNumber:bankAccountNumber,
            setBankAccountName:bankAccountName,
            userId:user.id
        });

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            navigate(`/verify-withdrawal-initiator/${id}?wi=${res.data}`,{state:{withdrawalId:res.data}})
          
        }
    
   
   

}

    

    const handleWithdrawalAmount = (value: number | any) => {
        const amount = value ?? 0;
        setAmount(amount);
        
    };
    const handlePaymentMethod =(ptmMethod:string)=>{
        setPaymentMethod(ptmMethod)

    }



    return (
        <Box  mx="auto">
            <Container>
            {isLoading && <Loader/>}
            {error && <p className='error'>{error}</p>}
            <Title order={3} align="center" mb="lg">Withdrawal Request</Title>
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
           
            

                      
            <NumberInput
                size='md'
                label="Withdrawal Amount"
                placeholder="Enter amount"
                precision={2}
                value={amount}
                onChange={handleWithdrawalAmount}
                min={0}
                required
            />
          
            <PaymentMethodSelect value={paymentMethod} onChange={handlePaymentMethod}/>
            {paymentMethod === 'M-PESA' && (
                <TextInput
                    label="M-PESA Number"
                    placeholder="Enter M-PESA number"
                    value={mpesaNumber}
                    onChange={(e) => setMpesaNumber(e.currentTarget.value)}
                    required
                />
            )}
            {paymentMethod === 'Bank' && (
             <>
               <TextInput
                    label="Bank Name"
                    placeholder="Enter name of the bank"
                    value={bankName}
                    onChange={(e) => setBankName(e.currentTarget.value)}
                    required
                />
                <TextInput
                    label="Bank Account"
                    placeholder="Enter bank account number"
                    value={bankAccountNumber}
                    onChange={(e) => setBankAccountNumber(e.currentTarget.value)}
                    required
                />

                <TextInput
                    label="Bank Account Name"
                    placeholder="Enter bank account name"
                    value={bankAccountName}
                    onChange={(e) => setBankAccountName(e.currentTarget.value)}
                    required
                />
             </>
            )}
            <Group position="right" mt="lg">
                <Button onClick={handleSubmit}>Submit Withdrawal</Button>
            </Group>

            </Container>
           
        </Box>
    );
};

export default WithdrawalForm;
