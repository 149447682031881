import React, { useState, useEffect } from 'react';
import './Post.css';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { Container, Loader, Notification, Paper, Title } from '@mantine/core';
import { isErrorObject } from '../utils/utils';
import { ICampaign } from '../types';
import PendingCampaignsTable from '../components/PendingCampaignsTable';
import { IconAlertTriangle } from '@tabler/icons-react';




const PendingCampaignsPage: React.FC = () => {
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);



  const fetchCampaigns = async () => {
    setIsLoading(true);
    const endpoint = 'campaigns';
    const res = await makeGetRequest(endpoint);
    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
    } else {
        setCampaigns(res.data);
    }
    setIsLoading(false);
};

  useEffect(() => {
    fetchCampaigns();
  }, []);





  return (
    <>

      <Container className="post-wrapper" size="md">
        <Title>Pending Campaigns</Title>
        <p>Campaigns Pending Approval</p>
        {isLoading && <Loader/>}
        {error && <p className='error'>{error}</p>}
        <Paper>
            {
                campaigns.length ?
                <PendingCampaignsTable campaigns={campaigns}/>
                :
                <Notification icon={<IconAlertTriangle size={18} />} color="yellow" mb="lg">
                No Pending Campaigns
            </Notification>

            }
        </Paper>
        
      </Container>
   
    </>
  );
};

export default PendingCampaignsPage;
