import React from 'react';
import DOMPurify from 'dompurify';

interface SanitizeHtmlComponentProps {
  htmlContent: string;
  maxContentLength?: number;
}

const SanitizeHtmlComponent: React.FC<SanitizeHtmlComponentProps> = ({ htmlContent, maxContentLength = 0 }) => {
  // Sanitize and render the HTML content
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  

  if (maxContentLength === 0) {
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
  } else {
    // Find the index of the first opening <p> tag
    const firstPTagIndex = sanitizedHTML.indexOf('<p');
    const truncatedContent = firstPTagIndex !== -1
      ? sanitizedHTML.substring(firstPTagIndex, firstPTagIndex + maxContentLength) + '...'
      : sanitizedHTML.substring(0, maxContentLength) + '...';

    return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
  }
};

export default SanitizeHtmlComponent;
