import React, { useState } from 'react';
import { makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { Box, Loader, Title,Notification, SimpleGrid, TextInput, Button } from '@mantine/core';
import { IconCheck, IconAlertCircle, IconSelector } from '@tabler/icons-react';




type RoleFormData = {
  name: string;
  description: string;
  
};



const AddRoleForm  = () => {

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

 // const user = JSON.parse(localStorage.getItem('user') || '{}');


  const [formData, setFormData] = useState<RoleFormData>({
    name: '',
    description: '',
  

  });



  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true)

    const res = await makePostRequest(`role`,formData);
    if (isErrorObject(res)) {
      setIsSubmitting(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
      setSuccess(false)
  } else {
          // Resetting the form fields to empty strings
          setFormData({
            name: '',
            description: '',
         
        });    
      setIsSubmitting(false);
      setError(null);
      setSuccess(true)
  
  }


 
  };

  return (
    <Box  mx="auto">
         {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Role Added 
                </Notification>
            )}

<form onSubmit={handleSubmit}>

{isSubmitting && <Loader/>}
            <Title order={3} align="center" mb="lg">Add Role </Title>
            {error && <p className='error'>{error}</p>}
          
            <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                        <TextInput 
                                        label="Name"
                                         name="name" 
                                         value={formData.name}
                                         onChange={handleChange}
                                         />
                                        <TextInput 
                                        label="Description"
                                         name="description" 
                                         value={formData.description}
                                         onChange={handleChange}
                                         />
                                        
                            
                                    </SimpleGrid>
                                    <Button
                                        leftIcon={<IconSelector size={18}/>}
                                        mx="auto"
                                        variant="light"
                                        type='submit'

                                    >
                                       Add  Role
                                    </Button>
         
    
    </form>
    </Box>
    
  );
};

export default AddRoleForm;
