import  {  useState } from 'react';
import {
    Box,

    Title,
    Notification,
    Loader,
    Container,
    SimpleGrid,
    TextInput,
    Button,
} from '@mantine/core';
import { IconCheck, IconAlertCircle, IconSelector } from '@tabler/icons-react';
import {  useNavigate, useParams } from 'react-router-dom';
import { makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';




const AuthWithdrawalInitiator= () => {
    const [otp, setOtp] = useState<string | null>();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


    const{id} = useParams();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const withdrawalId = decodeURIComponent(params.get('wi') as string);



    const verifyOtp = async () => {

        const data ={
            userId:user.id,
            campaignId:id,
            otp:otp,
            withdrawalId:withdrawalId
          
        };

        setIsSubmitting(true);

        const res = await makePostRequest(`verify-withdrawal-initiator-otp`,data);

        if (isErrorObject(res)) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            //save withdrawal id in local storage
            localStorage.setItem('wi',res.data.withdrawalId)
            setIsSubmitting(false);
            setError(null);
            setSuccess(true)
            const approvalStatus = res.data.approvalStatus;
            if(approvalStatus){
                //finalize transaction
                navigate('/process-withdrawal',{state:{campaignId:id,withdrawalId:res.data.withdrawalId}})
            }else{
                //request other admin approval
                navigate(`/request-admin-approval/${id}?wi=${res.data.withdrawalId}`)

            }

        }
    };


   

   

     

    return (
        <Box  mx="auto">
           <Container>
            <Box>
            {isSubmitting && <Loader/>}
            {error && <p className='error'>{error}</p>}
            <Title order={3} align="left" mb="lg">Verify OTP</Title>
            <p>Approval Code has been send to your account mobile phone. Enter it below</p>

            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
               <>
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Request Approved successfully
                </Notification>
           
               </>
             
            )}

<SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>

            <TextInput 
            label="Enter OTP"
            name="otp" 
            value={otp?.toString()}
            onChange={(e) => setOtp(e.target.value)}
                                         />

</SimpleGrid>
<br/>
                    <Button
                    leftIcon={<IconSelector size={18}/>}
                     mx="auto"
                    variant="light"
                    onClick={verifyOtp} disabled={isSubmitting}

                        >
                        {isSubmitting ? <Loader/> : 'Verify OTP'}
                                    </Button>
         
             
           
            </Box>
           </Container>
        </Box>
    );
};

export default AuthWithdrawalInitiator;
