import { useState, useEffect, ReactNode } from "react";
import { Box, Loader } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { LandingFooter, LandingNavbar } from "../components";
import footerLinksData from "../data/Footer.json";
import { makeGetRequest } from "../services/httpHelper";
import { isErrorObject } from "../utils/utils";
import ErrorHandler from "../services/ErrorHandler";

interface IProps {
  children?: ReactNode;
  compressedNav?: boolean;
}

const PublicLayout = ({ compressedNav }: IProps) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [footerData, setFooterData] = useState(footerLinksData.data);

  const getCampaignCategory = async () => {
    setIsloading(true);
    const res = await makeGetRequest("campaign-categories");

    if (isErrorObject(res)) {
      setIsloading(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      const results = await res.data;
     
      setIsloading(false);
      setError(null);

      const updatedFooterData = footerLinksData.data.map((section) => {
        if (section.title === "Fundraisers for") {
          return {
            ...section,
            links: results.map((category: { id: string; category: string }) => ({
              label: category.category,
              link: `category/${category.id}`,
            })),
          };
        }
        return section;
      });

      setFooterData(updatedFooterData);
    }
  };

  useEffect(() => {
    getCampaignCategory();
  }, []);

  return (
    <>
      {error && <p className="error">{error}</p>}
      {isLoading && <Loader />}
      <LandingNavbar compressed={compressedNav} />
      <Box sx={{ marginTop: compressedNav ? 0 : 96 }}>
        <Outlet />
      </Box>
      <LandingFooter data={footerData} />
    </>
  );
};

export default PublicLayout;
