import {Button, Card,   Loader,  Notification,  TextInput} from "@mantine/core";
import {useState} from "react";
import { makePostRequest } from "../services/httpHelper";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { ICampaignTeam } from "../types";

type teamProps = {
    team:ICampaignTeam
  };

const MessagePromoter:React.FC<teamProps> = ({team}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false)




  const handleMessage =(e: any)=>{
    setMessage(e.target.value)
    //e: React.MouseEvent<HTMLButtonElement>

  }


    const handleCantactUs = async (e: React.FormEvent) => {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        setIsSubmitting(true)
        const data = {
            fromName:user.first_name+' '+user.last_name,
            fromEmail:user.email,
            toEmail:team.user.email,
            toName:team.user.first_name,
            message:message,
            user_id:user.id,
            campaignId:team.campaign_id,
            teamId:team.id
        }
        console.log(data)
        const res = await makePostRequest('message-campaign-admin', data);

        if (res && res.errorType) {
            setIsSubmitting(false);
            setError(res.errorMessage)
        } else {
          
            setIsSubmitting(false);
            setIsSuccess(true)
        }
    }

 

    return (
        <Card>
              {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {isSuccess && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Message send
                </Notification>
            )}
             {isSubmitting && <Loader/>}
            <Card.Section>
               
                <form
                    onSubmit={handleCantactUs}
                >
                  
                    <TextInput
                        label="Message"
                        placeholder="message"
                        mt="md"
                        className="caontact-message"
                        onChange={handleMessage}
                        style={{width:"400px",height:"200px"}}
                    />

              
                    <Button type="submit" mt="md"  disabled={!message} onClick={handleCantactUs}>
                        Send Message 
                    </Button>
                </form>
            </Card.Section>
        </Card>
    );
};

export default MessagePromoter;
