import { useState } from "react";
import { Button, TextInput, Textarea,  Select, FileInput,Notification,Loader } from "@mantine/core";
import { DateInput } from '@mantine/dates';
import { ICampaign } from "../types";
import { makeMultipartFormPostRequest } from "../services/httpHelper";
import { isErrorObject } from "../utils/utils";
import ErrorHandler from "../services/ErrorHandler";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import FileDropzone from "./FileDropzone";
import { useNavigate } from "react-router-dom";

type editCampaignProps = {
    campaign: ICampaign;
    onClose: () => void; // onClose is a function that takes no arguments and returns nothing (void)
    onSave: (updatedCampaign: ICampaign) => void; // onSave is a function that takes an updated campaign object and returns nothing (void)
  };


const EditCampaignForm:React.FC<editCampaignProps> = ({ campaign, onClose, }) => {
  const [title, setTitle] = useState(campaign.title);
  const [description, setDescription] = useState(campaign.description);
  const [targetAmount, setTargetAmount] = useState<number|string>(campaign.target);
  const [image, setImage] = useState<File|null>(null); // For new image upload
  const [video, setVideo] = useState(campaign.video);
  const [endDate, setEndDate] = useState<Date | null>(new Date(campaign.end_date));
  const [donationType, setDonationType] = useState<string|null>(campaign.donation_type);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);



  const navigate = useNavigate();

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle save logic, send data to API
    const updatedCampaign = {
      title:title.toString(),
      description:description,
      target: targetAmount,
      image:image, // Handle image upload
      video: video,
      end_date: endDate,
      donation_type: donationType,

    };

          // Create a FormData object to include the file
          const formData = new FormData();
          formData.append('data', JSON.stringify(updatedCampaign));
      
          if (image) {
              formData.append('image', image);
          }

    setIsSubmitting(true);

    const res = await makeMultipartFormPostRequest(`campaign/${campaign.id}`,formData);
      
        if (isErrorObject(res)) {
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
          setIsSubmitting(false)
        } else {
          setIsSuccess(true);                  
          
        
         navigate(`/campaign/manage/${campaign.id}`)
         onClose();

        }

  
  };

  /*const handleCampaignStatus =(e:React.ChangeEvent<HTMLInputElement>)=>{
    e.preventDefault()
    setCampaignStatus(e.target.value)
    updateCampaignStatus(e)
  }*/


  const handleAttachFile =(file:File)=>{
    setImage(file)
  
  
  }

  return (
    <form onSubmit={handleSave}>
        {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {isSuccess && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Admin Added 
                </Notification>
            )}
      <TextInput label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      <Textarea label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
      <TextInput label="Target Amount" value={targetAmount} onChange={(e) => setTargetAmount(e.target.value)} />
      <FileInput label="Image" onChange={setImage} placeholder="Choose image" />
      {campaign.image && <img src={campaign.image} alt="Current Image" />}


      <FileDropzone
        label="Upload campaign photo (No more than 5MB)"
        description="This is the picture to appear in campaign listing and on donation page."
        onDrop={(acceptedFiles:any) => {
        console.log('Accepted zero ', acceptedFiles[0])
       
        return handleAttachFile(acceptedFiles[0])
                                        }

                                        }                                  

                                        />
      <TextInput label="Video URL" value={video} onChange={(e) => setVideo(e.target.value)} />
      <DateInput label="End Date" value={endDate} onChange={setEndDate} />
      <Select
        label="Donation Type"
        data={[
          { value: "any_amount", label: "Any Amount" },
          { value: "minimum_amount", label: "Minimum Amount" },
          { value: "fixed_amount", label: "Fixed Amount" },
        ]}
        value={donationType}
        onChange={setDonationType}
      />
      {
        isSubmitting ?
        <Button type="submit" disabled><Loader/></Button>
        :
        <Button type="submit">Save Changes</Button>
      }
    </form>
  );
};

export default EditCampaignForm;
