import React from 'react';
import { Container, Text, Title, Grid } from '@mantine/core';
import { Helmet } from 'react-helmet';
import ServiceBox from '../components/ServiceBox';

const WhyFundReach: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Why Choose FundReach</title>
        <meta
          name="description"
          content="FundReach stands out for its ease of use, local-first approach, and diverse payment options tailored to each country's needs. By understanding regional pain points, FundReach offers custom solutions that make fundraising more accessible and efficient. With a minimal service fee and multiple currency support, FundReach ensures that more of the donated money reaches the intended cause."
        />
      </Helmet>
      <Container id="why-fundreach">
        <section id="whycs">
          <Title align="center" order={1}>
            <span className="highlight">Why</span> Choose Us?
          </Title>
          <Text align="center">
            FundReach stands out for its ease of use, local-first approach, and diverse payment options tailored to each
            country's needs. By understanding regional pain points, FundReach offers custom solutions that make
            fundraising more accessible and efficient. With a minimal service fee and multiple currency support, FundReach
            ensures that more of the donated money reaches the intended cause.
          </Text>
          <br />
          <br />
          <Grid>
            <Grid.Col span={12} md={6} lg={2}>
              <ServiceBox icon="icon" title="Accessibility" subtitle="Easy campaign creation and sharing" />
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={2}>
              <ServiceBox icon="icon" title="Local-first approach" subtitle="Customized solutions for each country's unique needs." />
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={2}>
              <ServiceBox icon="icon" title="Diverse payment options" subtitle="Supports M-PESA, credit cards, PayPal, Google Pay, Apple Pay, and more." />
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={2}>
              <ServiceBox icon="icon" title="Low Fees" subtitle="Service fee of less than 5%." />
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={2}>
              <ServiceBox icon="icon" title="Multiple currencies" subtitle="Supports donations from around the world" />
            </Grid.Col>
          </Grid>
        </section>
      </Container>
    </>
  );
};

export default WhyFundReach;
