import React, { useState, useEffect } from 'react';
import { Accordion, Text, Container, Loader, Center } from '@mantine/core';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { Helmet } from 'react-helmet';
import { isErrorObject } from '../utils/utils';

interface Faq {
  id: string;
  question: string;
  answer: string;
}

const FAQs: React.FC = () => {
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const getFaqs = async () => {
    setIsLoading(true);
    const res = await makeGetRequest('faqs');
    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setServerError(errorMessage);
      } else {
      setFaqs(res.data);
      setIsLoading(false);
      setServerError(null);
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (serverError) {
    return <Text color="red">{serverError}</Text>;
  }

  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta name="description" content="CS Church management and accounting Software Frequently asked questions." />
        <link rel="canonical" href="/faqs" />
      </Helmet>
      <Container>
        <Text align="center" size="xl" weight={700} mt="xl" mb="md">
          Frequently Asked Questions
        </Text>
        {faqs.length !== 0 ? (
          faqs.map((faq) => (
            <Accordion key={faq.id} variant="separated">
              <Accordion.Item value={faq.id}>
                <Accordion.Control>{faq.question}</Accordion.Control>
                <Accordion.Panel>{faq.answer}</Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          ))
        ) : (
          <Text color="blue">The list is empty</Text>
        )}
      </Container>
    </>
  );
};

export default FAQs;
