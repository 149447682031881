import {ICampaign, IDonation} from "../types";
import {Avatar, Group, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {useEffect, useState} from "react";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { makeGetRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { formatCurrency } from "../utils/FormValidator";
import { useNavigate } from "react-router-dom";

const PAGE_SIZE = 10;
interface PendingCampaignsProps {
    campaigns: ICampaign[];
}

const PendingCampaignsTable: React.FC<PendingCampaignsProps> = ({ campaigns }) => {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(campaigns.slice(0, PAGE_SIZE));
    const [donations, setDonations] = useState<IDonation[]>([]);
    const [error, setError] = useState<string | null>(null);

    const totalRaised = (campaignId:string):number=>{
        let total =0;
        const campaignDonations = donations && donations.filter((donation)=>donation.campaign_id===campaignId);
        campaignDonations && campaignDonations.map((d)=>{
            total += parseFloat(d.donation_amount);
        })

        return total;
    }

    


    useEffect(() => {

        const fetchDonations = async () => {
            const endpoint = 'campaign-donations';
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setDonations(res.data);
            }
        };
        fetchDonations();

           const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
       campaigns && setRecords(campaigns.slice(from, to));
    }, [page,campaigns]);

  if(error){
    return <p className="error">{error}</p>
  }

  const navigate = useNavigate();

  const handleRowClick = (campaign: ICampaign) => {
    navigate(`/campaign/manage/pending/${campaign.id}`,{state:campaign})
   
  };

   
    return (
        <DataTable
            columns={[
                {
                    accessor: 'Fund Raiser',
                    render: ({fundRaiser}: ICampaign) =>
                        <Group>
                            <Avatar src={fundRaiser.avatar} alt={`${fundRaiser.first_name} ${fundRaiser.last_name} profile avatar`} size="sm" radius="xl"/>
                            <Text>{fundRaiser.first_name} {fundRaiser.last_name}</Text>
                        </Group>
                },
                {accessor: 'title'},
                {
                    accessor: 'category',
                    render: ({category}: ICampaign) =>
                        <Group>
                           
                            <Text>{toTitleCase(category.category)} </Text>
                        </Group>
                },
                {
                    accessor: 'raised',
                    render:({id}:ICampaign)=> <Group>
                           
                    <Text>{formatCurrency(totalRaised(id))} </Text>
                </Group>


                },
                {accessor: 'target'},
                {accessor: 'donors'},
                {accessor: 'country'}
            ]}
            records={records}
            totalRecords={campaigns && campaigns.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            highlightOnHover
            verticalSpacing="sm"
            onRowClick={handleRowClick}
        />
    );
};

export default PendingCampaignsTable;
