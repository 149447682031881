//import Freecurrencyapi from '@everapi/freecurrencyapi-js';


//const freecurrencyapi = new Freecurrencyapi('YOUR-API-KEY');
/*freecurrencyapi.latest({
  base_currency: 'USD',
  currencies: 'EUR'
}).then(response => {
  console.log(response);
});*/

interface ErrorObject {
    errorMessage: string | null;
    statusCode: number | null;
    validationErrors: any | null;
    errorType: string | null;
  }

export const isErrorObject = (obj: any): obj is ErrorObject => {
    return obj && typeof obj.errorType === 'string';
  };


  
/**
 * Returns age based on date of birth
 */
export const getAge = (dob:Date):number=>{
  dob = new Date(dob);  
 //calculate month difference from current date in time  
 var month_diff = Date.now() - dob.getTime();  
   
 //convert the calculated difference in date format  
 var age_dt = new Date(month_diff);   
   
 //extract year from date      
 var year = age_dt.getUTCFullYear();  
   
 //now calculate the age of the user  
 var age = Math.abs(year - 1970);  
   

 return age;


}
/**
* Capitalize each word in a string
* for example come today will be Come Today
*/
export const toTitleCase = (str:string):string=>{
  //split the string into arrays of words
  const words = str.split(' ');
  for(let i=0; i<words.length;i++){
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  const capitalized = words.join(" ");
  return capitalized;

}

/** 
* Removes a value from array from any position
* @params array, value to be removed
* Returns modified array i.e. with value removed
* **/
export const removeFromArray =(arr:[],value:any)=>{      
  
  for( let i = 0; i < arr.length; i++){ 
  
      if ( arr[i] ===value) { 
  
          arr.splice(i, 1); 
      }
      return arr;
  
  }

}

export const formatDate = (dateString: string, language: string): string => {
  const date = new Date(dateString);

  interface Options extends Intl.DateTimeFormatOptions {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: boolean,
    timeZoneName: 'short',
    weekday: 'long'
  }

  const options: Options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZoneName: 'short',
    weekday: 'long'
  };

  const formattedDate = new Intl.DateTimeFormat(language, options).format(date);

  return formattedDate;
};


export function formatNumber(number:number,currency='USD') {
  // Check if the number is negative
  if (number < 0) {
      // Convert the negative number to positive and enclose in brackets
      return '(' + Math.abs(number).toLocaleString('en-US', { style: 'currency', currency: currency }) + ')';
  } else {
      // Return the number as currency
      return number.toLocaleString('en-US', { style: 'currency', currency: currency });
  }
}
