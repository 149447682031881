import { Stepper, Title, Text, Group, Image, Stack } from '@mantine/core';



interface CampaignPreviewProps {
    values: {
      title: string;
      city: string;
      target: string;
      // Add other fields as necessary
    };
    selectedCategory: string;
    selectedCountry: string;
    selectedCurrency: string;
    editor: any; // Define a more specific type if possible
    selectedFile: File | null;
  }
  

const CampaignPreview: React.FC<CampaignPreviewProps> = ({
  values,
  selectedCategory,
  selectedCountry,
  selectedCurrency,
  editor,
  selectedFile
}) => {
  return (
    <Stepper.Completed>
      <Title align="center" my="xl">
        Completed, take a seat while we finish setting up things for you
      </Title>
      <Stack spacing="md">
        <Group>
          <Text weight={700}>Title:</Text>
          <Text>{values.title}</Text>
        </Group>
        <Group>
          <Text weight={700}>Category:</Text>
          <Text>{selectedCategory}</Text>
        </Group>
        <Group>
          <Text weight={700}>Country:</Text>
          <Text>{selectedCountry}</Text>
        </Group>
        <Group>
          <Text weight={700}>Currency:</Text>
          <Text>{selectedCurrency}</Text>
        </Group>
        <Group>
          <Text weight={700}>City:</Text>
          <Text>{values.city}</Text>
        </Group>
        <Group>
          <Text weight={700}>Target Amount:</Text>
          <Text>
            {selectedCurrency}
            {values.target}
          </Text>
        </Group>
        <Group>
          <Text weight={700}>Story:</Text>
          <Text>{editor?.getText()}</Text>
        </Group>
        {selectedFile && (
          <Group>
            <Text weight={700}>Uploaded Image:</Text>
            <Image src={URL.createObjectURL(selectedFile)} alt="Uploaded file" width={200} height={200} />
          </Group>
        )}
      </Stack>
    </Stepper.Completed>
  );
};

export default CampaignPreview;
