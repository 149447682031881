import React from 'react';
import './PaymentFailed.css';
import { Link } from 'react-router-dom';

// Define the component as a functional component
const PaymentFailed: React.FC = () => {
  return (
    <div className='container'>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="message-box _success _failed">
            <i className="fa fa-times-circle" aria-hidden="true"></i>
            <h2> Payment failed or cancelled</h2>
            <p>We tried to process your payment but had no luck. We suggest you review your billing information and make changes if necessary.</p>
            <br />
            <p><Link to="/campaigns" className="link-anchor">TRY AGAIN</Link></p>
            <p><Link to="/">Try again later</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;
