import {
    Anchor,
    Button,
    Container,
    Divider,
    Group,
    Loader,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import { Helmet } from "react-helmet";
import { IconBrandFacebook, IconBrandGoogle } from "@tabler/icons-react";
import { useState } from 'react';
import validateSignupForm from '../validators/SignupFormValidator';
import { isEmpty } from '../utils/FormValidator';
import { makePostRequest } from '../services/httpHelper';
import { Navigate } from 'react-router-dom';
import { BASE_URL } from '../services/paths';
import { CountrySelect } from '../components';

const SignupPage = () => {

    const [values, setValues] = useState({
        email: '',
        mobile_number: '',
        password: '',
        password_confirm: '',
        first_name: '',
        last_name: '',
    });

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password2: '',
        mobile_number: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    const handleSignup = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setError(null)
        setIsSubmitting(true)
        setErrors(validateSignupForm(values));
        const data = {
            mobile_number: values.mobile_number,
            password: values.password,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            country:selectedCountry
        }

        console.log(errors.first_name)

        if (errors.first_name || errors.last_name || errors.password || errors.email || errors.mobile_number) {
            setIsSubmitting(false);
            return;
        }

        const res = await makePostRequest('auth/signup', data);

        if (res && res.errorMessage) {
            console.log('ERROR', res.errorMessage)
            setIsSubmitting(false);
            setError(res.errorMessage);
        } else {
            setIsSuccess(true)
            setIsSubmitting(false);
            setError(null)
        }
    }

    const handleFacebookSignup = () => {
        window.location.href = `${BASE_URL}auth/facebook`;
    }

    const handleGoogleSignup = () => {
        window.location.href = `${BASE_URL}auth/google`;
    }


    const handleCountryChange = (selectedCountry:string) => {
        setSelectedCountry(selectedCountry);
      
        if (selectedCountry !== '') {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ['country']:''
          
          }));
        }
      };

    return (
        <>
            <Helmet>
                <title>Signup</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title align="center" sx={() => ({ fontWeight: 900 })}>
                    Welcome back!
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    Already have an account?{' '}
                    <Anchor size="sm" component="button">
                        Login
                    </Anchor>
                </Text>

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <Group grow mb="md" mt="md">
                        <Button radius="xl" leftIcon={<IconBrandFacebook size={18} />} onClick={handleFacebookSignup}>Facebook</Button>
                        <Button radius="xl" leftIcon={<IconBrandGoogle size={18} />} onClick={handleGoogleSignup}>Google</Button>
                    </Group>
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader />}
                    {isSuccess && <Navigate to={`/verify-email/${values.email}`} />}
                    {error && <p className='error'>{error}</p>}

                    <TextInput label="First Name" placeholder="First Name" name="first_name" onChange={handleChange} value={values.first_name} />
                    {!isEmpty(errors.first_name) && <p className='error'>{errors.first_name}</p>}
                    <TextInput label="Last Name" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name} />
                    {!isEmpty(errors.last_name) && <p className='error'>{errors.last_name}</p>}
                    <TextInput label="Email" placeholder="Your Email" name="email" onChange={handleChange} value={values.email} mt="md" />
                    {!isEmpty(errors.email) && <p className='error'>{errors.email}</p>}
                    <TextInput label="Phone" placeholder="Your Mobile Phone" name="mobile_number" onChange={handleChange} value={values.mobile_number} mt="md" />
                    {!isEmpty(errors.mobile_number) && <p className='error'>{errors.mobile_number}</p>}
                    <CountrySelect
                    value={selectedCountry} 
                     onChange={handleCountryChange} 
                        />
                        {!selectedCountry && <p className='error'>Pick your country</p>}

                    <PasswordInput label="Password" placeholder="Password" name="password" onChange={handleChange} value={values.password} mt="md" />
                    {!isEmpty(errors.password) && <p className='error'>{errors.password}</p>}

                    <PasswordInput label="Confirm Password" placeholder="Confirm Password" name="password2" onChange={handleChange} mt="md" />
                    {!isEmpty(errors.password2) && <p className='error'>{errors.password2}</p>}

                    <Button fullWidth mt="xl" onClick={(e) => handleSignup(e)}>
                        Sign Up
                    </Button>
                </Paper>
            </Container>
        </>
    );
}

export default SignupPage;
