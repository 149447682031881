import { Box, BoxProps, Container, Flex, Select, SimpleGrid, Stack, TextInput, Title, TitleProps } from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CampaignCard } from "../components";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mantine/hooks";
import { makeGetRequest } from "../services/httpHelper"; // Adjust import based on your file structure
import ErrorHandler from "../services/ErrorHandler"; // Adjust import based on your file structure
import { isErrorObject } from "../utils/utils";
import { ICampaign } from "../types";

const CampaignsPage = (): JSX.Element => {
    const matchesMobile = useMediaQuery('(max-width: 768px)');
    const { categoryId } = useParams<{ categoryId?: string }>();
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [filteredCampaigns, setFilteredCampaigns] = useState<ICampaign[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [showLimit, setShowLimit] = useState<string>('10');
    const [sortBy, setSortBy] = useState<string>('featured');

    useEffect(() => {
        const fetchCampaigns = async () => {
            setIsLoading(true);
            const endpoint = categoryId ? `campaign/category/${categoryId}` : 'campaigns';
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setCampaigns(res.data);
                setFilteredCampaigns(res.data);
            }
            setIsLoading(false);
        };
        fetchCampaigns();
    }, [categoryId]);

    useEffect(() => {
        let result = campaigns;

        if (searchQuery) {
            result = result.filter(campaign =>
                campaign.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (sortBy === 'popular') {
            result.sort((a, b) => b.donors - a.donors);
        } else if (sortBy === 'latest') {
            result.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        }

        setFilteredCampaigns(result.slice(0, parseInt(showLimit)));
    }, [searchQuery, showLimit, sortBy, campaigns]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleShowLimitChange = (value: string) => {
        setShowLimit(value);
    };

    const handleSortByChange = (value: string) => {
        setSortBy(value);
    };

    const boxProps: BoxProps = {
        mt: matchesMobile ? 4 : 24,
        mb: matchesMobile ? 4 : 48,
        py: matchesMobile ? 16 : 24
    };

    const titleProps: TitleProps = {
        size: 32,
        weight: 700,
        mb: "lg",
        transform: 'capitalize',
        sx: { lineHeight: '40px' }
    };

    const items = filteredCampaigns && filteredCampaigns.map(c => (
        <CampaignCard key={c.id} data={c} showActions={true} />
    ));

    return (
        <>
            <Helmet>
                <title>Discover campaigns to support</title>
            </Helmet>
            <Box>
                <Container size="lg">
                    <Stack>
                        <Box {...boxProps}>
                            <Title {...titleProps} align="center">Discover campaigns to support</Title>
                        </Box>
                        <Flex
                            justify="space-between"
                            gap={{ base: 'sm', sm: 'lg' }}
                            direction={{ base: 'column-reverse', sm: 'row' }}
                        >
                            <TextInput
                                placeholder="search campaigns..."
                                sx={{ width: 500 }}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <Flex align="center" gap="sm" justify={{ base: 'space-between', sm: 'flex-start' }}>
                                <Select
                                    label=""
                                    placeholder="campaigns in"
                                    data={[
                                        { value: '10', label: 'show: 10' },
                                        { value: '25', label: 'show: 25' },
                                        { value: '50', label: 'show: 50' },
                                        { value: '100', label: 'show: 100' },
                                    ]}
                                    value={showLimit}
                                    onChange={handleShowLimitChange}
                                />
                                <Select
                                    label=""
                                    placeholder="Explore"
                                    defaultValue="featured"
                                    data={[
                                        { value: 'featured', label: 'sort by: featured' },
                                        { value: 'popular', label: 'sort by: popular' },
                                        { value: 'latest', label: 'sort by: latest' },
                                    ]}
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                />
                            </Flex>
                        </Flex>
                        <SimpleGrid
                            cols={3}
                            spacing="lg"
                            breakpoints={[
                                { maxWidth: 'md', cols: 2, spacing: 'md' },
                                { maxWidth: 'sm', cols: 1, spacing: 0 },
                            ]}
                        >
                            {isLoading ? <p>Loading...</p> : items}
                        </SimpleGrid>
                        {error && <p className="text-danger">{error}</p>}
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default CampaignsPage;
