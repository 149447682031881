import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makePostRequest, makeGetRequest, makePutRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { Box, Loader, Title, Notification } from '@mantine/core';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { ICampaignAdmin } from '../types';

type AdminFormData = {
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  role: string;
  is_mandatory_signatory: boolean;
};

type AdminProps = {
  admin:ICampaignAdmin
};

const EditAdminForm:React.FC<AdminProps>  = ({admin}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formData, setFormData] = useState<AdminFormData>({
    first_name: admin.user.first_name,
    last_name: admin.user.last_name,
    email: admin.user.email,
    mobile_number: admin.user.mobile_number,
    role: admin.role,
    is_mandatory_signatory: false,
  });

  const { id, adminId } = useParams<{ id: string; adminId?: string }>();

  useEffect(() => {
    if (adminId) {
      const fetchAdminData = async () => {
        const res = await makeGetRequest(`campaign-admin/${id}/admin/${adminId}`);
        if (!isErrorObject(res)) {
          setFormData(res.data);
        } else {
          setError('Failed to fetch admin details');
        }
      };

      fetchAdminData();
    }
  }, [id, adminId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    const request = adminId
      ? makePutRequest(`campaign-admin/${id}/admin/${adminId}`, formData)
      : makePostRequest(`campaign-admin/${id}`, formData);

    const res = await request;
    setIsSubmitting(false);

    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      setSuccess(true);
      // Resetting the form fields to empty strings
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        role: '',
        is_mandatory_signatory: false,
      });
    }
  };

  return (
    <Box mx="auto">
      {error && (
        <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
          {error}
        </Notification>
      )}
      {success && (
        <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
          Admin {adminId ? 'updated' : 'added'} successfully.
        </Notification>
      )}

      <form onSubmit={handleSubmit}>
        {isSubmitting && <Loader />}
        <Title order={3} align="center" mb="lg">
          {adminId ? 'Edit Campaign Admin' : 'Add Campaign Admin'}
        </Title>

        <div>
          <label htmlFor="first_name">First Name:</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="last_name">Last Name:</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mobile_number">Mobile Number:</label>
          <input
            type="tel"
            id="mobile_number"
            name="mobile_number"
            value={formData.mobile_number}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="role">Role:</label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="is_mandatory_signatory">Is Mandatory Signatory:</label>
          <input
            type="checkbox"
            id="is_mandatory_signatory"
            name="is_mandatory_signatory"
            checked={formData.is_mandatory_signatory}
            onChange={handleChange}
          />
        </div>
        <button type="submit">
          {adminId ? 'Update Admin' : 'Add Admin'}
        </button>
      </form>
    </Box>
  );
};

export default EditAdminForm;
