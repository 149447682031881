import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Loader, 
  Notification, 
  Progress,
  Stack,
  Tabs,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { IconAlertCircle, IconCheck, IconHeart, IconHeartFilled, IconShare } from "@tabler/icons-react";
import { useDisclosure, useMediaQuery, useToggle } from "@mantine/hooks";
import { BackButton, DonationDrawer, NotFound, ShareModal, UserCard } from "../components";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { notifications } from "@mantine/notifications";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { makeGetRequest, makePostRequest, makePutRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { formatCurrency } from "../utils/FormValidator";
import { Link } from "react-router-dom";
//import CampaignDonorsTable from "../components/CampaignDonorsTable";
import { IMAGE_BASE_URL } from "../services/paths";
import ErrorBoundary from "../utils/ErrorBoundary";
import ManageTeamPage from "./ManageTeams";
import ManageAdminPage from "./ManageAdmins";
import { ICampaign,  ICampaignTransHistory,  IDonation } from "../types";
import { Modal, Select, /*Switch*/ } from "@mantine/core";
import EditCampaignForm from "../components/EditCampaignForm"; // Make sure to create this component
//import NotificationComponent from "../components/Notification";
import TransactionHistory from "./TransactionHistory";
import AdminCampaignDonationTable from "../components/AdminCampaignDonationTable";




const ManageCampaignPage = (): JSX.Element => {
  dayjs.extend(LocalizedFormat);
  const { id } = useParams();

  const [campaign, setCampaign] = useState<ICampaign>();
  const [opened, { open, close }] = useDisclosure(false);
  const [donateOpened, { open: donateOpen, close: donateClose }] = useDisclosure(false);
  const [following, setFollowing] = useToggle();
  const matchesMobile = useMediaQuery("(max-width: 768px)");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [transHistory, setTransHistory] = useState<ICampaignTransHistory[]>([]);


  const [donations, setDonations] = useState<IDonation[]>([]);
  //const [totalFollowers, setTotalFollowers] = useState(0);
  //const [teams, setTeams] = useState<ICampaignTeam[]>([]);
  const [editModalOpen, setEditModalOpen] = useState(false);


  const campaignImage = IMAGE_BASE_URL + campaign?.image;

  const getProgress = (raised: number, target: number): number => {
    return (raised / target) * 100;
  };

  const totalRaised = (): number => {
    let total = 0;
    const campaignDonations = donations && donations.filter((donation) => donation.campaign_id === id);
    campaignDonations?.forEach((d) => {
      total += parseFloat(d.donation_amount);
    });
    return total;
  };

  const fetchCampaignData = async () => {
    setIsLoading(true);
    try {
      const campaignRes = await makeGetRequest(`campaign/${id}`);
      if (isErrorObject(campaignRes)) {
        throw new Error(ErrorHandler(campaignRes));
      }
      setCampaign(campaignRes.data);
     // setTotalFollowers(campaignRes.data.campaignfollowers.length);

      const donationsRes = await makeGetRequest(`campaign-donors/${id}`);
      if (isErrorObject(donationsRes)) {
        throw new Error(ErrorHandler(donationsRes));
      }
      setDonations(donationsRes.data);

      const teamsRes = await makeGetRequest(`campaign-team/${id}`);
      if (isErrorObject(teamsRes)) {
        throw new Error(ErrorHandler(teamsRes));
      }
      //setTeams(teamsRes.data);
    } catch (err) {
        console.log(err)
      setError('something went wrong!')
    } finally {
      setIsLoading(false);
    }
  };


  // Handle functions for actionable settings
  const handleWithdrawalMandateToggle = async (value: string) => {
    setIsSuccess(false);
    setError(null)
    const confirm = window.confirm('Are sure you want to change withdrawal from campaign approval mandate?')
    if(confirm){
      try {
       const res= await makePutRequest(`campaign/${id}/update-signing-mandate`, { mandate: value });
        if (isErrorObject(res)) {
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
        } else {
          setIsSuccess(true);                  
        
     
    
        }
      } catch (err) {
        console.error("Failed to update status:", err);
      }

    }
 
  };

  // Handle functions for actionable settings
  /*const handleCampaignVisibilityToggle = async (value: boolean) => {
    setIsSuccess(false);
    setError(null)
    // Send request to update campaign visibility
    const confirm = 'Are sure you want to change visibility of this campaign?'
    if(confirm){
      try {
       const res= await makePostRequest(`campaign/${id}/update-visibility`, { visibility: value ? "public" : "private" });
        if (isErrorObject(res)) {
          const errorMessage = ErrorHandler(res);
          setError(errorMessage);
        } else {
          setIsSuccess(true);                  
        
     
    
        }
      } catch (err) {
        console.error("Failed to update visibility:", err);
      }

    }
 
  };*/

  const handleCampaignModeChange = async (value: string) => {
    setIsSuccess(false);
    setError(null)
    let confirmMessage;
  
    switch(value){
      case 'closed':
        confirmMessage='Are sure you want to close this campaign? Closed campaigns will not be visible to donors or accept donations';
      
        break;
        case 'suspended':
          confirmMessage='Are sure you want to mark this campaign as suspended? Once suspended will be available to donors but no donations can be accepted';
          break;
          case 'completed':
            confirmMessage="Are sure you want to mark this campaign as completed? Completed campaigns are thos that have reached its target or is no longer relevant."
            break;
            default:
              confirmMessage="Are sure about this?"
              break;

    }
    const confirm = window.confirm(confirmMessage)

    if(confirm){
          // Send request to update campaign mode
    try {
    const res =  await makePutRequest(`campaign/${id}/update-status`, { status: value });
    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      setIsSuccess(true);                  
    
 

    }
      
    } catch (err) {
      console.error("Failed to update mode:", err);
    }

    }
  

  };

  




  const getTransHistory = async () => {
    setIsLoading(true);
    const res = await makeGetRequest(`campaign/transaction-history/${id}`);

    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
    } else {
        const results = res.data;
        setTransHistory(results);       
        setIsLoading(false);
        setError(null);
    }
};

  useEffect(() => {
    fetchCampaignData();
    getTransHistory()
  }, [id]);

  const followCampaign = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const data = {
      user_id: user.id,
      campaign_id: id,
    };
    const res = await makePostRequest("follow-campaign", data);

    if (res && res.errorType) {
      setError(res.errorMessage);
    } else {
      setFollowing(!following);
      notifications.show({
        title: "Notification",
        message: `${following ? "Following" : "Unfollowed"} this campaign`,
        withBorder: true,
      });
    }
  };

  return (
    <>
      <ErrorBoundary>
        <Helmet>
          <title>{campaign?.title}</title>
        </Helmet>
        <Box>
      <Container>
      {isLoading && <Loader />}

{isSuccess && (
        <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
            Status updated
        </Notification>
    )}
      {error && (
        <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
            {error}
        </Notification>
    )}
      </Container>
          {campaign ? (
            <Container size="lg">
              <BackButton mb="md" />
              <Grid>
                <Grid.Col lg={12}>
                  <Stack>
                    <Tabs defaultValue="campaign">
                      <Tabs.List>
                        <Tabs.Tab value="campaign">Campaign</Tabs.Tab>
                        <Tabs.Tab value="donations">Donations</Tabs.Tab>
                        <Tabs.Tab value="promoters">Promoters</Tabs.Tab>
                        <Tabs.Tab value="followers">Followers</Tabs.Tab>
                        <Tabs.Tab value="admins">Admins</Tabs.Tab>
                        {/* Tabs for future implementation */}
                        {/*<Tabs.Tab value="notifications" disabled>
                          Notifications
                        </Tabs.Tab>*/}
                        <Tabs.Tab value="trans-history" >
                          Trans. History
                        </Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="campaign">
                        <Card padding="md" shadow="sm">
                          <Card.Section>
                            <img crossOrigin="anonymous" src={`${campaignImage}`} height={480} />
                          </Card.Section>
                          <Stack mt="md">
                            {!matchesMobile ? (
                              <Flex gap="xs" align="center">
                                <Text size="sm">Campaign created by</Text>
                                <UnstyledButton component={Link} to={`/user/${campaign.fundRaiser.id}`}>
                                  <Flex gap="xs" align="center">
                                    <Avatar src={campaign?.fundRaiser.avatar} radius="xl" size="sm" />
                                    <Text size="sm">
                                      {campaign?.fundRaiser.first_name} {campaign?.fundRaiser.last_name}
                                    </Text>
                                  </Flex>
                                </UnstyledButton>
                                <Text component={Link} to={`/country/${campaign?.country}`} size="sm">
                                  {campaign?.country}
                                </Text>
                                <Text component={Link} to={`/category/${campaign.category.id}`} size="sm">
                                  {toTitleCase(campaign.category.category)}
                                </Text>
                              </Flex>
                            ) : (
                              <Stack>
                                <Flex gap="md">
                                  <Text size="sm">Fundraise campaign created by</Text>
                                  <UnstyledButton component={Link} to={`/user/${campaign.fundRaiser.id}`}>
                                    <Flex gap="xs" align="center">
                                      <Avatar src={campaign.fundRaiser?.avatar} radius="xl" size="sm" />
                                      <Text size="sm">
                                        {campaign?.fundRaiser.first_name} {campaign?.fundRaiser.last_name}
                                      </Text>
                                    </Flex>
                                  </UnstyledButton>
                                </Flex>
                                <Group>
                                  <Text size="sm">
                                    Location - <Link to={`/country/${campaign?.country}`}>{toTitleCase(campaign?.country)}</Link>
                                  </Text>
                                  <Text size="sm">
                                    Category - <Link to={`/category/${campaign.category.id}`}>{toTitleCase(campaign.category.category)}</Link>
                                  </Text>
                                </Group>
                              </Stack>
                            )}
                            <Text size="lg" weight={600}>
                              Our story
                            </Text>
                            <Text size="sm">{campaign?.description}</Text>
                            <Divider />
                            <Flex justify="space-between">
                              <Title size={32}>{totalRaised()}</Title>
                              <Text fw={500} align="center" color="dimmed">
                                raised of {formatCurrency(parseFloat(campaign?.target.toString()))}
                              </Text>
                            </Flex>
                            <Progress value={getProgress(parseFloat(totalRaised().toString()), parseFloat(campaign.target.toString()))} size="md" />
                            <Flex justify="space-between">
                              <Text fw={500}>{donations.length} Donors</Text>
                              <Button onClick={() => setEditModalOpen(true)}>
                                Edit Campaign
                              </Button>
                            </Flex>
                            <Flex align="center" gap="xs">
                              <Button onClick={donateOpen} fullWidth>
                                Donate
                              </Button>
                              <ActionIcon
                                variant="subtle"
                                onClick={open}
                                color="blue"
                                title="Share with your friends"
                                size="lg"
                              >
                                <IconShare size={18} />
                              </ActionIcon>
                              <ActionIcon
                                title={`${following ? "Unfollow" : "Follow"} this campaign`}
                                size="lg"
                                color="secondary"
                                onClick={followCampaign}
                              >
                                {following ? <IconHeartFilled size={18} /> : <IconHeart size={18} />}
                              </ActionIcon>
                            </Flex>

                            
                          </Stack>
                           {/* Actionable Settings */}
            <Stack mt="md" spacing="xs">
             {/* <Group position="apart">
                <Text>Campaign Visibility {campaign.compaign_visility}</Text>
                <Switch
                  checked={campaign.compaign_visility === "public"}
                  onChange={(e) => handleCampaignVisibilityToggle(e.currentTarget.checked)}
                  label="Public"
                />
              </Group>*/}

              {/*<Group position="apart">
                <Text>Approval Status</Text>
                <Text>{campaign.approval_status && toTitleCase(campaign.approval_status)}</Text> {/* Not editable */}
             {/* </Group>*/}

              <Group position="apart">
                <Text>Campaign Mode</Text>
                <Select
                  data={[
                    { value: "active", label: "Active" },
                    { value: "closed", label: "Closed" },
                    { value: "suspended", label: "Suspended" },
                    { value: "completed", label: "Completed" },
                  ]}
                  value={campaign.compaign_type}
                  placeholder={campaign.status.toLocaleUpperCase()}
                  onChange={handleCampaignModeChange}
                />
              </Group>

              <Group position="apart">
                <Text>Withdrawal Mandate</Text>
                <Select
                  data={[
                    { value: "any_two", label: "Any Two" },
                    { value: "all", label: "All" },
                  ]}
                  value={campaign.withdrawal_mandate}
                  disabled={!campaign.has_admins}
                  placeholder={campaign.withdrawal_mandate}
                   onChange={handleWithdrawalMandateToggle}
                />
              </Group>
            </Stack>

                          
                        </Card>
                     
                      </Tabs.Panel>

                      <Tabs.Panel value="donations">
                        <AdminCampaignDonationTable donations={donations} campaignId={id||''} />
                      </Tabs.Panel>

                      <Tabs.Panel value="promoters">
                        <ManageTeamPage />
                      </Tabs.Panel>

                      <Tabs.Panel value="followers">
                        <UserCard campaignId={id ?? ''}/>
                      </Tabs.Panel>

                      <Tabs.Panel value="admins">
                        <ManageAdminPage />
                      </Tabs.Panel>

                      {/*<Tabs.Panel value="notifications">
                        <NotificationComponent />
                      </Tabs.Panel>*/}

                      <Tabs.Panel value="trans-history">
                        <TransactionHistory transactions={transHistory} campaignId={id||''} />
                      </Tabs.Panel>
                    </Tabs>
                  </Stack>
                </Grid.Col>
               
              </Grid>
               {/* Edit Campaign Modal */}
            <Modal
              opened={editModalOpen}
              onClose={() => setEditModalOpen(false)}
              title="Edit Campaign"
            >
              <EditCampaignForm
                campaign={campaign}
                onClose={() => setEditModalOpen(false)}
                onSave={(updatedCampaign) => setCampaign(updatedCampaign)}
              />
            </Modal>
            </Container>
          ) : (
            <NotFound />
          )}

<ShareModal opened={opened} onClose={close} campaign={campaign} iconSize={18}/>
<DonationDrawer campaign={campaign} opened={donateOpened} onClose={donateClose} iconSize={18}/>
        </Box>
      </ErrorBoundary>
    </>
  );
};

export default ManageCampaignPage;
