import { useEffect, useRef, useState } from "react";
import { DataTable } from "mantine-datatable";
import { ICampaign, IDonation } from "../types";
import { Avatar, Button, Group, Text, ActionIcon } from "@mantine/core";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { makeGetRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { formatCurrency } from "../utils/FormValidator";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import { IconBrandWhatsapp, IconDownload, IconMail, IconPrinter } from "@tabler/icons-react";

const PAGE_SIZE = 10;

interface CampaignsTableProps {
    campaigns: ICampaign[];
}

const DonatorsTable: React.FC<CampaignsTableProps> = ({ campaigns }) => {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(campaigns.slice(0, PAGE_SIZE));
    const [donations, setDonations] = useState<IDonation[]>([]);
    const [error, setError] = useState<string | null>(null);
    const tableRef = useRef(null);

    const totalRaised = (campaignId: string): number => {
        let total = 0;
        const campaignDonations = donations && donations.filter((donation) => donation.campaign_id === campaignId);
        campaignDonations && campaignDonations.map((d) => {
            total += parseFloat(d.donation_amount);
        });

        return total;
    };

    useEffect(() => {
        const fetchDonations = async () => {
            const endpoint = 'campaign-donations';
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setDonations(res.data);
            }
        };
        fetchDonations();
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        campaigns && setRecords(campaigns.slice(from, to));
    }, [page, campaigns]);

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    const handleExportPDF = () => {
        const input = tableRef.current;
        if (input) {
            html2canvas(input as HTMLElement).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                // Corrected usage of addImage with appropriate parameters
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save("donations.pdf");
            });
        } else {
            console.error("Table reference is null. Cannot export to PDF.");
        }
    };
    

    const handleShareEmail = () => {
        const mailtoLink = `mailto:?subject=Donation Report&body=Find the attached donation report as PDF or CSV.`;
        window.open(mailtoLink, '_blank');
    };

    const handleShareWhatsapp = () => {
        const whatsappLink = `https://wa.me/?text=Find the attached donation report as PDF or CSV.`;
        window.open(whatsappLink, '_blank');
    };

    if (error) {
        return <p className="error">{error}</p>;
    }

    const csvData = campaigns.map(campaign => ({
        fundraiser: `${campaign.fundRaiser.first_name} ${campaign.fundRaiser.last_name}`,
        raised: formatCurrency(totalRaised(campaign.id)),
        country: campaign.country
    }));

    return (
        <div>
            <Group position="apart" mb="md">
                <Group>
                    <Button onClick={handlePrint} leftIcon={<IconPrinter size={14} />}>Print</Button>
                    <Button onClick={handleExportPDF} leftIcon={<IconDownload size={14} />}>Export to PDF</Button>
                    <CSVLink data={csvData} filename="donations.csv">
                        <Button leftIcon={<IconDownload size={14} />}>Export to CSV</Button>
                    </CSVLink>
                </Group>
              
                <Group>
                    <ActionIcon onClick={handleShareEmail}>
                        <IconMail size={20} />
                    </ActionIcon>
                    <ActionIcon onClick={handleShareWhatsapp}>
                        <IconBrandWhatsapp size={20} />
                    </ActionIcon>
                </Group>
            </Group>

            <div ref={tableRef}>
                <DataTable
                    columns={[
                        {
                            accessor: 'Fund Raiser',
                            render: ({ fundRaiser }: ICampaign) =>
                                <Group>
                                    <Avatar src={fundRaiser.avatar} alt={`${fundRaiser.first_name} profile avatar`} size="sm" radius="xl" />
                                    <Text>{toTitleCase(fundRaiser.first_name)} {toTitleCase(fundRaiser.last_name)}</Text>
                                </Group>
                        },
                        {
                            accessor: 'raised',
                            render: ({ id }: ICampaign) => <Group>
                                <Text>{formatCurrency(totalRaised(id))}</Text>
                            </Group>
                        },
                        { accessor: 'country' }
                    ]}
                    records={records}
                    totalRecords={campaigns && campaigns.length}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    highlightOnHover
                    verticalSpacing="sm"
                    striped
                />
            </div>
        </div>
    );
};

export default DonatorsTable;
