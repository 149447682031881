import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import SanitizeHtmlComponent from './SanitizeHtmlComponent';
import './Post.css';
import { IMAGE_BASE_URL } from '../services/paths';
import { makeGetRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { Helmet } from 'react-helmet';
//import SocialShare from '../components/SocialShare';
import { Container, Grid, Image, Title, Text, Box, Space, Loader } from '@mantine/core';
import { isErrorObject } from '../utils/utils';

interface Author {
  first_name: string;
  last_name: string;
}

interface PostData {
  id: number;
  slug: string;
  featuredImage: string;
  title: string;
  content: string;
  createdAt: string;
  author: Author | null;
}

const Post: React.FC = () => {
  const [post, setPost] = useState<PostData | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<PostData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams<{ id: string }>();
  const currentUrl = window.location.href;

  const getPost = async () => {
    setIsLoading(true);
    const res = await makeGetRequest(`blog-post/${id}`);
    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
      setPost(res.data);
      setIsLoading(false);
      setError(null);
    }
  };

  const getRelatedArticles = async () => {
    setIsLoading(true);
    const res = await makeGetRequest(`related-posts/${id}`);
    if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
      setRelatedPosts(res.data);
      setIsLoading(false);
      setError(null);
    }
  };

  useEffect(() => {
    getPost();
    getRelatedArticles();
  }, [id]);

  const stripHtmlTags = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const extractDescription = (htmlContent: string, wordLimit: number): string => {
    const textContent = stripHtmlTags(htmlContent);
    return textContent.split(' ').slice(0, wordLimit).join(' ') + '...';
  };

  const postDescription = post ? extractDescription(post.content, 20) : '';
  const metaDesc = `${post ? post.title : ''}`;
  const metaLink = `/blog/${post ? post.slug : ''}/${id}`;
  const metaImage = post && post.featuredImage ? `${IMAGE_BASE_URL}${post.featuredImage}` : '';

  return (
    <>
      <Helmet>
        <title>{post ? post.title : 'CS Church Management Software'}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={metaLink} />
        <meta property="og:title" content={post ? post.title : ''} />
        <meta property="og:description" content={postDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container className="post-wrapper" size="md">
        {isLoading && <Loader/>}
        {error && <p className='error'>{error}</p>}
        {post !== null ?
          <>
            {post.featuredImage !== null &&
              <article className="hentry">
                <Box className="featured-image">
                  <Image
                    src={`${IMAGE_BASE_URL}${post.featuredImage}`}
                    alt={post.title}
                    className='post-img'
                  />
                </Box>

                <Title className="entry-title">{post.title}</Title>
                <Box className="entry-meta">
                  <Text>
                    <span className="author">Written by <a href="#">{post.author !== null && post.author.first_name} {post.author !== null && post.author.last_name}</a></span>
                   {/* <span className="date"> <Moment fromNow>{post.createdAt}</Moment></span>*/}
                  </Text>
                </Box>
                <Box className="entry-content">
                  <SanitizeHtmlComponent htmlContent={post.content} />
                </Box>

                <footer className="entry-footer">
                  <Space h="md" />
                  <Box style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                   {/* <SocialShare title={post.title} shareUrl={currentUrl} />*/}
                  </Box>
                </footer>
              </article>
            }
            <Title order={5}>Related</Title>
            {relatedPosts.length !== 0 &&
              relatedPosts.map((relatedPost) => (
                <Grid key={relatedPost.id} style={{ marginBottom: "25px" }}>
                  <Grid.Col span={8}>
                    <Link to={`/blog/${relatedPost.slug}/${relatedPost.id}`}>
                      <Title order={4}>{relatedPost.title}</Title>
                    </Link>
                  </Grid.Col>
                </Grid>
              ))
            }
          </>
          :
          <Text color="yellow">Nothing to show</Text>
        }
      </Container>
   
    </>
  );
};

export default Post;
