import {forwardRef, useEffect, useState} from 'react';
import {Avatar, Group, Loader, Select, Text} from "@mantine/core";
import {ICountry} from "../types";
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { makeGetRequest } from '../services/httpHelper';

interface CountrySelectProps {
    value: string;
    onChange: (value: string) => void;
  }

const CountrySelectItem = forwardRef<HTMLDivElement, ICountry>(
    ({image, country, country_code, ...others}: ICountry, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Avatar src={image}/>

                <div>
                    <Text size="sm">{country}</Text>
                    <Text size="xs" opacity={0.65}>
                        {country_code}
                    </Text>
                </div>
            </Group>
        </div>
    )
);



const CountrySelect: React.FC<CountrySelectProps> = ({ value, onChange }) => {
    const [countriesData, setCountriesData] = useState<ICountry[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
  
    const getCountries = async () => {
      setIsLoading(true);
      const res = await makeGetRequest('countries');
  
      if (isErrorObject(res)) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
        const results = res.data;
        setCountriesData(results);
        setIsLoading(false);
        setError(null);
      }
    };
  
    useEffect(() => {
      getCountries();
    }, []);
  
    if (isLoading) {
      return <Loader />;
    }
    if (error) {
      return <p className='error'>{error}</p>;
    }
  
    return (
      <Select
        label="Country"
        itemComponent={CountrySelectItem}
        data={countriesData.map(c => ({ value: c.country_code, label: c.country, ...c }))}
        searchable
        clearable
        maxDropdownHeight={300}
        nothingFound="Nothing found"
        filter={(value, item) =>
          item?.country?.toLowerCase().includes(value?.toLowerCase().trim()) ||
          item?.code?.toLowerCase().includes(value?.toLowerCase().trim())
        }
        value={value}
        onChange={onChange}
      />
    );
  };
  
  export default CountrySelect;
