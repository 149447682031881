import {Button, Card, CardProps, Code,  Loader,  TextInput} from "@mantine/core";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import { makePostRequest } from "../services/httpHelper";

type IProps = Omit<CardProps, 'children'>

const ContactCard = ({...cardProps}: IProps) => {
    const [submittedValues, setSubmittedValues] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const[num1,setNum1] =useState<number>(0);
    const[num2,setNum2] =useState<number>(0)
    const [operator, setOperator] = useState<string>('+');
    const [isSuccess, setIsSuccess] = useState(false)



    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            message: '',
            answer: ''
        },

        validate: {
            name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            message: (value) => (value.length < 20 ? 'Message must have at least 20 letters' : null),
            answer: (value) => {
                const correctAnswer = operator === '+' ? num1 + num2 : num1 - num2;
                return parseInt(value, 10) === correctAnswer ? null : 'Incorrect answer';
            }
        },
      
    });

    const handleCantactUs = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const validate = form.validate;

        console.log(validate)

        setIsSubmitting(true)
        const data = {
            name:form.values.name,
            email:form.values.email,
            message:form.values.message
        }
        const res = await makePostRequest('contact-us', data);

        if (res && res.errorType) {
            setIsSubmitting(false);
            setError(res.errorMessage)
        } else {
          
            setIsSubmitting(false);
            setIsSuccess(true)
        }
    }

    useEffect(()=>{
        setNum1(Math.floor(Math.random() * 101));
        setNum2(Math.floor(Math.random() * 11));
        setOperator(Math.random() > 0.5 ? '+' : '-');


    },[])

    return (
        <Card {...cardProps} className="contact-form">
         
            <Card.Section>
                {error && <p className="error">{error}</p>}
                {isSuccess && <p className="success">Message send</p>}
                {isSubmitting && <Loader/>}
                <form
                    onSubmit={form.onSubmit((values) => setSubmittedValues(JSON.stringify(values, null, 2)))}
                >
                    <TextInput
                        label="Name"
                        placeholder="name"
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        label="Email"
                        placeholder="email"
                        mt="md"
                        {...form.getInputProps('email')}
                    />
                    <TextInput
                        label="Message"
                        placeholder="message"
                        mt="md"
                        className="caontact-message"
                        {...form.getInputProps('message')}
                    />

                  <TextInput
                         label={`${num1} ${operator} ${num2} =`}
                        placeholder="Your Answer"
                        mt="md"
                        {...form.getInputProps('answer')}
                    />
                    <Button type="submit" mt="md"  disabled={!form.values.answer} onClick={handleCantactUs}>
                        Send Message 
                    </Button>
                </form>
                {submittedValues && <Code block>{submittedValues}</Code>}
            </Card.Section>
        </Card>
    );
};

export default ContactCard;
