import { Container } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
  return (
   <Container style={{marginTop:"10%"}}>

    <div>
      <h1>403 Unauthorized</h1>
      <p>You do not have the necessary permissions to access this page.</p>
      <Link to="/dashboard">Go back to dashboard</Link>
    </div>
  
   </Container>
  );
};

export default UnauthorizedPage;
