//import React from 'react';
import {
    Box,
  //  Button,
    Container,
  //  createStyles,
   // Group,
    Paper,
  
    Text,
    Title,
  //  TitleProps,
   // TextInput,
 //   Textarea,
    Card
} from "@mantine/core";
import { Helmet } from "react-helmet";
import { ContactCard } from '../components';

/*const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));*/

const ContactUsPage = () => {
   // const { classes } = useStyles();

    const paperProps = {
        p: "md",
        shadow: "sm"
    };

    /*const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Handle form submission logic here
    };*/

    return (
        <>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <Box>
                <Container fluid my="xl" >
                    <Paper {...paperProps}>
                        <Title className="contact-title">Contact Us</Title>
                        <Text size="sm" className="contact-subtitle">Please fill out the form below to contact our support team:</Text>
                        <Card>
                        <Box>
                  <div className="contact-form">
                    
                  <ContactCard/>
                  </div>
                   </Box>
                    
                        </Card>
                     
                    </Paper>
                   
                </Container>
            </Box>
        </>
    );
};

export default ContactUsPage;
