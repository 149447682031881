import  {  useState } from 'react';
import {
    Box,

    Title,
    Notification,
    Loader,
    Container,
    Button,
    TextInput,
    SimpleGrid,
} from '@mantine/core';
import { IconCheck, IconAlertCircle, IconSelector } from '@tabler/icons-react';
import {  useNavigate, useParams } from 'react-router-dom';
import { makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';




const WithdrawalVerifyOtp= () => {
    const [otp, setOtp] = useState<string | null>();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


    const{id} = useParams();
    const withdrawalId = localStorage.getItem('wi');

    const params = new URLSearchParams(window.location.search);
    const adminData = params.get('dt') ? JSON.parse(decodeURIComponent(params.get('dt') as string)) : null;
    console.log(adminData)

  



    const verifyOtp = async () => {

        const data ={
            adminId:adminData.ai,
            campaignId:id,
           withdrawalId:withdrawalId,
            otp:otp,
          
        }
        setIsSubmitting(true);

        const res = await makePostRequest(`verify-withdrawal-admin-otp`,data);

        if (isErrorObject(res)) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setIsSubmitting(false);
            setError(null);
            setSuccess(true)
            navigate(`/request-admin-approval/${id}`,{state:{}})

        }
    };


   
const navigate = useNavigate();
   const redirectback=()=>{
    setTimeout(() => {
        navigate(`/request-admin-approval/${id}?wi=${adminData.ai}`)
        
    }, 5000);

   }

        

    return (
        <Box  mx="auto">
           <Container>
           {isSubmitting && <Loader/>}
            {error && <p className='error'>{error}</p>}
            <Title order={3} align="left" mb="lg">Verify OTP</Title>
            <p>Approval Code has been send to <strong>{adminData.adn}</strong> mobile phone. Enter it below</p>

            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
               <>
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Request Approved successfully
                </Notification>
                {redirectback()}
               </>
             
            )}

          
             
           


        <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>

            <TextInput 
            label="Enter OTP"
            name="otp" 
            value={otp?.toString()}
            onChange={(e) => setOtp(e.target.value)}
                                         />

</SimpleGrid>
<br/>
                    <Button
                    leftIcon={<IconSelector size={18}/>}
                     mx="auto"
                    variant="light"
                    onClick={verifyOtp} disabled={isSubmitting}

                        >
                        {isSubmitting ? <Loader/> : 'Verify OTP'}
                                    </Button>
                                    </Container>
         
             
           
            </Box>
         
      
    );
};

export default WithdrawalVerifyOtp;
