import React, { useState, useEffect } from 'react';

import {
    Anchor,
    Button,
    Container,
    Divider,
    Group,
    Loader,
    Paper,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import {Helmet} from "react-helmet";
import {IconBrandFacebook, IconBrandGoogle} from "@tabler/icons-react";
import { makePostRequest } from '../services/httpHelper';
import { Navigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../services/paths';




const VerifyEmailPage = () => {
  const [countDown, setCountDown] = useState<number>(60);
  const [showResendButton, setShowResendButton] = useState<boolean>(false);
  const[verificationCode,setVerificationCode]=useState<string | null>(null);
    const[error,setError]=useState<string | null>(null);
    const[isSubmitting,setIsSubmitting]=useState(false);
    const[isSuccess,setIsSuccess]=useState(false);

    const {email} = useParams();




    const handleChange = (e:  React.ChangeEvent<HTMLInputElement>) =>{
        setVerificationCode(e.target.value)
    
    }

    const handleSubmit =async(e: React.MouseEvent<HTMLButtonElement>)=>{
        setError(null)
        e.preventDefault();
        if(verificationCode===null){
            setError('Enter Verification Code');
            return;
        }

        setIsSubmitting(true)
     

        const data ={
          verification_code:verificationCode,
          email:email,

        
      }

        const res = await makePostRequest('auth/verify-email',data);
        if(res && res.errorType){
            setIsSubmitting(false);
            setError(res.errorMessage);
                   
    
      
          }else{
                       
            setIsSubmitting(false);
            setIsSuccess(true)

            
          }

        
    }

        

  useEffect(() => {
    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown(prevCount => prevCount - 1);
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval on unmount
    } else {
      setShowResendButton(true);
    }
  }, [countDown]);

  const resendVerificationCode = async(e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // Your resend verification code logic here

    // Reset the countdown and hide the button
    setCountDown(60);
    setShowResendButton(false);
    setIsSubmitting(true)
     

        const data ={
          email:email,

        
      }

        const res = await makePostRequest('auth/resend-email-verification-code',data);
        if(res && res.errorType){
            setIsSubmitting(false);
            setError(res.errorMessage) 
          }else{                       
            setIsSubmitting(false);
            setIsSuccess(true)

            
          }


  };

  const handleFacebookSignup = () => {
    window.location.href = `${BASE_URL}auth/facebook`;
}

const handleGoogleSignup = () => {
    window.location.href = `${BASE_URL}auth/google`;
}

  return (
    <>
            <Helmet>
                <title>Signup</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={() => ({fontWeight: 900})}
                >
                   Verify Email
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    Already have an account?{' '}
                    <Anchor size="sm" component="button">
                        Login
                    </Anchor>
                </Text>

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <Group grow mb="md" mt="md">
                        <Button radius="xl" leftIcon={<IconBrandFacebook size={18} onClick={handleFacebookSignup}/>}>Facebook</Button>
                        <Button radius="xl" leftIcon={<IconBrandGoogle size={18} onClick={handleGoogleSignup}/>}>Google</Button>
                    </Group>
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader/>}
                    {isSuccess && <Navigate to="/login"/>}
                    {error && <p className='error'>{error}</p>}

                    <TextInput label="Verification Code" placeholder="Enter Verification Code" name="verification_code" onChange={handleChange} />               
               

                  
                  
                    <Button fullWidth mt="xl" onClick={(e)=>handleSubmit(e)}>
                        Verify
                    </Button>
                </Paper>

                <div>
      <p>Did not receive verification code? Resend in {countDown} seconds</p>
      {showResendButton && (
        <button onClick={resendVerificationCode}>
          Resend Code
        </button>
      )}
    </div>
            </Container>
        </>
   
  );
};

export default VerifyEmailPage;
