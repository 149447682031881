import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface ProtectedRouteProps {
  isAllowed: boolean;
  requiredRoles?: string[];
  campaignId?: string | null;  // Add campaignId for contextual check
  redirectPath?: string;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  requiredRoles = [],
  campaignId,  // Add campaignId to props
  redirectPath = '/login',
  children
}) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const token = sessionStorage.getItem('token');

  if (!user || !token) {
    return <Navigate to={redirectPath} replace />;
  }

  const sessionExpired = tokenIsExpired(token);
  if (isAllowed === false || sessionExpired) {
    return <Navigate to={redirectPath} replace />;
  }

  // Assume user roles include campaignIds from server in this format:
  // userRoles = [{ role: 'admin', campaignId: null }, { role: 'c-admin', campaignId: 10 }]
  const userRoles = user.roles || [];

  // Admin bypasses campaignId checks
  const isAdmin = user.is_admin; //userRoles.some((roleObj: { role: string }) => roleObj.role === 'admin');

  const hasRoleForCampaign = isAdmin || userRoles.some(
    (roleObj: { role: string, campaignId: number | null }) => 
      requiredRoles.includes(roleObj.role) && (roleObj.campaignId === campaignId || roleObj.campaignId === null)
  );

  if (!hasRoleForCampaign) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children ? <>{children}</> : <Outlet />;

  
};

// Function to check if the token is expired
const tokenIsExpired = (token: string): boolean => {
  const now = Date.now();
  const decoded: { exp: number } = jwtDecode<{ exp: number }>(token);

  return decoded.exp * 1000 < now;
}

export default ProtectedRoute;
