import {
    Box,
    BoxProps,
    Burger,
    Button,
    ButtonProps,
    Center,
    Collapse,
    Container,
    createStyles,
    Divider,
    Drawer,
    Flex,
    getStylesRef,
    Group,
    Header,
    HoverCard,
    Loader,
    rem,
    ScrollArea,
    SimpleGrid,
    Text,
    ThemeIcon,
    UnstyledButton,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
    IconAugmentedReality,
    IconCat,
    IconChevronDown,
    IconClipboardHeart,
    IconDeviceTv,
    IconFireHydrant,
    IconHeartHandshake,
    IconLeaf,
  //  IconSearch,
    IconSos,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { /*BrandName,*/ SearchDrawer } from './index';
import { Link, useNavigate } from 'react-router-dom';
import { makeGetRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { Category } from '../types';
import Logo from '../assets/logos/fundreach.png'

const useStyles = createStyles((theme) => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan('md')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.primary[6],
            color: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            fontWeight: 600,

            [`& .${getStylesRef('icon')}`]: {
                color: theme.colorScheme === 'dark' ? theme.black : theme.white,
            },
        },
    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.primary[0],
        }),

        '&:active': theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
    },

    title: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: rem(40),
        letterSpacing: -1,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        marginBottom: theme.spacing.xs,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(28),
            textAlign: 'left',
        },
    },

    highlight: {
        color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
    },

    linkIcon: {
        ref: getStylesRef('icon'),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.secondary[6],
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
}));

interface IProps extends BoxProps {
    compressed?: boolean;
}

interface User {
    id:string;
    first_name: string;
    role: string;  
    is_admin:boolean
}
const LandingNavbar = ({ compressed }: IProps) => {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const [searchOpened, { toggle: toggleSearchDrawer, close: closeSearchDrawer }] = useDisclosure(false);
    const { classes, theme } = useStyles();
    const [stickyClass, setStickyClass] = useState(false);
    const matchesMobile = useMediaQuery('(max-width: 768px)');
    const [categories, setCategories] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const user: User = JSON.parse(localStorage.getItem('user') || '{}');


    const getCampaignCategory = async () => {
        setIsLoading(true);
        const res = await makeGetRequest('campaign-categories');
        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setCategories(res.data);
            setIsLoading(false);
            setError(null);
        }
    };

    useEffect(() => {
        getCampaignCategory();
    }, []);

    const iconMapping: { [key: string]: any } = {
        Medical: IconClipboardHeart,
        Environment: IconLeaf,
        Nonprofit: IconHeartHandshake,
        Animals: IconCat,
        Crisis: IconFireHydrant,
        Technology: IconAugmentedReality,
        Films: IconDeviceTv,
    };

    const navigate = useNavigate();

    const logout = ()=>{
        localStorage.removeItem('user');
        sessionStorage.removeItem('token');
        navigate('/')
    }

    const links = categories.map((category) => (
        <UnstyledButton className={classes.subLink} key={category.id}>
            <Group noWrap align="center">
                <ThemeIcon size={34} variant="default" radius="md">
                    {iconMapping[category.category.trim()] ? (
                        React.createElement(iconMapping[category.category.trim()], {
                            size: rem(22),
                            stroke: 1.5,
                            color: theme.fn.primaryColor(),
                        })
                    ) : (
                        <IconSos size={rem(22)} stroke={1.5} color={theme.fn.primaryColor()} />
                    )}
                </ThemeIcon>
                <div>
                    <Link to={`/campaigns/category/${category.id}`}>
                        <Text size="sm" fw={500}>
                            {category.category.trim()}
                        </Text>
                    </Link>
                </div>
            </Group>
        </UnstyledButton>
    ));

    const buttonProps: ButtonProps = {
        variant: 'subtle',
        radius: matchesMobile ? 'sm' : 0,
    };

    const stickNavbar = () => {
        if (window !== undefined) {
            const windowHeight = window.scrollY;
            windowHeight > 240 ? setStickyClass(true) : setStickyClass(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    if(isLoading){
        return <Loader/>
    }
    if(error){
        return <p className='error'>{error}</p>
    }

    return (
        <Box
            mt={compressed ? (stickyClass ? 0 : 'xl') : 0}
            sx={{
                transition: 'all ease 150ms',
                position: 'fixed',
                top: '3%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                margin: 'auto',
                width: compressed ? (stickyClass ? '100%' : '85%') : '100%',
                boxShadow: theme.shadows.sm,
            }}
        >
            <Header
                height={60}
                px="md"
                sx={{
                    backgroundColor: stickyClass ? 'rgba( 255, 255, 255, .9 )' : theme.white,
                    backdropFilter: 'blur(8px)',
                    borderBottom: 'none',
                    borderRadius: '8px',
                    border: `1px solid ${theme.colors.gray[2]}`,
                }}
            >
                <Container size="xl">
                    <Flex justify="space-between" align="center" sx={{ height: '100%' }}>
                        <Flex align="center" gap="xl">
                            <Link to="/">
                               {/* <BrandName />*/}
                                <img src={Logo} alt="FundReach"/>
                            </Link>
                        </Flex>
                        <Flex align="center" gap="xl">
                            <Group className={classes.hiddenMobile} sx={{ height: '100%' }} spacing={0}>
                               
                            <Link to="/dashboard" className={classes.link}>
                                    Dashboard
                                </Link>

                                {
                                    user && <Link to="/campaigns" className={classes.link}>
                                    Campaigns
                                </Link>
                                }
                                <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                                    <HoverCard.Target>
                                        <a href="#" className={classes.link} onClick={toggleLinks}>
                                            <Center inline>
                                                <Box component="span" mr={5}>
                                                    Categories
                                                </Box>
                                                <IconChevronDown size={16} color={theme.fn.primaryColor()} />
                                            </Center>
                                        </a>
                                    </HoverCard.Target>

                                    <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                                        <SimpleGrid cols={2} spacing={0}>
                                            {links}
                                        </SimpleGrid>

                                        <div className={classes.dropdownFooter}>
                                            <Group position="apart">
                                                <div>
                                                    <Text fw="bold" fz="sm" color="dimmed">
                                                        Get started
                                                    </Text>
                                                    <Text fz="xs" color="dimmed">
                                                        Explore our site and services
                                                    </Text>
                                                </div>
                                                <Button variant="default">Get started</Button>
                                            </Group>
                                        </div>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                                <Link to="/how-it-works" className={classes.link}>
                                    How it works
                                </Link>

                                <Link to="/contact-us" className={classes.link}>
                                    Contact Us
                                </Link>
                            </Group>

                            
                            <Group className={classes.hiddenMobile} sx={{ height: '100%' }} spacing={0}>
                               {
                                user ?  <Link to="#" onClick={logout} className={classes.link}>
                                Logout
                            </Link>
                            :
                            <Link to="/login" className={classes.link}>
                            Login
                        </Link>
                               }
                              <Link to="/create-campaign">
                              <Button
                                    {...buttonProps}
                                    color="primary"
                                    sx={{
                                        border: `1px solid ${theme.colors.primary[5]}`,
                                        color: theme.colors.primary[5],
                                    }}
                                >
                                    Start a Campaign
                                </Button>
                              </Link>
                            </Group>
                            <Burger
                                opened={drawerOpened}
                                onClick={toggleDrawer}
                                className={classes.hiddenDesktop}
                                color={theme.colors.gray[6]}
                                size="sm"
                            />
                        </Flex>
                    </Flex>
                </Container>
            </Header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title="Navigation"
                className={classes.hiddenDesktop}
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                    <Divider my="sm" />
                    <Link to="/campaigns" className={classes.link}>
                        Discover
                    </Link>
                    <a href="#" className={classes.link}>
                        <Center inline>
                            <Box component="span" mr={5}>
                                Categories
                            </Box>
                            <IconChevronDown size={16} color={theme.fn.primaryColor()} />
                        </Center>
                    </a>
                    <Collapse in={linksOpened}>{links}</Collapse>
                    <Link to="/resources" className={classes.link}>
                        Resources
                    </Link>
                    <Divider my="sm" />
                    <Link to="login" className={classes.link}>
                        Login
                    </Link>
                    <Button
                        {...buttonProps}
                        color="primary"
                        sx={{
                            border: `1px solid ${theme.colors.primary[5]}`,
                            color: theme.colors.primary[5],
                            width: '100%',
                        }}
                        onClick={toggleSearchDrawer}
                    >
                        Start a Campaign
                    </Button>
                </ScrollArea>
            </Drawer>
            <SearchDrawer opened={searchOpened} onClose={closeSearchDrawer} />

        </Box>
    );
};

export default LandingNavbar;
